import { GeoBoundingBoxDef } from '../model/definitions/GeoBoundingBoxDef';
import { WDLocation } from '../model/definitions/WDLocation';

export function fromBoundsToGeoBox(bounds: [number, number, number, number]): GeoBoundingBoxDef {
  return {
    upperLeft: { longitude: bounds[0], latitude: bounds[3] },
    bottomLeft: { longitude: bounds[0], latitude: bounds[1] },
    bottomRight: { latitude: bounds[1], longitude: bounds[2] },
    upperRight: { latitude: bounds[3], longitude: bounds[2] },
  };
}

export function fromBoundsToGeoBoxSymbol(
  bounds: [number, number, number, number] /*left 0, right 1, top 2, bottom 3 */,
): GeoBoundingBoxDef {
  return {
    upperLeft: { longitude: bounds[0], latitude: bounds[2] },
    bottomLeft: { longitude: bounds[0], latitude: bounds[3] },
    bottomRight: { latitude: bounds[3], longitude: bounds[1] },
    upperRight: { latitude: bounds[2], longitude: bounds[1] },
  };
}

export function fromGeoBoxToBounds(gBox: GeoBoundingBoxDef): [number, number, number, number] {
  return [
    gBox.upperLeft?.longitude,
    gBox.bottomLeft?.latitude,
    gBox.bottomRight?.longitude,
    gBox.upperRight?.latitude,
  ];
}

export function fromGeoBoxToBoundsWDLayers(
  gBox: GeoBoundingBoxDef,
): [number, number, number, number] {
  return [
    gBox.upperLeft?.longitude,
    gBox.bottomRight?.longitude,
    gBox.upperRight?.latitude,
    gBox.bottomLeft?.latitude,
  ];
}

export function fromBoundsToPointBox(bounds: [number, number, number, number]) {
  const geobox = fromBoundsToGeoBoxSymbol(bounds);

  return {
    points: [
      geobox.upperLeft,
      geobox.upperRight,
      geobox.bottomRight,
      geobox.bottomLeft,
      geobox.upperLeft,
    ],
  };
}

export function fromWDLocationToPointBox(bounds: WDLocation) {
  const points = [
    { longitude: bounds.leftLongitude, latitude: bounds.upperLatitude },
    { longitude: bounds.rightLongitude, latitude: bounds.upperLatitude },
    { longitude: bounds.rightLongitude, latitude: bounds.lowerLatitude },
    { longitude: bounds.leftLongitude, latitude: bounds.lowerLatitude },
    { longitude: bounds.leftLongitude, latitude: bounds.upperLatitude },
  ];
  return { points };
}
