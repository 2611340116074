import { Select } from 'flowbite-react';
import Slider from 'rc-slider';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ElementsEnum } from '../../../../../core/ui/enums/ElementsEnum';
import { TimeControlDef } from '../../../../../model/definitions/TimeControlDef';
import { AnimationsEnum } from '../../../../../model/enums/AnimationsEnum';
import { ActiveDef } from '../../../../../store/slices/active-slice';
import { RootState } from '../../../../../store/store';
import InputNumber from '../../../../marketplace-new/atoms/FormatNumber/FormatNumber';
import styles from '../../Properties.module.scss';
import GridItem from '../../shared/GridItem';

interface AnimationPickerProps {
  type?: ElementsEnum;
  timeControls?: TimeControlDef;
  onChange: (value: number | AnimationsEnum, name: keyof TimeControlDef) => void;
  maxVal?: number;
}
export const AnimationPicker = ({ timeControls, onChange, type, maxVal }: AnimationPickerProps) => {
  const { activeFramerate } = useSelector<RootState, ActiveDef>((state) => state.active);
  const inAnimationOptions = Object.keys(AnimationsEnum)
    .slice(0, -1)
    .map((k) => (
      <option key={k} value={k}>
        {k.charAt(0).toUpperCase() + k.slice(1).toLowerCase().replace(/_/, ' ')}
      </option>
    ));
  const outAnimationOptions = Object.keys(AnimationsEnum)
    .filter((e) => e !== 'FADE_IN')
    .map((k) => (
      <option key={k} value={k}>
        {k.charAt(0).toUpperCase() + k.slice(1).toLowerCase().replace(/_/, ' ')}
      </option>
    ));

  useEffect(() => {
    if (timeControls?.inAnimationDef === AnimationsEnum.CUT) {
      onChange(0, 'inAnimationDuration');
    } else if (
      timeControls?.inAnimationDef === AnimationsEnum.FADE_IN &&
      timeControls?.inAnimationDuration === 0
    ) {
      //500ms = 1/2 activeFramerate
      onChange(500, 'inAnimationDuration');
    }
  }, [timeControls?.inAnimationDef]);

  useEffect(() => {
    if (timeControls?.outAnimationDef === AnimationsEnum.CUT) {
      onChange(0, 'outAnimationDuration');
    } else if (
      timeControls?.outAnimationDef === AnimationsEnum.FADE_OUT &&
      timeControls?.outAnimationDuration === 0
    ) {
      //500ms = 1/2 activeFramerate
      onChange(500, 'outAnimationDuration');
    }
  }, [timeControls?.outAnimationDef]);

  return (
    <>
      <GridItem
        label={`In ${type === ElementsEnum.AUDIO ? 'effect' : 'animation'}:`}
        item={
          <Select
            className={styles.select}
            value={timeControls?.inAnimationDef ? timeControls?.inAnimationDef : ''}
            onChange={(e) => onChange(e.target.value as AnimationsEnum, 'inAnimationDef')}
          >
            {inAnimationOptions}
          </Select>
        }
      />

      <GridItem
        noBorderBg
        label="Duration:"
        item={
          <>
            <Slider
              min={0}
              max={activeFramerate * 2}
              value={((timeControls?.inAnimationDuration ?? 0) / 1000) * activeFramerate}
              disabled={(timeControls?.inAnimationDef ?? 0) === AnimationsEnum.CUT}
              onChange={(e) => {
                e &&
                  typeof e === 'number' &&
                  onChange(Math.floor((e * 1000) / activeFramerate), 'inAnimationDuration');
              }}
            />
            <InputNumber
              type={'number'}
              min={0}
              max={activeFramerate * 2}
              precision={2}
              disabled={timeControls?.inAnimationDef === AnimationsEnum.CUT}
              className={styles.inputWrap}
              value={Math.floor(
                ((timeControls?.inAnimationDuration ?? 0) / 1000) * activeFramerate,
              )}
              onInputChange={(e) => {
                e >= 0 &&
                  e <= 10000 &&
                  onChange(Math.floor((e * 1000) / activeFramerate), 'inAnimationDuration');
              }}
            />
            fr
          </>
        }
      />

      <GridItem
        label={`Out ${type === ElementsEnum.AUDIO ? 'effect' : 'animation'}:`}
        item={
          <Select
            className={styles.select}
            value={timeControls?.outAnimationDef ? timeControls?.outAnimationDef : ''}
            onChange={(e) => onChange(e.target.value as AnimationsEnum, 'outAnimationDef')}
          >
            {outAnimationOptions}
          </Select>
        }
      />

      <GridItem
        noBorderBg
        label="Duration:"
        item={
          <>
            <Slider
              min={0}
              max={activeFramerate * 2}
              value={Math.floor(
                ((timeControls?.outAnimationDuration ?? 0) / 1000) * activeFramerate,
              )}
              disabled={timeControls?.outAnimationDef === AnimationsEnum.CUT}
              onChange={(e) => {
                e &&
                  typeof e === 'number' &&
                  onChange(Math.floor((e * 1000) / activeFramerate), 'outAnimationDuration');
              }}
            />
            <InputNumber
              type={'number'}
              min={0}
              max={activeFramerate * 2}
              precision={0}
              disabled={timeControls?.outAnimationDef === AnimationsEnum.CUT}
              className={styles.inputWrap}
              value={Math.floor(
                ((timeControls?.outAnimationDuration ?? 0) / 1000) * activeFramerate,
              )}
              onInputChange={(e) => {
                e >= 0 &&
                  e <= 10000 &&
                  onChange(Math.floor((e * 1000) / activeFramerate), 'outAnimationDuration');
              }}
            />
            fr
          </>
        }
      />
    </>
  );
};
