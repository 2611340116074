import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Select } from 'flowbite-react';
import React, { ChangeEvent, useEffect, useMemo } from 'react';

import Input from '../../../../atoms/input/Input';
import { useGetGrib, valuesKey } from '../../../../core/api/mapLayers/useGetGrib';
import { useGetGribModelType } from '../../../../core/api/mapLayers/useGetGribModelType';
import { useGetParameterMappings } from '../../../../core/api/mapLayers/useGetParameterMappings';
import { useGetModelUnits } from '../../../../core/api/mapLayers/useGetUnits';
import { shouldHaveInterpolateOption } from '../../../../core/weather-data/helpers/interpolationPropertyHelper';
import { queryClient } from '../../../../index';
import { MAX_ZINDEX_VALUE } from '../../../../model/constants/constants';
import { DataFrameDef } from '../../../../model/definitions/DataFrameDef';
import { GribMapLayer } from '../../../../model/definitions/GribMapLayer';
import { MapPanelDef } from '../../../../model/definitions/MapPanelDef';
import { PickerDef } from '../../../../model/definitions/PickerDef';
import { RadarMapLayer } from '../../../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../../../model/definitions/SatelliteMapLayer';
import { SymbolLayerDef } from '../../../../model/definitions/SymbolLayerDef';
import { ColorPaletteParamTypeEnum } from '../../../../model/enums/ColorPaletteParamTypeEnum';
import { VisualisationTypeEnum } from '../../../../model/enums/VisualisationTypeEnum';
import { filterWithSelection } from '../../../../molecules/mapElement/helpers';
import useGetAllIconSets from '../../../dashboard/queries-NEW/useGetAllIconSets';
import InputNumber from '../../../marketplace-new/atoms/FormatNumber/FormatNumber';
import { FirstLayerRange } from '../../modals/frameRangeHelpers';
import { FramesRange } from '../../modals/FramesRange';
import styles from '../Properties.module.scss';

export const isGribLayerType = (
  l: GribMapLayer | RadarMapLayer | SatelliteMapLayer | SymbolLayerDef,
): l is GribMapLayer => {
  return (l as GribMapLayer).gribSource !== undefined;
};

interface MapLayersProps {
  oldLayer: boolean;
  dataProductId: string | null;
  setDataProductId: (e: string) => void;
  bounds: [number, number, number, number];
  name?: string;
  setName: (e: string) => void;
  mapLayer: MapPanelDef;
  onMapLayerChange: (propertyPath: keyof MapPanelDef, newValue: SymbolLayerDef[]) => void;
  source: PickerDef | undefined;
  setSource: (e?: PickerDef) => void;
  param: PickerDef | undefined;
  setParam: (e?: PickerDef) => void;
  selectedDataFrames: Array<DataFrameDef>;
  z: number;
  setZ: React.Dispatch<React.SetStateAction<number>>;
  unit: string | undefined;
  setUnit: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSelectedDataFrames: (e: Array<DataFrameDef>) => void;
  gribLayerEdit?: SymbolLayerDef;
  rangeFromFirstLayer: FirstLayerRange | null;
  gribModel: PickerDef | undefined;
  setGribModel: React.Dispatch<React.SetStateAction<PickerDef | undefined>>;
  enableInterpolation: boolean | undefined;
  setEnableInterpolation: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setFramesDensity: React.Dispatch<React.SetStateAction<number>>;
  framesDensity: number;
  iconPackId?: string;
  setIconPackId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setParameterType: React.Dispatch<React.SetStateAction<ColorPaletteParamTypeEnum | undefined>>;
}
const MapSymbolModelLayers = ({
  oldLayer,
  dataProductId,
  setDataProductId,
  bounds,
  name,
  setName,
  setParam,
  setSource,
  param,
  source,
  selectedDataFrames,
  setSelectedDataFrames,
  z,
  setZ,
  unit,
  setUnit,
  gribLayerEdit,
  rangeFromFirstLayer,
  gribModel,
  setGribModel,
  enableInterpolation,
  setEnableInterpolation,
  framesDensity,
  setFramesDensity,
  iconPackId,
  setIconPackId,
  setParameterType,
}: MapLayersProps) => {
  const {
    useGetGribParamType,
    getSource,
    getTypes,
    getGribValue,
    paramData,
    sourceData,
    gribValue,
    paramLoading,
    sourceLoading,
    framesLoading,
  } = useGetGrib(oldLayer);
  useGetGribParamType(bounds ?? [0, 0, 0, 0]);
  const { data: parameterMappings } = useGetParameterMappings({
    dataProductId,
    parameter: param?.name,
  });
  const { data: templates } = useGetAllIconSets({
    parameter: param?.name,
    page: 0,
    size: 1000,
  });

  const isWind = param?.visualisation?.defaultType == VisualisationTypeEnum.PARTICLE;

  const { data: unitsList, isLoading: unitsLoading } = useGetModelUnits(param?.name);

  const { mutate: getGribModel, data: modelData, isLoading: modelLoading } = useGetGribModelType();
  const selectedRangeValue = useMemo(() => {
    if (!gribLayerEdit || !gribValue?.length || !gribLayerEdit?.dataFrames) return [0, 0];

    if (
      isGribLayerType(gribLayerEdit) &&
      param &&
      gribLayerEdit.gribSource.parameterType.name !== param.name
    )
      return undefined;
    const [firstFrame] = gribLayerEdit.dataFrames;
    if (!firstFrame) return [0, 0];
    const indexOfFirstFrame = gribValue!
      .sort((a, b) => a.timestamp - b.timestamp)
      .findIndex((fr) => fr.frameId === firstFrame.frameId);
    return [indexOfFirstFrame, indexOfFirstFrame + gribLayerEdit.dataFrames.length];
  }, [gribLayerEdit, gribValue, param]);

  useEffect(() => {
    if (bounds.length) {
      if (oldLayer) {
        getSource({ parameterTypeId: param!.id, bounds });
        getGribModel({ parameterTypeId: param!.id, bounds, sourceId: source!.id });
        getGribValue({
          bounds,
          paramTypeId: param!.id,
          sourceId: source!.id,
          modelId: gribModel!.id,
        });
      } else {
        getSource({ bounds });
      }
      if (gribLayerEdit && dataProductId) {
        getGribValue({
          paramTypeId: param!.id,
          bounds,
          dataProductId: dataProductId,
        });

        getTypes({
          dataProductId: dataProductId,
          bounds: bounds,
        });
      }
    }
  }, [bounds.length]);

  useEffect(() => {
    if (!gribLayerEdit && parameterMappings) {
      setEnableInterpolation(parameterMappings.isInterpolationEnabled);
      setParameterType(parameterMappings.colorPaletteParameterGroup?.value);
      // const defaultVisualisationType = parameterMappings.visualizationTypes.find(
      //   (visualisationType: VisualizationTypeInterface) => visualisationType.isDefault,
      // );
      // setVisualisationType(defaultVisualisationType);
    }
  }, [parameterMappings]);

  const onParamChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    if (value >= 0) {
      dataProductId && !oldLayer
        ? getGribValue({
            paramTypeId: value,
            dataProductId: dataProductId,
            bounds,
          })
        : getSource({
            parameterTypeId: value,
            bounds,
          });
      if (oldLayer) {
        setSource(undefined);
        setGribModel(undefined);
      }
    }
    queryClient.invalidateQueries(valuesKey);
    const selectedParam = paramData?.find((param) => param.id === value);
    setParam(selectedParam);
    if (selectedParam) {
      setName(selectedParam.name);
      if (selectedParam.visualisation?.defaultType == VisualisationTypeEnum.PARTICLE) {
        setUnit('WDirWSpeed');
      } else {
        setUnit(undefined);
      }
    }
  };

  const onSourceChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    const selectedSource = sourceData?.find((source) => source.id === value);
    if (selectedSource?.dataProductId) {
      getTypes({
        dataProductId: selectedSource.dataProductId,
        bounds: bounds,
      });
      setDataProductId(selectedSource.dataProductId);
      setParam(undefined);
      if (selectedSource.modelId && selectedSource.modelName) {
        setGribModel({ id: selectedSource.modelId, name: selectedSource.modelName });
      }
    } else {
      if (value >= 0) {
        param &&
          value &&
          getGribModel({
            parameterTypeId: param.id,
            sourceId: value,
            bounds,
          });
        param &&
          value &&
          gribModel &&
          getGribValue({
            paramTypeId: param.id,
            sourceId: value,
            modelId: gribModel.id,
            bounds,
          });
      }
      setGribModel(undefined);
    }
    setSource(selectedSource);
  };
  const onModelChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    if (value >= 0) {
      param &&
        source &&
        value &&
        getGribValue({
          paramTypeId: param.id,
          sourceId: source.id,
          modelId: value,
          bounds,
        });
    }
    const selectedModel = modelData?.find((model) => model.id === value);
    setGribModel(selectedModel);
  };
  const onEnableInterpolationChange = (e: CheckboxChangeEvent) => {
    setEnableInterpolation(e.target.checked);
  };
  const gribParamSelect = () => (
    <Select
      className={'w-full'}
      style={{ width: '100%' }}
      onChange={onParamChange}
      value={param?.id}
      required
    >
      <option value={-1}>
        {paramData?.length === 0 ? 'No parameters available' : 'Select parameter'}
      </option>
      {paramData
        ?.sort((a, b) => (a.id > b.id ? 1 : -1))
        .map((k: PickerDef) => (
          <option key={k.id} value={k.id}>
            {k.name}
          </option>
        ))}
    </Select>
  );
  const framesWithDensity = useMemo(() => {
    if (!framesDensity) return gribValue;
    return filterWithSelection(gribValue || [], framesDensity, selectedDataFrames[0]);
  }, [framesDensity, gribValue]);
  const gribSourceSelect = () =>
    sourceData ? (
      <Select
        className={'w-full'}
        style={{ width: '100%' }}
        onChange={onSourceChange}
        value={source?.id}
        required
      >
        <option value={undefined}>
          {sourceData?.length === 0 ? 'No sources available' : 'Select source'}
        </option>
        {sourceData
          ?.sort((a, b) => a.name.localeCompare(b.name))
          ?.map((k: PickerDef) => (
            <option key={k.id} value={k.id}>
              {k.name}
            </option>
          ))}
      </Select>
    ) : null;
  const gribModelSelect = () =>
    oldLayer ? (
      param?.id && source?.id ? (
        <Select className={'w-full'} onChange={onModelChange} value={gribModel?.id} required>
          <option value={undefined}>
            {modelData?.length === 0 ? 'No models available' : 'Select model'}
          </option>
          {modelData?.map((k: PickerDef) => (
            <option key={k.id} value={k.id}>
              {k.name}
            </option>
          ))}
        </Select>
      ) : null
    ) : (
      <Input value={gribModel?.name} disabled />
    );

  const unitSelect = () =>
    unitsList ? (
      <Select
        className={'w-full'}
        style={{ width: '100%' }}
        onChange={(e) => {
          setUnit(e.target.value);
        }}
        value={unit}
        required
      >
        <option value={''}>{unitsList?.length === 0 ? 'No units available' : 'Select unit'}</option>
        {unitsList?.map((k) => (
          <option key={k.key} value={k.key}>
            {k.value}
          </option>
        ))}
      </Select>
    ) : null;

  useEffect(() => {
    if (!iconPackId && templates && templates.content.length > 0) {
      const iconPack =
        templates.content.find((iconPack) => iconPack.isDefault) || templates.content[0];
      setIconPackId(iconPack.id);
    }
  }, [iconPackId, templates]);

  const iconSetSelect = () =>
    templates?.content ? (
      <Select
        className={'w-full'}
        style={{ width: '100%' }}
        onChange={(e) => {
          setIconPackId(e.target.value);
        }}
        value={iconPackId}
        required
      >
        <option value={''}>
          {templates.content.length === 0 ? 'No icon set available' : 'Select icon set'}
        </option>
        {templates.content?.map((k) => (
          <option key={k.id} value={k.id}>
            {k.name}
          </option>
        ))}
      </Select>
    ) : null;

  function onHourlyChange(e: CheckboxChangeEvent) {
    if (e.target.checked) {
      setFramesDensity(1);
    } else {
      setFramesDensity(0);
    }
  }
  const useHourlyValues = (
    <div className="relative flex flex-col">
      <Checkbox checked={!!framesDensity} onChange={onHourlyChange}>
        <span className={'!text-white'}>Time step granularity</span>
      </Checkbox>
      {!framesDensity && (
        <small className=" absolute top-5 left-[0px] text-red-500">
          *A high number of data time steps may lower rendering performance
        </small>
      )}
    </div>
  );
  const enableInterpolationJSX = (
    <Checkbox checked={Boolean(enableInterpolation)} onChange={onEnableInterpolationChange}>
      <span className="!text-white">Enable interpolation</span>
    </Checkbox>
  );
  return (
    <>
      <h3>Model Layer</h3>
      <div className="layers-wrap model-layer">
        <div className="layer-item-flex-wrapper">
          <div className="layer-item">
            {oldLayer ? (
              <>
                <label>
                  <span>*</span> Parameter type
                </label>
                {paramLoading ? (
                  <div className={'loading'}>Loading parameters</div>
                ) : (
                  gribParamSelect()
                )}
              </>
            ) : (
              <>
                <label>
                  <span>*</span> Source
                </label>
                {sourceLoading ? (
                  <div className={'loading'}>Loading sources</div>
                ) : (
                  gribSourceSelect()
                )}
              </>
            )}
          </div>
          <div className="layer-item">
            {param && (
              <>
                <label>
                  <span>*</span> Layer name
                </label>
                <Input
                  placeholder="Type model layer name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </>
            )}
          </div>
        </div>
        <div className="layer-item-flex-wrapper">
          <div className="layer-item">
            {oldLayer
              ? param && (
                  <>
                    <label>
                      <span>*</span> Source
                    </label>
                    {sourceLoading ? (
                      <div className={'loading'}>Loading sources</div>
                    ) : (
                      gribSourceSelect()
                    )}
                  </>
                )
              : source && (
                  <>
                    <label>
                      <span>*</span> Parameter type
                    </label>
                    {paramLoading ? (
                      <div className={'loading'}>Loading parameters</div>
                    ) : (
                      gribParamSelect()
                    )}
                  </>
                )}
          </div>
          <div className="layer-item">
            {param && (
              <>
                <label>
                  <span>*</span> Layer level
                </label>
                <InputNumber
                  placeholder="0"
                  value={z}
                  onInputChange={(e) => setZ(e)}
                  className={styles.inputWrap}
                  required
                  min={0}
                  max={MAX_ZINDEX_VALUE}
                  step={1}
                />
              </>
            )}
          </div>
        </div>
        <div className="layer-item-flex-wrapper">
          <div className="layer-item">
            {source && (
              <>
                <label>
                  <span>*</span> Model
                </label>
                {modelLoading ? <div className={'loading'}>Loading models</div> : gribModelSelect()}
              </>
            )}
          </div>
          <div className="layer-item">
            {param?.name === 'WeatherType' ? (
              <>
                <label>
                  <span>*</span> Icon Set
                </label>
                {iconSetSelect()}
              </>
            ) : !isWind ? (
              <>
                <label>
                  <span>*</span> Unit
                </label>
                {unitsLoading ? <div className={'loading'}>Loading units</div> : unitSelect()}
              </>
            ) : null}
          </div>
        </div>
        {param && gribValue && (
          <div className={'grid grid-cols-3 gap-2'}>
            <div className="layer-item mt-[20px]">{useHourlyValues}</div>
            <div className="layer-item">
              <label className={'top-5'}>
                Frames granularity
                <Select
                  value={framesDensity ?? 0}
                  onChange={(e) => setFramesDensity(Number(e.target.value))}
                >
                  <option value={0}>original</option>
                  <option value={1}>1h</option>
                  <option value={2}>2h</option>
                  <option value={6}>6h</option>
                  <option value={12}>12h</option>
                  <option value={24}>24h</option>
                  <option value={168}>7 days</option>
                </Select>
              </label>
            </div>
            <div className="layer-item flex justify-end items-baseline mt-[20px]">
              {((param && shouldHaveInterpolateOption(param.name)) ||
                parameterMappings?.isInterpolationEnabled) &&
                enableInterpolationJSX}
            </div>
          </div>
        )}

        {param && source && gribModel && (
          <>
            {framesLoading ? (
              <div className={'loading my-[68px] text-center'}>Loading available frames</div>
            ) : (
              gribValue.length > 0 && (
                <FramesRange
                  maxDays={14}
                  layerType={'grib'}
                  frames={framesWithDensity}
                  framesDensity={framesDensity}
                  onRangeChange={setSelectedDataFrames}
                  selectedFrames={selectedDataFrames}
                  layerEdit={gribLayerEdit}
                  defaultValue={gribLayerEdit ? selectedRangeValue : undefined}
                  show={!!rangeFromFirstLayer}
                  rangeFromFirstLayer={rangeFromFirstLayer}
                />
              )
            )}
          </>
        )}
      </div>
    </>
  );
};
export default MapSymbolModelLayers;
