import axios, { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';

import { _kc } from '../auth/KeycloakService';

const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/store/`;

export const axiosInstance = axios.create({ baseURL: BASE_URL });

export function getCustomHeaders() {
  const params = new URLSearchParams(window.location.search);
  const projectId = params.get('projectId');
  const token = _kc.tokenParsed;
  const userId = token?.['email'];
  const orgId = sessionStorage.getItem('ORGANIZATION_ID');
  return { projectId, orgId, userId };
}

export function appendCustomHeaders(config: AxiosRequestConfig<any>) {
  if (!config.headers) return;
  const { projectId, userId, orgId } = getCustomHeaders();
  if (projectId) {
    config.headers['X-Project-Id'] = projectId;
  }
  if (userId) {
    config.headers['X-User-Id'] = userId;
  }
  if (orgId) {
    config.headers['X-Org-Id'] = orgId;
  }
}

axiosInstance.interceptors.request.use(async (config) => {
  if (_kc.authenticated && config?.headers) {
    config.headers['Authorization'] = `Bearer ${_kc.token}`;
    appendCustomHeaders(config);
    return config;
  }
  await _kc.updateToken(5);
  if (!!_kc.token && config?.headers) {
    config.headers['Authorization'] = `Bearer ${_kc.token}`;
    appendCustomHeaders(config);
  }
  return config;
});
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    if (error?.response.status === 403) {
      toast.error(error?.response.data.message);
    }
    return Promise.reject(error);
  },
);
