import { v4 } from 'uuid';

import { FilteringTypeEnum, PostProcessingTypeEnum } from '../enums/FilteringTypeEnum';
import { InterpolationEnum } from '../enums/InterpolationEnum';
import { OrientationTypeEnum } from '../enums/OrientationTypeEnum';
import { PaletteLegendScalingEnum } from '../enums/PaletteLegendScalingEnum';
import { VisualisationTypeEnum } from '../enums/VisualisationTypeEnum';
import { ColorPaletteDef } from './ColorPaletteDef';
import { PositionControlDef } from './PositionControlDef';
import { TimeControlDef } from './TimeControlDef';

export class ParticleProps {
  particleDensity: number;
  fadeOpacity: number;
  speedFactor: number;
  dropRate: number;
  dropRateBump: number;
  particleNumber: number;
  particleSize: number;
}

export class WeatherDataMapLayerSetup {
  id = v4();
  mapLayerUrl: string;
  baseAppUrl: string;
  initiateFramesBaseUrl: string;
  interpolation = InterpolationEnum.NEAREST;
  preprocessing: FilteringTypeEnum | null = FilteringTypeEnum.MEDIAN;
  postprocessing: PostProcessingTypeEnum | null = null;
  embossEffect = 7;
  numberOfIterations = 4;
  isolineWidth: number;
  unitOfMeasurement: string;
  colorPaletteDef: ColorPaletteDef | null = new ColorPaletteDef();
  jsonColorPallet: string; // not used
  displayPaletteLegend = false;
  displayLegendValues = true;
  legendFontSize = 3;
  fontFamily = 'Arial';
  fontType?: string = 'Regular';
  fontVariantId?: string;
  paletteLegendPositionControl: PositionControlDef;
  paletteLegendOrientation: OrientationTypeEnum;
  paletteLegendScaling: PaletteLegendScalingEnum;
  visualisationType: VisualisationTypeEnum;
  isolineGeoJson: string; // GeoJson string
  windParticles: ParticleProps = new ParticleProps();
  //private List<ColorScale> colorPallet = new ArrayList<>();
  /*** UI helper property ***/
  paletteTC: TimeControlDef[] = new Array<TimeControlDef>();
  canvasLock = false;
}
