import { Map, MapBrowserEvent } from 'ol';
import { ProjectionLike, toLonLat } from 'ol/proj';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useScaleFactor } from '../../hooks/useScaleFactor';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { WeatherPosterDef } from '../../model/definitions/WeatherPosterDef';
import { ActiveDef, setPoster } from '../../store/slices/active-slice';
import { updateMapOverlayLonLat, updateMapOverlayOffset } from '../../store/slices/project-slice';
import { RootState } from '../../store/store';
import { AnimationElement } from './AnimationElement';
import ElementContainer from './ElementContainer';
import { ForecastElement } from './ForecastElement';
import { ImageElement } from './ImageElement';
import { ObservedElement } from './ObservedElement';
import { PointDateElement } from './PointDateElement';
import { PointLocationElement } from './PointLocationElement';
import { TextElement } from './TextElement';
import { VideoElement } from './VideoElement';

interface PosterElementProps {
  panelProps: WeatherPosterDef;
  canvas?: { cnvWidth?: number; cnvHeight?: number };
  disabled: boolean;
  isMapOverlay?: boolean;
  parentMapId?: string;
  mapRef?: React.MutableRefObject<Map | null>;
  projectionCalc?: ProjectionLike;
}
export const PosterElement = ({
  panelProps,
  canvas,
  disabled,
  isMapOverlay = false,
  parentMapId,
  mapRef,
  projectionCalc,
}: PosterElementProps) => {
  const {
    positionControl,
    textPanels,
    imagePanels,
    animationPanels,
    videoPanels,
    observedWDElements,
    pointDates,
    pointLocation,
    forecastWDElements,
    timeControls,
    id,
  } = panelProps;
  const elementRef = useRef<HTMLDivElement | null>(null);
  const posterRef = useRef<HTMLDivElement>(null);
  const [parentSize, setParentSize] = useState<PositionControlDef>(positionControl);
  const [editPositionMode, setEditPositionMode] = useState(false);
  const dispatch = useDispatch();
  const scaleFactor = useScaleFactor(disabled);

  const { activeScene, biasMode, activeElement } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  const elementCanvas = { cnvWidth: positionControl.w, cnvHeight: positionControl.h };

  function onElementClick(e: React.MouseEvent<HTMLDivElement>) {
    const rect = elementRef.current?.getBoundingClientRect();
    if (!rect) return;

    const offsetXPerc = ((e.clientX - rect.left) / rect.width) * 100;
    const offsetYPerc = ((e.clientY - rect.top) / rect.height) * 100;
    // const offsetX = (e.clientX - rect.left) * (1 / scaleFactor);
    // const offsetY = (e.clientY - rect.top) * (1 / scaleFactor);
    // const overlay = mapRef?.current?.getOverlayById(panelProps.id + parentMapId);
    // overlay && overlay.setOffset([-offsetX, -offsetY]);

    dispatch(
      updateMapOverlayOffset({
        geoPosterId: panelProps.id,
        mapId: parentMapId!,
        activeScene,
        x: offsetXPerc,
        y: offsetYPerc,
      }),
    );
    setEditPositionMode(false);
  }
  useEffect(() => {
    function onMapClick(e: MapBrowserEvent<any>) {
      if (editPositionMode) {
        const overlay = mapRef?.current?.getOverlayById(panelProps.id + parentMapId);
        overlay && overlay.setPosition(e.coordinate);
        dispatch(
          updateMapOverlayLonLat({
            geoPosterId: panelProps.id,
            mapId: parentMapId!,
            activeScene,
            lonLat: toLonLat(e.coordinate, projectionCalc!) as [number, number],
          }),
        );
        setEditPositionMode(false);
      }
    }
    if (mapRef && mapRef.current) {
      mapRef.current.on('click', onMapClick);
    }
    return () => mapRef?.current?.un('click', onMapClick);
  }, [editPositionMode, mapRef, parentMapId, panelProps.id, activeScene]);

  useEffect(() => {
    let offsetX = Number(panelProps.properties?.x || '0'); // %
    let offsetY = Number(panelProps.properties?.y || '0'); // %
    const rect = posterRef.current?.getBoundingClientRect();

    if (!rect) return;
    offsetX = (offsetX / rect.width) * 100;
    offsetY = (offsetY / rect.height) * 100;
    offsetX = offsetX * scaleFactor;
    offsetY = offsetY * scaleFactor;

    const overlay = mapRef?.current?.getOverlayById(panelProps.id + parentMapId);

    if (overlay) {
      overlay.setOffset([-offsetX, -offsetY]);
    } else {
      // dirty hack TBD on initial load overlay is undefined not set yet
      setTimeout(() => {
        const overlay = mapRef?.current?.getOverlayById(panelProps.id + parentMapId);

        overlay && overlay.setOffset([-offsetX, -offsetY]);
      }, 10);
    }
  }, [panelProps?.properties?.x, panelProps?.properties?.y]);

  const renderTextElements = () =>
    textPanels.map((text) => (
      <TextElement
        key={text.id}
        panelProps={text}
        canvas={elementCanvas}
        disabled={disabled}
        parentTime={timeControls}
        inPoster
        posterId={id}
        parentSize={parentSize}
        isMapOverlay={isMapOverlay}
        mapId={parentMapId}
        geoPosterId={panelProps.id}
      />
    ));
  const renderImageElements = () =>
    imagePanels.map((text) => (
      <ImageElement
        key={text.id}
        panelProps={text}
        canvas={elementCanvas}
        disabled={disabled}
        parentTime={timeControls}
        inPoster
        posterId={id}
        parentSize={parentSize}
        isMapOverlay={isMapOverlay}
        mapId={parentMapId}
        geoPosterId={panelProps.id}
      />
    ));
  const renderAnimationElements = () =>
    animationPanels.map((text) => (
      <AnimationElement
        key={text.id}
        panelProps={text}
        canvas={elementCanvas}
        disabled={disabled}
        parentTime={timeControls}
        inPoster
        posterId={id}
        parentSize={parentSize}
        isMapOverlay={isMapOverlay}
        mapId={parentMapId}
        geoPosterId={panelProps.id}
      />
    ));
  const renderVideoElements = () =>
    videoPanels.map((text) => (
      <VideoElement
        key={text.id}
        panelProps={text}
        canvas={elementCanvas}
        disabled={disabled}
        parentTime={timeControls}
        inPoster
        posterId={id}
        parentSize={parentSize}
        isMapOverlay={isMapOverlay}
        mapId={parentMapId}
        geoPosterId={panelProps.id}
      />
    ));
  const renderObservedElements = () =>
    observedWDElements.map((text) => (
      <ObservedElement
        key={text.id}
        panelProps={text}
        canvas={elementCanvas}
        disabled={disabled}
        parentTime={timeControls}
        inPoster
        posterId={id}
        parentSize={parentSize}
        isMapOverlay={isMapOverlay}
        mapId={parentMapId}
        geoPosterId={panelProps.id}
      />
    ));
  const renderDateElements = () =>
    pointDates?.map((text) => (
      <PointDateElement
        key={text.id}
        panelProps={text}
        canvas={elementCanvas}
        disabled={disabled}
        parentTime={timeControls}
        inPoster
        posterId={id}
        parentSize={parentSize}
        isMapOverlay={isMapOverlay}
        mapId={parentMapId}
        geoPosterId={panelProps.id}
        scene={panelProps}
      />
    ));
  const renderLocationElements = () =>
    pointLocation?.map((text) => (
      <PointLocationElement
        key={text.id}
        panelProps={text}
        canvas={elementCanvas}
        disabled={disabled}
        parentTime={timeControls}
        inPoster
        posterId={id}
        parentSize={parentSize}
        isMapOverlay={isMapOverlay}
        mapId={parentMapId}
        geoPosterId={panelProps.id}
        scene={panelProps}
      />
    ));
  const renderForecastElements = () =>
    forecastWDElements.map((text) => (
      <ForecastElement
        key={text.id}
        panelProps={text}
        canvas={elementCanvas}
        disabled={disabled}
        parentTime={timeControls}
        inPoster
        posterId={id}
        parentSize={parentSize}
        isMapOverlay={isMapOverlay}
        mapId={parentMapId}
        geoPosterId={panelProps.id}
      />
    ));

  useEffect(() => {
    if (posterRef.current && posterRef.current?.offsetHeight && posterRef.current?.offsetWidth)
      setParentSize((pSize) => ({
        ...pSize,
        w: posterRef.current!.offsetWidth,
        h: posterRef.current!.offsetHeight,
      }));
  }, [panelProps]);

  return (
    <ElementContainer
      canvas={canvas}
      panelProps={panelProps}
      disabled={disabled || biasMode}
      // @ts-ignore
      type={isMapOverlay ? 'mapOverlay' : 'weatherPosters'}
      visibility={panelProps.enabled}
      isMapOverlay={isMapOverlay}
      parentMapId={parentMapId}
      geoPosterId={panelProps.id}
      isDraggable={activeElement === id}
    >
      <div
        onDoubleClick={() => parentMapId && setEditPositionMode((m) => !m)}
        onClick={() => dispatch(setPoster({ posterId: id }))}
        style={{ width: '100%', height: '100%', position: 'relative' }}
        ref={posterRef}
        className="move-element-container"
      >
        {editPositionMode && (
          <div
            onClick={onElementClick}
            ref={elementRef}
            className="absolute text-base left-0 right-0 bottom-0 top-0 flex flex-col justify-center items-center text-red-500 z-[999] cursor-crosshair"
          >
            <p>
              Click map or element
              <br />
              to adjust position
            </p>
          </div>
        )}
        {renderTextElements()}
        {renderImageElements()}
        {renderAnimationElements()}
        {renderVideoElements()}
        {renderObservedElements()}
        {renderForecastElements()}
        {renderDateElements()}
        {renderLocationElements()}
      </div>
    </ElementContainer>
  );
};
