import { notification } from 'antd';
import { Tooltip } from 'flowbite-react';
import Slider from 'rc-slider';
import React, { useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  AiFillCaretDown,
  AiFillLock,
  AiOutlineArrowLeft,
  AiOutlineEdit,
  AiOutlineSchedule,
  AiOutlineShareAlt,
  AiOutlineVideoCamera,
  AiOutlineZoomIn,
  AiOutlineZoomOut,
} from 'react-icons/ai';
import { BiVideoPlus } from 'react-icons/bi';
import { CgLockUnlock } from 'react-icons/cg';
// import { GrStatusInfo } from 'react-icons/gr';
import { IoAdd } from 'react-icons/io5';
import { LuImagePlus } from 'react-icons/lu';
import { RiComputerLine } from 'react-icons/ri';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { queryClient } from '../../../..';
import Button from '../../../../atoms/button/Button';
import { useAssembleVideo } from '../../../../core/api/assembly/useAssembleVideo';
import { saveProject } from '../../../../core/api/ProjectsAPI';
import { useEditSharedProject } from '../../../../core/api/useEditSharedProject';
import { useUnlockProject } from '../../../../core/api/useUnlockProject';
import { getAssemblyTasksPerProject } from '../../../../core/api/VideoAssemblyAPI';
import { ElementsEnum } from '../../../../core/ui/enums/ElementsEnum';
import { ModeEnum } from '../../../../core/ui/enums/ModeEnum';
import { useIsPlaying } from '../../../../hooks/useIsPlaying';
import { usePreviewSize } from '../../../../hooks/usePreviewSize';
import useRoleAccess from '../../../../hooks/useRoleAccess';
import { VIDEO_ASSEMLBY_PING_INTERVAL } from '../../../../model/constants/constants';
import { C9ProjectDef } from '../../../../model/definitions/C9ProjectDef';
import { RolesEnum } from '../../../../model/enums/RolesEnum';
import SaveUndoRedo from '../../../../organisms/secondaryControls/SaveUndoRedo';
import UnsavedChanges from '../../../../organisms/unsavedChanges/UnsavedChanges';
import { ActiveDef, setMode } from '../../../../store/slices/active-slice';
import { MapLoadingState } from '../../../../store/slices/map-loading.slice';
import { addNewProject, setSavedProject } from '../../../../store/slices/project-slice';
import { RootState } from '../../../../store/store';
import AssemblyStatusModal from '../../../playground/modals/AssemblyStatusModal';
import SendAssemblyModal from '../../../playground/modals/SendAssemblyModal';
import ShareProjectModal from '../../../playground/modals/ShareProjectModal';
import ShowMore from '../../modals/ShowMore';
import CronStudioDrawer from '../cron-studio/CronStudioDrawer';
import styles from './TopBar.module.scss';

interface Props {
  onOpen?: () => void;
  addElement: () => void;
  stop?: () => void;
}

function Navbar(props: any) {
  return (
    <nav className={styles.leftPart}>
      <ul className={`${styles['navbar-nav']}`}>{props.children}</ul>
    </nav>
  );
}

const TopBar = ({ addElement, stop }: Props) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isPlaying = useIsPlaying();
  const height = screen.height;
  const { previewSize, setPreview } = usePreviewSize();
  const [showMore, setShowMore] = useState(false);
  const [showRender, setShowRender] = useState(false);
  const [renderType, setRenderType] = useState<ElementsEnum>();
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [showStatuses, setShowStatuses] = useState(false);
  const projectId = useSelector<RootState, string>((state) => state.project.present.project.id);
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project?.present?.project);
  const roleAccess = useRoleAccess(
    [RolesEnum.ROLE_CREATOR, RolesEnum.ROLE_MAINTAINER, RolesEnum.ROLE_FORECASTER],
    project.isSharedEntity,
    project.inEditMode,
  );
  const { mapIsLoading } = useSelector<RootState, MapLoadingState>((state) => state.mapLoading);
  const { mutate: editSharedProject } = useEditSharedProject();
  const roleProducer = useRoleAccess(
    [RolesEnum.ROLE_PRODUCER],
    project.isSharedEntity,
    project.inEditMode,
  );
  const rolePresenter = useRoleAccess(
    [RolesEnum.ROLE_PRESENTER],
    project.isSharedEntity,
    project.inEditMode,
  );
  const roleSuperAdmin = useRoleAccess(
    [RolesEnum.ROLE_SUPER_ADMIN],
    project.isSharedEntity,
    project.inEditMode,
  );
  const { mutate: assemble, isLoading } = useAssembleVideo();
  const { mutate: unlock } = useUnlockProject();
  const { data, isSuccess } = useQuery(
    ['render-status-by-project', projectId],
    () => getAssemblyTasksPerProject(projectId, 0, 100),
    { refetchInterval: VIDEO_ASSEMLBY_PING_INTERVAL },
  );
  useHotkeys('ctrl+q, command+q', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    switchMode(ModeEnum.SEQUENCE);
    return false;
  });
  useHotkeys('ctrl+u, command+u', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    switchMode(ModeEnum.PROJECT);
    return false;
  });

  useHotkeys('ctrl+shift+a, command+shift+a', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    addElement();
    return false;
  });

  const active = useSelector<RootState, ActiveDef>((state) => state.active);
  const maxLength = 40;
  function editShared() {
    editSharedProject(project.versionId);
  }

  const formatDateToUTC = (utcDate: number) => {
    return new Date(utcDate)
      .toLocaleDateString(undefined, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'UTC',
      })
      .split('/')
      .reverse()
      .join('-');
  };

  function onShowMore(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    e.stopPropagation();
    setShowMore((d) => !d);
  }
  function switchMode(mode: ModeEnum) {
    if (isPlaying) return;
    dispatch(setMode({ mode }));
    stop && stop();
  }

  function NavItem(props: any) {
    return (
      <li
        className={`${styles['optionsButton']}`}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        {props.children}
      </li>
    );
  }

  function navigateToDashboard() {
    navigate(`/`);
  }

  function getTitleCharLength(str: string) {
    return str?.slice(0, maxLength);
  }

  const saveMutation = useMutation(saveProject, {
    onSuccess: (data) => {
      if (data) {
        dispatch(addNewProject({ project: data }));
        dispatch(setSavedProject({ savedProject: data }));
        notification.success({ message: 'Project saved!' });
      }
    },
    onError: () => notification.error({ message: 'Failed to save project!' }),
  });

  const onSave = () => {
    saveMutation.mutate(project);
  };

  const sliderRef = useRef(null);

  const [openDrawer, setOpenDrawer] = useState(false);

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const sendThinClient = () => {
    const cloneProject = { ...project };
    assemble(
      {
        type: active.mode === ModeEnum.PROJECT ? 'project' : 'scene',
        projectDef: cloneProject,
        sceneDefId: active.mode === ModeEnum.SEQUENCE ? active.activeScene : null,
        sendToThinClient: true,
      },
      {
        onSuccess: (data) => {
          if (data.requestSentToQueue) {
            queryClient.invalidateQueries('render-status-by-project');
            toast.success('Successfully sent job to Thin Client');
          } else {
            toast.error('An error occurred, please try again later...');
          }
        },
        onError: () => {
          toast.error('Error occured while sending job to Thin Client');
        },
      },
    );
  };

  const unlockProject = () => {
    unlock(projectId);
  };

  return (
    <>
      <CronStudioDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
      <div className={styles.topBar}>
        <Navbar>
          <NavItem>
            <i
              className={`drop-down-icon ${roleAccess ? '' : 'pointer-events-none opacity-30'}`}
              title="Add new element (CTRL + SHIFT + A)"
            >
              <Button
                size={'small'}
                icon={
                  <>
                    <i>
                      <AiFillCaretDown />
                    </i>
                    <IoAdd />
                  </>
                }
                onClick={() => addElement()}
                className={styles.hoverState}
                disabled={isPlaying}
              />
            </i>
          </NavItem>
          <NavItem>
            <SaveUndoRedo />
          </NavItem>
          <p> {getTitleCharLength(project.name!)}</p>
          {project?.name?.length > maxLength ? (
            <span onClick={(e) => onShowMore(e)} className={`${styles['show-more-title']}`}>
              ...Show More
            </span>
          ) : null}
        </Navbar>
        <div className={'flex w-[300px] items-end'}>
          <AiOutlineZoomOut color={'white'} />
          <div className={'flex-1 text-white text-center'}>
            <p>Preview size</p>
            <Slider
              ref={sliderRef}
              min={250}
              max={height * 0.55}
              value={previewSize}
              onChange={(e) => typeof e === 'number' && setPreview(e)}
              disabled={isPlaying}
            />
          </div>
          <AiOutlineZoomIn color={'white'} />
        </div>
        <div className={'flex items-center relative'}>
          <div>
            <UnsavedChanges onSave={onSave} isLoading={saveMutation.isLoading} />
          </div>
          <div>
            <Button
              buttonType="primary"
              className={`${styles.linkText}`}
              onClick={navigateToDashboard}
              icon={<AiOutlineArrowLeft />}
              disabled={isPlaying}
              label="Dashboard"
            />
          </div>
          {active.mode === ModeEnum.SEQUENCE && (
            <div>
              <Button
                buttonType="secondary"
                className={`${styles.linkText}`}
                icon={<LuImagePlus />}
                label={'Create image'}
                onClick={() => {
                  setShowRender(true);
                  setRenderType(ElementsEnum.IMAGE);
                }}
                disabled={isPlaying}
              />
            </div>
          )}
          <div>
            <Button
              buttonType="secondary"
              className={`${styles.linkText}`}
              icon={<BiVideoPlus />}
              label={'Create video'}
              onClick={() => {
                setShowRender(true);
                setRenderType(ElementsEnum.VIDEO);
              }}
              disabled={isPlaying}
            />
          </div>
          <div>
            <Button
              buttonType="secondary"
              className={`${styles.linkText}`}
              icon={<RiComputerLine />}
              label={'Render for thin client'}
              onClick={sendThinClient}
              disabled={isPlaying}
              loading={isLoading}
            />
          </div>

          {isSuccess && Boolean(data?.content?.length) && (
            <div className="render-status">
              <Tooltip content="Render status" style={`dark`}>
                {data?.content?.length && (
                  <span className="notification">{data?.content?.length}</span>
                )}
                <Button
                  buttonType="secondary"
                  className={`${styles.linkText}`}
                  icon={<AiOutlineVideoCamera color="white" />}
                  //   label={'Render status'}
                  onClick={() => setShowStatuses(true)}
                  disabled={isPlaying}
                />
              </Tooltip>
            </div>
          )}
          <div>
            <Button
              buttonType="secondary"
              className={`${styles.linkText}`}
              icon={<AiOutlineSchedule />}
              label={'Scheduler'}
              onClick={showDrawer}
              disabled={isPlaying}
            />
          </div>
          {project?.isSharedEntity && !project?.inEditMode && (
            <div>
              <Button
                buttonType="secondary"
                className={`${styles.linkText}`}
                icon={<AiOutlineEdit />}
                label={'Edit Mode'}
                onClick={editShared}
                disabled={isPlaying}
              />
            </div>
          )}
          {project?.inEditMode && (
            <div className="flex items-center">
              {roleSuperAdmin && (
                <Button
                  buttonType="secondary"
                  className={`${styles.linkText}`}
                  icon={<CgLockUnlock />}
                  label={'Unlock'}
                  onClick={unlockProject}
                />
              )}
              <div className="flex-shrink-0">
                <AiFillLock className="text-red-500" />
              </div>
              <div className="flex flex-col ml-2">
                <span className="text-white text-xs">{`${project.editor}`}</span>
                <span className="text-white text-xs">
                  {formatDateToUTC(project.editingStartDate)}
                </span>
              </div>
            </div>
          )}

          {(project?.isEditVersion || !project.isSharedEntity) && (
            <div>
              <Button
                buttonType="secondary"
                className={`${styles.linkText}`}
                icon={<AiOutlineShareAlt />}
                label={'Share'}
                onClick={() => setShowSharePopup(true)}
                disabled={isPlaying}
              />
            </div>
          )}
          <div
            className={`${styles.btnSeq} ${active.mode === ModeEnum.SEQUENCE && styles.active}`}
            onClick={() => switchMode(ModeEnum.SEQUENCE)}
            style={{ marginLeft: '1rem' }}
          >
            SEQ
          </div>
          <div
            className={`${styles.btnSto} ${active.mode === ModeEnum.PROJECT && styles.active} ${
              (rolePresenter || roleProducer) && !mapIsLoading
                ? ''
                : 'pointer-events-none opacity-30'
            }`}
            onClick={() => switchMode(ModeEnum.PROJECT)}
          >
            STO
          </div>
        </div>
        {showMore && <ShowMore description={project.name} opened={showMore} onClose={onShowMore} />}
        {showRender && (
          <SendAssemblyModal
            onClose={() => setShowRender(false)}
            opened={showRender}
            renderType={renderType}
          />
        )}
        {showSharePopup && (
          <ShareProjectModal
            onClose={() => setShowSharePopup(false)}
            opened={showSharePopup}
            isEditVersion={project.isEditVersion}
            projectId={project.id}
          />
        )}
        {showStatuses && (
          <AssemblyStatusModal
            onClose={() => setShowStatuses(false)}
            opened={showStatuses}
            statuses={data!.content}
          />
        )}
      </div>
    </>
  );
};

export default TopBar;
