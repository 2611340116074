import React from 'react';

import { useFontLoader } from '../../core/api/useLoadFont';
import { RichTextChildDef } from '../../model/definitions/RichTextChildDef';

function TextWithFontFamily({ node, element }: { node: RichTextChildDef; element: JSX.Element }) {
  useFontLoader(node.fontFamily ?? '');
  if (React.isValidElement(element)) {
    return element;
  }
  return (
    <span
      style={{
        fontFamily: `${node.fontFamily} ${node.fontType}`,
        textTransform: node.textTransform,
      }}
    >
      {element}
    </span>
  );
}

export default TextWithFontFamily;
