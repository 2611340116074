import { bezier as turfBezier } from '@turf/turf';
import { isEqual } from 'lodash';
import Circle from 'ol/geom/Circle';
import LineString from 'ol/geom/LineString';
import MultiPoint from 'ol/geom/MultiPoint';
import Polygon from 'ol/geom/Polygon';
import { Circle as CircleStyle, RegularShape } from 'ol/style';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

import { MAX_FULLSCREEN_HEIGHT } from '../../model/constants/constants';
import { transformPercentToAbsolute } from '../../molecules/canvasElements/utils';
import { getDrawingType, getFrontType } from './helpers';
/**For circle around selected image */
const BORDER_WIDTH = 5;

export const COLOR_PROPERTIES = [
  'coldFront',
  'occludedFront',
  'outflowFront',
  'stationaryCold',
  'stationaryWarm',
  'warmFront',
  'lineColor',
];

export const GLOBAL_FRONT_PROPERTIES = [
  ...COLOR_PROPERTIES,
  'zIndex',
  'lineThickness',
  'frontDensity',
];

// export function setColorsToProperties(propertiesToSet, propertiesToMutate) {
//   COLOR_PROPERTIES.forEach((prop) => {
//     if (propertiesToSet[prop]) {
//       propertiesToMutate[prop] = propertiesToSet[prop];
//     }
//   });
// }

export const drawingColors = {
  lineFill: 'rgba(37, 150, 190, 1)',
  objectFill: 'rgba(37, 150, 190, 1)',
  warmFront: 'rgba(254, 0, 0, 1)',
  coldFront: 'rgba(51, 50, 204, 1)',
  occludedFront: 'rgba(148, 0, 212, 1)',
  occludedFrontV2: 'rgba(148, 0, 212, 1)',
  outflowFront: 'rgba(228, 155, 15, 1)',
};

const SEGMENT_SIZE = 50;

const RED_COLOR = 'rgba(254, 0, 0, 1)';
const SELECTED_COLOR = '#FFFF00';
const propertiesOfInterest = [
  'fillColor',
  'fill',
  'arrowType',
  'lineColor',
  'arrayOfTurningPoints',
  'oneDashLength',
  'drawingType',
  'lineThickness',
  'selected',
  'frontType',
  'frontDensity',
  'partSize',
  'hornOrientation',
  'lineGap',
];

export const stylesCache = {};

export function cleanDuplcateCoordinatesForBezierCurve(lineString) {
  if (lineString.length <= 2) return lineString;
  return lineString.filter(
    (coordinate, index, self) =>
      index === self.findIndex((t) => t[0] === coordinate[0] && t[1] === coordinate[1]),
  );
}

const getSmoothLineString = (lineString) => {
  const removedDuplicates = cleanDuplcateCoordinatesForBezierCurve(lineString);

  const hornCurve = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: removedDuplicates,
    },
  };
  const hl = turfBezier(hornCurve);
  return hl['geometry']['coordinates'];
};

const calculatePixelDistance = (coordinate1, coordinate2, map) => {
  const pixel1 = map.getPixelFromCoordinate(coordinate1);
  const pixel2 = map.getPixelFromCoordinate(coordinate2);
  return Math.sqrt(Math.pow(pixel2[0] - pixel1[0], 2) + Math.pow(pixel2[1] - pixel1[1], 2));
};

const vertexOfEquilateralTriangle = (start, end, side) => {
  let thirdX;
  let thirdY;
  const [xs, ys] = start;
  const [xe, ye] = end;
  if (side) {
    thirdX = (xs + xe + Math.sqrt(3) * (ye - ys)) / 2;
    thirdY = (ys + ye + Math.sqrt(3) * (xs - xe)) / 2;
  } else {
    thirdX = (xs + xe + Math.sqrt(3) * (ys - ye)) / 2;
    thirdY = (ys + ye + Math.sqrt(3) * (xe - xs)) / 2;
  }

  return [thirdX, thirdY];
};
//Calculating translated coordinates on move
const getTranslatedCoordinates = (
  arrayOfTurningPoints,
  startTranslationCoordinate,
  endTranslationCoordinate,
) => {
  let dx = Math.abs(endTranslationCoordinate[0] - startTranslationCoordinate[0]);
  let dy = Math.abs(endTranslationCoordinate[1] - startTranslationCoordinate[1]);
  let translatedTurningPoints = [];
  let [sx, sy] = startTranslationCoordinate;
  let [ex, ey] = endTranslationCoordinate;
  for (let i = 0; i < arrayOfTurningPoints.length; i++) {
    let coordinate = [];
    if (ex > sx) {
      let newX = arrayOfTurningPoints[i][0] + dx;
      coordinate.push(newX);
    } else {
      let newX = arrayOfTurningPoints[i][0] - dx;
      coordinate.push(newX);
    }
    if (ey > sy) {
      let newY = arrayOfTurningPoints[i][1] + dy;
      coordinate.push(newY);
    } else {
      let newY = arrayOfTurningPoints[i][1] - dy;
      coordinate.push(newY);
    }
    translatedTurningPoints.push(coordinate);
  }
  return translatedTurningPoints;
};

//Setting feature property values
const setFeatureProperties = (feature, formData, arrayOfTurningPoints, resolution) => {
  feature.setProperties({ featureId: feature['ol_uid'] });
  feature.setProperties({ drawingType: getDrawingType(formData.drawingType) });
  feature.setProperties({ lineThickness: formData.strokeWidth });
  feature.setProperties({ resolution: resolution });
  feature.setProperties({ zIndex: formData.zIndex });
  feature.setProperties({ warmFront: formData.warmFront });
  feature.setProperties({ coldFront: formData.coldFront });
  feature.setProperties({ occludedFront: formData.occludedFront });
  feature.setProperties({ occludedFrontV2: formData.occludedFrontV2 });
  feature.setProperties({ outflowFront: formData.outflowFront });
  feature.setProperties({ stationaryWarm: formData.stationaryWarm });
  feature.setProperties({ stationaryCold: formData.stationaryCold });
  feature.setProperties({ selected: false });
  feature.setProperties({ image: formData.image });
  feature.setProperties({ imageWidth: formData.imageWidth });
  feature.setProperties({ imageHeight: formData.imageHeight });
  feature.setProperties({ name: formData.name });
  feature.setProperties({ isAnimation: Boolean(formData.isAnimation) });
  if (formData.thumbnail) {
    feature.setProperties({ thumbnail: formData.thumbnail });
  }
  if (arrayOfTurningPoints?.length > 0) {
    feature.setProperties({ arrayOfTurningPoints: arrayOfTurningPoints });
  }

  if (formData.drawingType === 'Fronts') {
    feature.setProperties({ frontType: getFrontType(formData.frontType) });
    const geometry = feature.getGeometry();
    let lengthInPixels = geometry.getLength() / resolution;
    let frontDensity = Math.ceil(lengthInPixels / SEGMENT_SIZE);
    if (frontDensity < 1) {
      frontDensity = 1;
    }
    feature.setProperties({ frontDensity: frontDensity });
    feature.setProperties({ partSize: parseInt(formData.sizeOnePart) || 1 });
    feature.setProperties({ originalPartSize: parseInt(formData.sizeOnePart) || 1 });
    feature.setProperties({
      hornOrientation: formData.hornOrientation === undefined ? true : formData.hornOrientation,
    });
    feature.setProperties({ lineGap: formData.lineGap });
    feature.setProperties({ oneDashLength: formData.oneDashLength });
  } else if (formData.drawingType === 'Arrow') {
    feature.setProperties({ lineColor: formData.lineFill });
    feature.setProperties({ arrowType: formData.arrowType });
  } else {
    feature.setProperties({ lineColor: formData.lineFill });
    feature.setProperties({ fill: formData.objectFill });
    feature.setProperties({ fillColor: formData.objectFill });
  }
  return feature;
};

export function areFeaturePropertiesEqual(featureOne, featureTwo) {
  let equal = true;
  for (let property of propertiesOfInterest) {
    if (!isEqual(featureOne.get(property), featureTwo.get(property))) {
      equal = false;
      return equal;
    }
  }
  return equal;
}

//Making of arrowhead
const makeArrow = (geometry, arrowType, arrowColor, arrowWidth, map) => {
  let points = [];
  let styles = [];
  for (let i = 0; i < 1; i += 0.001) {
    points.push(geometry.getCoordinateAt(i));
  }

  let lineString = new LineString(points);
  styles.push(
    new Style({
      geometry: lineString,
      stroke: new Stroke({
        color: arrowColor,
        width: arrowWidth,
      }),
    }),
  );

  let endPoint = lineString.getCoordinateAt(1);

  let startPoint;
  for (let i = 500; i >= 0; i--) {
    startPoint = geometry.getCoordinates()[i];

    if (calculatePixelDistance(endPoint, startPoint, map) > 50) {
      break;
    }
  }
  let tempLineString = new LineString([startPoint, endPoint]);
  let beforeEnd = tempLineString.getCoordinateAt(0.5);
  let thirdVertex = vertexOfEquilateralTriangle(startPoint, endPoint, true);
  let forthVertex = vertexOfEquilateralTriangle(startPoint, endPoint, false);
  let hLineOne = new LineString([beforeEnd, thirdVertex]);
  let hLineTwo = new LineString([beforeEnd, forthVertex]);
  let pointOnHLineOne = hLineOne.getCoordinateAt(0.4);
  let pointOnHLineTwo = hLineTwo.getCoordinateAt(0.4);

  let arrowFullness;
  if (arrowType === 'solid') {
    arrowFullness = tempLineString.getCoordinateAt(0.5);
  } else if (arrowType === 'medium') {
    arrowFullness = tempLineString.getCoordinateAt(0.6);
  } else if (arrowType === 'thin') {
    arrowFullness = tempLineString.getCoordinateAt(0.8);
  }

  let arrowPolygon = new Polygon([
    [endPoint, pointOnHLineOne, arrowFullness, pointOnHLineTwo, endPoint],
  ]);
  styles.push(
    new Style({
      geometry: arrowPolygon,
      stroke: new Stroke({
        color: arrowColor,
        width: arrowWidth,
      }),
      fill: new Fill({
        color: arrowColor,
      }),
    }),
  );
  return styles;
};

//Making of hal circle line
const getCirclePoints = (radius, center, steps, leftPoint, rightPoint, side) => {
  let circlePoints = [];
  let centerAdded = false;
  for (let i = 0; i < steps; i++) {
    let xValue = center[0] + radius * Math.cos((2 * Math.PI * i) / steps);
    let yValue = center[1] + radius * Math.sin((2 * Math.PI * i) / steps);
    let currentPoint = [xValue, yValue];
    if (isLeft(leftPoint, rightPoint, currentPoint) !== side) {
      circlePoints.push(currentPoint);
    } else if (!centerAdded) {
      circlePoints.push(center);
      centerAdded = true;
    }
  }
  return circlePoints;
};
const isLeft = (PointA, PointB, PointC) => {
  return (
    (PointB[0] - PointA[0]) * (PointC[1] - PointA[1]) -
      (PointB[1] - PointA[1]) * (PointC[0] - PointA[0]) >
    0
  );
};
//Making one segment of front
const makeSegment = (
  geometry,
  start,
  end,
  hornType,
  partColor,
  moveLR,
  side,
  lineThickness,
  isEven = undefined /**For occluded front type 2 - if not undefined is occludedV2 */,
) => {
  let points = [];
  let styles = [];
  let deltaI = (end - start) / 20;
  for (let i = start; i < end; i += deltaI) {
    points.push(geometry.getCoordinateAt(i));
  }

  let lineStr = new LineString(points);
  styles.push(
    new Style({
      geometry: lineStr,
      stroke: new Stroke({
        color: partColor,
        width: lineThickness,
      }),
    }),
  );
  let centralPoint = lineStr.getCoordinateAt(0.5);
  let leftPoint = lineStr.getCoordinateAt(0.5 - moveLR);
  let rightPoint = lineStr.getCoordinateAt(0.5 + moveLR);

  if (isEven !== undefined) {
    if (isEven) {
      centralPoint = lineStr.getCoordinateAt(1 - moveLR);
      leftPoint = lineStr.getCoordinateAt(1 - 2 * moveLR);
      rightPoint = lineStr.getCoordinateAt(1);
    } else {
      centralPoint = lineStr.getCoordinateAt(moveLR);
      leftPoint = lineStr.getCoordinateAt(0);
      rightPoint = lineStr.getCoordinateAt(2 * moveLR);
    }
  }
  let thirdVertex = vertexOfEquilateralTriangle(leftPoint, rightPoint, side);
  let hLine = new LineString([centralPoint, thirdVertex]);
  let tempThirdVertex = hLine.getCoordinateAt(0.8);
  let hornLine;
  if (hornType === 'triangle') {
    hornLine = new Polygon([[leftPoint, tempThirdVertex, rightPoint, centralPoint, leftPoint]]);

    styles.push(
      new Style({
        geometry: hornLine,
        stroke: new Stroke({
          color: partColor,
          width: 2,
        }),
        fill: new Fill({
          color: partColor,
        }),
      }),
    );
  } else {
    let radius = Math.sqrt(
      Math.pow(centralPoint[0] - leftPoint[0], 2) + Math.pow(centralPoint[1] - leftPoint[1], 2),
    );

    let makeHalfCircle = getCirclePoints(radius, centralPoint, 76, leftPoint, rightPoint, side);
    let hornLine = new Polygon([makeHalfCircle]);

    styles.push(
      new Style({
        geometry: hornLine,
        stroke: new Stroke({
          color: partColor,
          width: 2,
        }),
        fill: new Fill({
          color: partColor,
        }),
      }),
    );
  }
  return styles;
};
//Making of interrupted line of front type
const makeInterruptedLine = (
  geometry,
  feature,
  interspace,
  onePartLength,
  width,
  isSelected = false,
) => {
  return new Style({
    geometry: geometry,
    stroke: new Stroke({
      color: isSelected
        ? SELECTED_COLOR
        : feature.getProperties()?.outflowFront
        ? feature.getProperties()?.outflowFront
        : 'rgba(228, 155, 15, 1)',
      width,
      lineDash: [onePartLength, interspace],
    }),
  });
};

/******************************Style function*************************************/
const styleForFronts = (
  feature,
  resolution,
  isSelected = false,
  layerId = '',
  useCache = false,
) => {
  if (
    layerId &&
    stylesCache[layerId] &&
    areFeaturePropertiesEqual(feature, stylesCache[layerId].feature) &&
    useCache
  ) {
    return stylesCache[layerId].styles;
  }

  let frontDensity = feature['values_']['frontDensity']; // number of horns
  let partSize = feature['values_']['partSize'] || 1; // percent of segment that horn will occupy
  let frontsType = feature['values_']['frontType'];
  let hornOrientation = feature['values_']['hornOrientation'];
  let dashLength = feature['values_']['oneDashLength'];
  let lineGap = feature['values_']['lineGap'];
  let lineThickness = feature['values_']['lineThickness'];
  let res = feature['values_']['resolution'];

  if (lineThickness < 1) {
    lineThickness = 1;
  }
  /**Make sure not below 1 number of horns and size of horn */

  if (partSize < 1) {
    partSize = 1;
  }
  /**Do not allow more then 100% */
  if (partSize > 100) {
    partSize = 100;
  }

  const geometry = feature.getGeometry();
  const styles = [];

  if (res && res !== resolution) {
    frontDensity = (res / resolution) * frontDensity;
  }

  if (frontDensity !== undefined && frontDensity < 1) {
    frontDensity = 1;
  }

  const isOccludedV2 = frontsType === 'occludedFrontV2';

  // let hornCount = Math.ceil(lengthInPixels / frontDensity);
  /**Use number of horns from form */
  let hornCount = Math.round(frontDensity);
  /**if occluded V2 make it even always */
  if (isOccludedV2 && hornCount % 2 !== 0) {
    hornCount += 1;
  }

  // hornCount = Math.ceil(lengthInPixels / frontDensity);

  /**Part size percent of 0.5 - 0.5 will fill whole area for one horn -> is 100% */
  const moveLR = (0.5 / 100) * partSize;

  for (let i = 0; i < hornCount; i++) {
    let start = i / hornCount;
    let end = (i + 1) / hornCount;

    let segment;
    switch (frontsType) {
      case 'warmFront':
        segment = makeSegment(
          geometry,
          start,
          end,
          'round',
          isSelected
            ? SELECTED_COLOR
            : feature.getProperties()?.warmFront
            ? feature.getProperties()?.warmFront
            : RED_COLOR,
          moveLR,
          hornOrientation,
          lineThickness,
        );
        styles.push(segment[0]);
        styles.push(segment[1]);
        break;
      case 'coldFront':
        segment = makeSegment(
          geometry,
          start,
          end,
          'triangle',
          isSelected
            ? SELECTED_COLOR
            : feature.getProperties()?.coldFront
            ? feature.getProperties()?.coldFront
            : 'rgba(51, 50, 204, 1)',
          moveLR,
          hornOrientation,
          lineThickness,
        );
        styles.push(segment[0]);
        styles.push(segment[1]);
        break;
      case 'stationaryFront':
        if (i % 2 === 0) {
          segment = makeSegment(
            geometry,
            start,
            end,
            'round',
            isSelected
              ? SELECTED_COLOR
              : feature.getProperties()?.stationaryWarm
              ? feature.getProperties()?.stationaryWarm
              : RED_COLOR,
            moveLR,
            hornOrientation,
            lineThickness,
          );
          styles.push(segment[0]);
          styles.push(segment[1]);
        } else {
          segment = makeSegment(
            geometry,
            start,
            end,
            'triangle',
            isSelected
              ? SELECTED_COLOR
              : feature.getProperties()?.stationaryCold
              ? feature.getProperties()?.stationaryCold
              : 'rgba(51, 50, 204, 1)',
            moveLR,
            !hornOrientation,
            lineThickness,
          );
          styles.push(segment[0]);
          styles.push(segment[1]);
        }
        break;
      case 'occludedFront':
        if (i % 2 === 0) {
          segment = makeSegment(
            geometry,
            start,
            end,
            'round',
            isSelected
              ? SELECTED_COLOR
              : feature.getProperties()?.occludedFront
              ? feature.getProperties()?.occludedFront
              : 'rgba(148, 0, 212, 1)',
            moveLR,
            hornOrientation,
            lineThickness,
          );
          styles.push(segment[0]);
          styles.push(segment[1]);
        } else {
          segment = makeSegment(
            geometry,
            start,
            end,
            'triangle',
            isSelected
              ? SELECTED_COLOR
              : feature.getProperties()?.occludedFront
              ? feature.getProperties()?.occludedFront
              : 'rgba(148, 0, 212, 1)',
            moveLR,
            hornOrientation,
            lineThickness,
          );
          styles.push(segment[0]);
          styles.push(segment[1]);
        }
        break;

      case 'occludedFrontV2':
        if (i % 2 === 0) {
          segment = makeSegment(
            geometry,
            start,
            end,
            'round',
            isSelected
              ? SELECTED_COLOR
              : feature.getProperties()?.occludedFrontV2
              ? feature.getProperties()?.occludedFrontV2
              : 'rgba(148, 0, 212, 1)',
            moveLR,
            hornOrientation,
            lineThickness,
            true,
          );
          styles.push(segment[0]);
          styles.push(segment[1]);
        } else {
          segment = makeSegment(
            geometry,
            start,
            end,
            'triangle',
            isSelected
              ? SELECTED_COLOR
              : feature.getProperties()?.occludedFrontV2
              ? feature.getProperties()?.occludedFrontV2
              : 'rgba(148, 0, 212, 1)',
            moveLR,
            hornOrientation,
            lineThickness,
            false,
          );
          styles.push(segment[0]);
          styles.push(segment[1]);
        }
        break;
      case 'troughOrOutflowBoundary':
        styles.push(
          makeInterruptedLine(geometry, feature, lineGap, dashLength, lineThickness, isSelected),
        );
        break;
      default:
        console.log('Unknown front type');
    }
  }

  stylesCache[layerId] = {
    feature: feature.clone(),
    styles,
    resolution,
  };
  if (feature.get('modifyActive')) {
    /**Style modify vertices */
    const verticeStyle = new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({
          color: 'black',
        }),
      }),
      geometry: function (feature) {
        const coordinates = feature.getGeometry().getCoordinates();

        return new MultiPoint(coordinates);
      },
    });

    styles.push(verticeStyle);
  }
  return styles;
};
const styleFunctionForBasicShapes = (color, fill, fillColor, lineThickness, isSelected = false) => {
  const styles = [];
  styles.push(
    new Style({
      stroke: new Stroke({
        color: isSelected ? SELECTED_COLOR : color,
        width: lineThickness,
      }),
      fill: new Fill({
        color: isSelected ? SELECTED_COLOR : fill ? fillColor : 'rgba(255, 255, 255, 0)',
      }),
    }),
  );
  return styles;
};

const getStyleForImage = (
  properties,
  activeAspectRatio,
  isSelected = false,
  imageHack = false,
  canvas,
) => {
  const blob = properties['image'] && properties['image'].includes('blob:');

  if (blob || isSelected || imageHack || canvas) {
    const width = transformPercentToAbsolute(
      Number(properties['imageWidth']),
      activeAspectRatio,
      'width',
      MAX_FULLSCREEN_HEIGHT,
    );

    const height = transformPercentToAbsolute(
      Number(properties['imageHeight']),
      activeAspectRatio,
      'height',
      MAX_FULLSCREEN_HEIGHT,
    );
    let borderStyle = new Style();

    if (isSelected) {
      const halfDiagonal = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));

      borderStyle = new Style({
        image: new RegularShape({
          radius: halfDiagonal + BORDER_WIDTH,
          points: 30,

          stroke: new Stroke({
            color: SELECTED_COLOR,
            width: BORDER_WIDTH,
          }),
          angle: Math.PI / 4,
        }),
      });
    }

    const imageStyle = canvas
      ? new Style({
          image: new Icon({
            img: canvas,
            width: width,
            height: height,
            imgSize: [canvas.width, canvas.height],
            // color: isSelected ? SELECTED_COLOR : undefined,
          }),
        })
      : new Style({
          image: new Icon({
            src: properties['image'],
            width,
            height,
            // color: isSelected ? SELECTED_COLOR : undefined,
          }),
        });

    return isSelected ? [borderStyle, imageStyle] : imageStyle;
  } else {
    return [];
  }
};

const getStyleForCircle = (properties, isSelected = false) => {
  return new Style({
    stroke: new Stroke({
      color: isSelected ? SELECTED_COLOR : properties['lineColor'],
      width: properties['lineThickness'],
    }),
    fill: new Fill({
      color: isSelected
        ? SELECTED_COLOR
        : properties['fill']
        ? properties['fillColor']
        : 'rgba(255, 255, 255, 0)',
    }),
    geometry: function (feature) {
      return new Circle(feature.getGeometry().getFlatCoordinates(), feature.get('circleRadius'));
    },
  });
};

const styleFunctionForArrow = (feature, resolution, map, isSelected = false) => {
  const styles = [];
  if (isSelected) {
    const selectedStyle = new Style({
      stroke: new Stroke({
        color: SELECTED_COLOR,
        width: 2,
      }),
      fill: new Fill({
        color: SELECTED_COLOR,
      }),
    });
    styles.push(selectedStyle);

    if (feature.get('modifyActive')) {
      /**Style modify vertices */
      const verticeStyle = new Style({
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: 'black',
          }),
        }),
        geometry: function (feature) {
          const coordinates = feature.getGeometry().getCoordinates();

          return new MultiPoint(coordinates);
        },
      });

      styles.push(verticeStyle);
    }
    return styles;
  }

  let geometry = feature.getGeometry();
  let arrowSegment = makeArrow(
    geometry,
    'medium',
    isSelected ? SELECTED_COLOR : feature['values_']['lineColor'],
    feature['values_']['lineThickness'],
    map,
  );
  styles.push(arrowSegment[0]);
  styles.push(arrowSegment[1]);

  return styles;
};
/*******************************Geometry functions*********************************/
let geometryFunctionForArrow = (coordinates, geometry) => {
  if (!geometry) {
    geometry = new LineString([]);
    return geometry;
  }
  let curved = getSmoothLineString(coordinates);
  geometry.setCoordinates(curved);
  return geometry;
};

let geometryFunctionForCurvedClosedLine = function (coordinates, geometry) {
  if (!geometry) {
    geometry = new Polygon([]);
  }
  let extendedCoordinates = bezier(coordinates.concat(coordinates, coordinates, [coordinates[0]]), {
    resolution: 30000,
  });
  let length = extendedCoordinates.length / 3;
  let section = extendedCoordinates.slice(length, length * 2);
  geometry.setCoordinates([section.concat([section[0]])]);
  return geometry;
};

/*******************************Making of curved closed line***********************/
let Spline = function (options) {
  this.points = options.points || [];
  this.duration = options.duration || 10000;
  this.sharpness = options.sharpness || 0.85;
  this.centers = [];
  this.controls = [];
  this.stepLength = options.stepLength || 60;
  this.length = this.points.length;
  this.delay = 0;
  for (let i = 0; i < this.length; i++) this.points[i].z = this.points[i].z || 0;
  for (let i = 0; i < this.length - 1; i++) {
    let p1 = this.points[i];
    let p2 = this.points[i + 1];
    this.centers.push({
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
      z: (p1.z + p2.z) / 2,
    });
  }
  this.controls.push([this.points[0], this.points[0]]);
  for (let i = 0; i < this.centers.length - 1; i++) {
    let dx = this.points[i + 1].x - (this.centers[i].x + this.centers[i + 1].x) / 2;
    let dy = this.points[i + 1].y - (this.centers[i].y + this.centers[i + 1].y) / 2;
    let dz = this.points[i + 1].z - (this.centers[i].y + this.centers[i + 1].z) / 2;
    this.controls.push([
      {
        x:
          (1.0 - this.sharpness) * this.points[i + 1].x + this.sharpness * (this.centers[i].x + dx),
        y:
          (1.0 - this.sharpness) * this.points[i + 1].y + this.sharpness * (this.centers[i].y + dy),
        z:
          (1.0 - this.sharpness) * this.points[i + 1].z + this.sharpness * (this.centers[i].z + dz),
      },
      {
        x:
          (1.0 - this.sharpness) * this.points[i + 1].x +
          this.sharpness * (this.centers[i + 1].x + dx),
        y:
          (1.0 - this.sharpness) * this.points[i + 1].y +
          this.sharpness * (this.centers[i + 1].y + dy),
        z:
          (1.0 - this.sharpness) * this.points[i + 1].z +
          this.sharpness * (this.centers[i + 1].z + dz),
      },
    ]);
  }
  this.controls.push([this.points[this.length - 1], this.points[this.length - 1]]);
  this.steps = this.cacheSteps(this.stepLength);
  return this;
};

Spline.prototype.cacheSteps = function (mindist) {
  let steps = [];
  let laststep = this.pos(0);
  steps.push(0);
  for (let t = 0; t < this.duration; t += 10) {
    let step = this.pos(t);
    let dist = Math.sqrt(
      (step.x - laststep.x) * (step.x - laststep.x) +
        (step.y - laststep.y) * (step.y - laststep.y) +
        (step.z - laststep.z) * (step.z - laststep.z),
    );
    if (dist > mindist) {
      steps.push(t);
      laststep = step;
    }
  }
  return steps;
};

Spline.prototype.pos = function (time) {
  function bezier(t, p1, c1, c2, p2) {
    let B = function (t) {
      let t2 = t * t,
        t3 = t2 * t;
      return [t3, 3 * t2 * (1 - t), 3 * t * (1 - t) * (1 - t), (1 - t) * (1 - t) * (1 - t)];
    };
    let b = B(t);
    return {
      x: p2.x * b[0] + c2.x * b[1] + c1.x * b[2] + p1.x * b[3],
      y: p2.y * b[0] + c2.y * b[1] + c1.y * b[2] + p1.y * b[3],
      z: p2.z * b[0] + c2.z * b[1] + c1.z * b[2] + p1.z * b[3],
    };
  }
  let t = time - this.delay;
  if (t < 0) t = 0;
  if (t > this.duration) t = this.duration - 1;
  let t2 = t / this.duration;
  if (t2 >= 1) return this.points[this.length - 1];

  let n = Math.floor((this.points.length - 1) * t2);
  let t1 = (this.length - 1) * t2 - n;
  return bezier(
    t1,
    this.points[n],
    this.controls[n][1],
    this.controls[n + 1][0],
    this.points[n + 1],
  );
};

let bezier = (lineCoordinates, options) => {
  options = options || {};
  let resolution = options.resolution || 10000;
  let sharpness = options.sharpness || 0.85;

  let coords = [];
  let spline = new Spline({
    points: lineCoordinates.map(function (pt) {
      return { x: pt[0], y: pt[1] };
    }),
    duration: resolution,
    sharpness: sharpness,
  });

  for (let i = 0; i < spline.duration; i += 10) {
    let pos = spline.pos(i);
    if (Math.floor(i / 100) % 2 === 0) {
      coords.push([pos.x, pos.y]);
    }
  }

  return coords;
};

export {
  geometryFunctionForArrow,
  geometryFunctionForCurvedClosedLine,
  getSmoothLineString,
  getStyleForCircle,
  getStyleForImage,
  getTranslatedCoordinates,
  setFeatureProperties,
  styleForFronts,
  styleFunctionForArrow,
  styleFunctionForBasicShapes,
};
