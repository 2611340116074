import { Empty } from 'antd';
import { useState } from 'react';
import { MdOutlineImageNotSupported } from 'react-icons/md';

import Button from '../../../atoms/button/Button';
import Modal from '../../../molecules/modal/Modal';
import useGetMultimedia from '../../dashboard/queries-NEW/useGetMultimedia';

interface Props {
  isOpen: boolean;
  currentImage: string;
  currentName?: string;
  onClose: () => void;
  onSave: (image: string, name: string, isAnimation?: boolean, thumb?: string) => void;
  includeAnimations?: boolean;
}

function SymbolImageModal({
  isOpen,
  currentImage,
  currentName,
  onClose,
  onSave,
  includeAnimations = false,
}: Props) {
  const { data: images } = useGetMultimedia({
    page: 0,
    type: 'symbol',
    size: 1000,
  });
  const { data: animations } = useGetMultimedia({
    page: 0,
    type: 'animation',
    size: 1000,
  });
  const [selectedImage, setSelectedImage] = useState<string>(currentImage);
  const [selectedThumb, setSelectedThumb] = useState<string>('');
  const [name, setName] = useState<string>(currentName ?? '');
  const [isAnimation, setIsAnimation] = useState(false);

  const handleChange = (
    image: string,
    name: string,
    isAnimation: boolean | null,
    thumb: string,
  ) => {
    setSelectedImage(image);
    setName(name);
    setIsAnimation(Boolean(isAnimation));
    setSelectedThumb(thumb);
  };

  const handleSave = () => {
    onSave(selectedImage, name, isAnimation, selectedThumb);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={
        '!h-auto !w-1/2 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 !-mt-[50px]'
      }
      header={'Select image'}
      footer={
        <div className={'modal-footer flex justify-end'}>
          <Button
            label={'Save'}
            buttonType="primary"
            onClick={handleSave}
            disabled={!selectedImage}
          />
          <Button label={'Cancel'} buttonType="secondary" onClick={onClose} />
        </div>
      }
    >
      <div className={'grid grid-cols-5 gap-4 m-4 max-h-96 max-w-[95%]'}>
        {(images?.content && images?.content?.length > 0) ||
        (includeAnimations && animations?.content && animations?.content?.length > 0) ? (
          <>
            {images?.content?.map((image: any) => (
              <div
                onClick={() =>
                  handleChange(
                    image.thumbnailURLs![0],
                    image.name,
                    image.jsonAnimation,
                    image.thumbnailURLs![0],
                  )
                }
                key={image.versionId}
                className={`border border-solid ${
                  selectedImage === image.thumbnailURLs![0]
                    ? 'border-green-500 border-4'
                    : 'border-slate-200'
                } cursor-pointer`}
              >
                <img src={image.thumbnailURLs![0]} className="aspect-square object-cover" />
                <div
                  className={`text-center text-sm mt-1 ${
                    selectedImage === image.thumbnailURLs![0] ? 'text-white' : 'text-gray-400'
                  }`}
                >
                  {image.name.length > 50 ? `${image.name.substring(0, 70)}...` : image.name}
                </div>
              </div>
            ))}
            {Boolean(includeAnimations) &&
              animations?.content?.map((animation: any) => (
                <div
                  onClick={() =>
                    handleChange(
                      animation.versionId,
                      animation.name,
                      animation.jsonAnimation,
                      animation.thumbnailURLs![0],
                    )
                  }
                  key={animation.versionId}
                  className={`border border-solid ${
                    selectedImage === animation.thumbnailURLs![0]
                      ? 'border-green-500 border-4'
                      : 'border-slate-200'
                  } cursor-pointer`}
                >
                  <img src={animation.thumbnailURLs![0]} className="aspect-square object-cover" />
                  <div
                    className={`text-center text-sm mt-1 ${
                      selectedImage === animation.thumbnailURLs![0] ? 'text-white' : 'text-gray-400'
                    }`}
                  >
                    {animation.name.length > 50
                      ? `${animation.name.substring(0, 70)}...`
                      : animation.name}
                  </div>
                </div>
              ))}
          </>
        ) : (
          <div className=" col-span-5">
            <Empty
              image={<MdOutlineImageNotSupported className="text-[128px]" />}
              description={<span className="text-xl">No data</span>}
            ></Empty>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default SymbolImageModal;
