// eslint-disable-next-line import/no-unresolved
import { Property } from 'csstype';
import React, { useRef } from 'react';

import { gradientOpacity, singleColorOpacity } from '../../../helpers/convertOpacity';
import { MAX_FULLSCREEN_HEIGHT } from '../../../model/constants/constants';
import { PositionControlDef } from '../../../model/definitions/PositionControlDef';
import { TextAnimation } from '../../../model/definitions/TextAnimation';
import { TimeControlDef } from '../../../model/definitions/TimeControlDef';
import { BlendMode, ShadowSetup } from '../../../model/types/textShadow';
import { translateText } from '../utils';

/**
 * The props for the TextShadow component.
 */
export type TextShadowProps = {
  /**
   * The actual text or React node(s) to render.
   */
  children: React.ReactNode;

  /**
   * Whether to display the shadow layer.
   */
  shadow: boolean;

  /**
   * Configuration for the drop shadow (offset, blur, color, blend mode, etc.).
   */
  shadowSetup?: ShadowSetup;

  /**
   * For scaling offsets: the "canvas" height. Defaults to 1 if not provided.
   * Used in converting shadow distances from percentage-based to pixels.
   */
  cnvHeight?: number;

  /**
   * The aspect ratio of the text area or viewport, used for some animations.
   * Typically [width, height].
   */
  aspectRatio: [number, number];

  /**
   * Controls any automatic text scrolling/animation (direction, speed, etc.).
   */
  textAnimation: TextAnimation;

  /**
   * Background color for the container. This is behind both text and shadow.
   */
  background: string;

  /**
   * Positioning data used by the text animation utility.
   */
  positionControl: PositionControlDef;

  /**
   * An array of time-based controls (start, end, etc.) for scheduling animations.
   */
  timeControls: TimeControlDef[];

  /**
   * The current time (in milliseconds) for driving time-based animation.
   */
  time: number;

  /**
   * Which part of the text gets clipped for background images. Usually "text" (WebKit only).
   */
  backgroundClip: string;

  /**
   * The base font color for the top text layer (if not using a gradient).
   */
  fontColor: string;

  /**
   * Width (in px) of the stroke drawn around the text on the top layer.
   * If zero, no stroke is applied.
   */
  strokeWidth: number;

  /**
   * Color of the stroke outline for the top text layer.
   */
  strokeColor: string;
};

/**
 * Renders text (or any children) with an optional SVG-filtered shadow behind it,
 * plus optional text animation (scrolling), background clip, and stroke.
 *
 * The component creates two layers:
 * 1) A top layer with crisp text, possibly with a stroke or background clipping.
 * 2) An optional bottom layer (if `shadow` is true) which uses an SVG filter
 *    (morphology + gaussian blur + flood) to create a drop-shadow effect.
 *
 * @param {TextShadowProps} props - The props object.
 * @returns {JSX.Element} A container that isolates and positions the crisp text and optional shadow layer.
 */
export const TextShadow: React.FC<TextShadowProps> = ({
  children,
  shadow,
  shadowSetup,
  cnvHeight = 1,
  textAnimation,
  background,
  positionControl,
  timeControls,
  time,
  aspectRatio,
  backgroundClip,
  fontColor,
  strokeWidth,
  strokeColor,
}: TextShadowProps): JSX.Element => {
  // A ref to measure text width/height for animations.
  const ref = useRef<HTMLDivElement>(null);

  // Convert "distance" to actual offset using cnvHeight scaling.
  const pts = (cnvHeight ?? MAX_FULLSCREEN_HEIGHT) / 100;
  const offsetX = pts * (shadowSetup?.distanceX ?? 0);
  const offsetY = pts * (shadowSetup?.distanceY ?? 0);
  const spreadDistance = pts * (shadowSetup?.spread ?? 0);
  const blurRadius = pts * (shadowSetup?.blur ?? 0);
  const shadowOpacity = (shadowSetup?.opacity ?? 0) / 255;

  // Pre-compute the transform for the shadow layer.
  const shadowTransform = textAnimation.active
    ? translateText(
        positionControl.w,
        aspectRatio,
        time,
        ref,
        textAnimation.speed,
        timeControls[0].startMS,
        MAX_FULLSCREEN_HEIGHT,
        textAnimation.direction,
        offsetX,
        offsetY,
      )
    : `translate(${offsetX}px, ${offsetY}px)`;

  // Pre-compute the transform for the top (crisp) text layer.
  const textTransform = textAnimation.active
    ? translateText(
        positionControl.w,
        aspectRatio,
        time,
        ref,
        textAnimation.speed,
        timeControls[0].startMS,
        MAX_FULLSCREEN_HEIGHT,
        textAnimation.direction,
      )
    : 'none';

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: background,
        isolation: 'isolate', // ensures blend modes only affect the background
      }}
    >
      {/**
       * CRISP TEXT (TOP LAYER)
       */}
      <div
        ref={ref}
        style={{
          position: 'relative',
          zIndex: 2,
          whiteSpace: textAnimation.active ? 'nowrap' : 'break-spaces',
          transform: textTransform,
          backgroundImage: backgroundClip === 'text' ? gradientOpacity(fontColor) : 'transparent',
          // @ts-ignore
          WebkitBackgroundClip: backgroundClip,
          WebkitTextStroke: strokeWidth
            ? `${strokeWidth}px ${singleColorOpacity(strokeColor)}`
            : '0px',
          color: fontColor,
        }}
        // The actual color to apply to the text layer (fallback in case backgroundClip isn't "text")
      >
        {children}
      </div>
      {/**
       * SHADOW LAYER (BOTTOM) — only if `shadow` is true
       */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          pointerEvents: 'none',
          mixBlendMode:
            shadowSetup?.blendMode === BlendMode.None
              ? undefined
              : (shadowSetup?.blendMode?.replace('_', '-').toLowerCase() as
                  | Property.MixBlendMode
                  | undefined),
          opacity: shadowOpacity,
          // Provide a solid color so SourceGraphic sees an opaque shape
          color: '#000',
          WebkitTextFillColor: '#000',
          // @ts-ignore
          filter: 'url(#myTextShadowFilter)',
          transform: shadowTransform,
        }}
      >
        {children}
      </div>
      {/**
       * SVG filter definition for the shadow
       */}
      <svg width="0" height="0" style={{ position: 'absolute' }}>
        <defs>
          <filter id="myTextShadowFilter" x="-50%" y="-50%" width="200%" height="200%">
            <feMorphology
              in="SourceGraphic"
              operator="dilate"
              radius={spreadDistance}
              result="spread"
            />
            <feGaussianBlur in="spread" stdDeviation={blurRadius} result="blurred" />
            <feFlood
              floodColor={singleColorOpacity(shadowSetup?.color ?? 'transparent')}
              result="flooded"
            />
            <feComposite in="flooded" in2="blurred" operator="in" result="coloredShadow" />
            <feMerge>
              <feMergeNode in="coloredShadow" />
              {/** Omit merging SourceGraphic => we only see the blurred shadow, not the text color */}
            </feMerge>
          </filter>
        </defs>
      </svg>
    </div>
  );
};
