import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['DATA_PRODUCT_STORAGE_LOCATION'];

export const useGetStorageLocation = (id?: string) =>
  useQuery(
    [...key, id],
    async () => {
      const { data } = await axiosInstance(`data-products/${id}/storage-location`, {
        headers: {
          'X-Product-Id': id || '',
        },
      });
      return data;
    },
    {
      staleTime: Infinity,
      enabled: !!id,
    },
  );
