import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { key } from './useGetDataProducts';

export const useEditDataProduct = () => {
  return useMutation(
    async (form: any) => {
      const product = JSON.parse(form.get('dataProduct'));
      const { data } = await axiosInstance.put(`data-products/${product.id}`, form, {
        headers: {
          'X-Product-Id': product.id || '',
        },
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(key);
      },
      onError: () => {
        toast.error('There was an error while editing product. Please try again.');
      },
    },
  );
};
