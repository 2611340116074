import './style.scss';

import { ToggleSwitch } from 'flowbite-react';
import Slider from 'rc-slider';
import React, { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { IoCameraOutline } from 'react-icons/io5';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import noThumbnail from '../../../assets/images/thumbnail.jpg';
import Button from '../../../atoms/button/Button';
import { setMultimediaThumbnail } from '../../../core/api/MultimediaAPI';
import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { ScreenshotData } from '../../../helpers/screenshotElement';
import { usePropertyGridActive } from '../../../hooks/usePropertyGridActive';
import { ImagePanelDef } from '../../../model/definitions/ImagePanelDef';
import { ActiveDef, setPropertyGridActiveHash } from '../../../store/slices/active-slice';
import { deleteImageLayer, updateImageLayer } from '../../../store/slices/project-slice';
import { selectActiveImageLayer } from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import { Panel } from './components/Panel';
import { PropertySection } from './components/PropertySection';
import ImageAudioThumbnailModal from './ImageAudioThumbnail';
import { PositionControls } from './panels/PositionControls';
import { TimeControlsPanel } from './panels/TimeControlsPanel';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

const ImageProperties: React.FC = () => {
  const [thumbnail, setThumbnail] = useState(false);
  const { activeScene, activeElement, activePoster } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  const { isOpened, lastFocused } = usePropertyGridActive([
    'lockAspectRatio',
    'name',
    'description',
  ]);
  const imageLayer = useSelector<RootState, ImagePanelDef | null | undefined>((state) =>
    selectActiveImageLayer(state),
  );
  const thumbnailMut = useMutation(setMultimediaThumbnail, {
    onSuccess: () => {
      setThumbnail(false);
    },
  });

  const dispatch = useDispatch();

  function onFocus(path: Leaves<ImagePanelDef>) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }

  function onImageLayerChange(propertyPath: Leaves<ImagePanelDef>, e: string | boolean | number) {
    onFocus(propertyPath);
    dispatch(
      updateImageLayer({
        newValue: e,
        activeScene: activeScene,
        elementId: activeElement,
        propertyPath: propertyPath,
        parentId: activePoster,
      }),
    );
  }

  const handleRatioLockChange = (e: boolean) => {
    dispatch(
      updateImageLayer({
        newValue: e,
        activeScene: activeScene,
        elementId: activeElement,
        propertyPath: 'lockAspectRatio',
        parentId: activePoster,
      }),
    );
  };

  function onThumbnail() {
    setThumbnail(true);
  }
  function onConfirm(data: ScreenshotData) {
    thumbnailMut.mutate({
      multimediaType: 'IMAGE',
      versionId: imageLayer!.imagePanelDefTemplate.versionId,
      thumbnail: data,
    });
  }

  const deleteElement = () => {
    dispatch(
      deleteImageLayer({
        activeScene,
        elementId: activeElement,
        parentId: activePoster,
      }),
    );
  };
  return (
    <Panel
      actions={[
        <Button
          key={`${imageLayer?.id}_set_thumb`}
          buttonType="border"
          label="Set thumbnail"
          icon={<IoCameraOutline />}
          onClick={onThumbnail}
          className="property-grey-buttons"
        />,
      ]}
    >
      <>
        {!activePoster && (
          <>
            <PropertySection isOpened={isOpened} label={'Image properties'}>
              <div className="prop-wrapper">
                <GridWrapper>
                  <GridItem
                    noBorderBg
                    label={'Lock aspect ratio:'}
                    item={
                      <ToggleSwitch
                        label={''}
                        checked={imageLayer?.lockAspectRatio ?? true}
                        onChange={(e) => onImageLayerChange('lockAspectRatio', e)}
                      />
                    }
                  />
                  <GridItem
                    noBorderBg
                    label={'Action'}
                    item={
                      <Button
                        label={'Delete'}
                        buttonType={'danger'}
                        onClick={() => deleteElement()}
                      />
                    }
                  />
                  <GridItem
                    label={'Name'}
                    item={
                      <DebounceInput
                        debounceTimeout={400}
                        onFocus={() => onFocus('name')}
                        autoFocus={lastFocused === 'name'}
                        value={imageLayer?.name}
                        onChange={(e) => onImageLayerChange('name', e.target.value)}
                      />
                    }
                  />
                  <GridItem
                    label={'Description'}
                    item={
                      <DebounceInput
                        debounceTimeout={400}
                        value={imageLayer?.description}
                        onFocus={() => onFocus('description')}
                        autoFocus={lastFocused === 'description'}
                        onChange={(e) => onImageLayerChange('description', e.target.value)}
                      />
                    }
                  />
                  <GridItem
                    noBorderBg
                    label={`Opacty`}
                    item={
                      <>
                        <Slider
                          style={{ width: '100%' }}
                          min={0}
                          max={1}
                          step={0.01}
                          value={imageLayer?.opacity}
                          onChange={(e) => {
                            e >= 0 && e <= 1 && onImageLayerChange(`opacity`, Number(e));
                          }}
                        />
                        <div style={{ marginLeft: '0.5rem', fontVariantNumeric: 'tabular-nums' }}>
                          {imageLayer?.opacity.toFixed(2)}
                        </div>
                      </>
                    }
                  />
                  <GridItem
                    noBorderBg
                    label={`Lock on canvas`}
                    item={
                      <>
                        <ToggleSwitch
                          checked={imageLayer?.canvasLock ?? false}
                          label={''}
                          onChange={(e) => {
                            onImageLayerChange('canvasLock', e);
                          }}
                        />
                      </>
                    }
                  />
                </GridWrapper>
              </div>
            </PropertySection>
            {imageLayer?.positionControl && (
              <PositionControls
                position={imageLayer?.positionControl}
                layer={'imagePanels'}
                lockedRatio={imageLayer?.lockAspectRatio ?? true}
                lockRatio={handleRatioLockChange}
              />
            )}
            {imageLayer?.timeControls?.length ? (
              <TimeControlsPanel
                timeControls={imageLayer?.timeControls}
                layer={ElementsEnum.IMAGE}
              />
            ) : null}
            {imageLayer && (
              <ImageAudioThumbnailModal
                opened={thumbnail}
                onClose={() => setThumbnail(false)}
                currentThumbnail={imageLayer!.imagePanelDefTemplate.thumbnailUrls[0] || noThumbnail}
                loading={thumbnailMut.isLoading}
                onConfirm={onConfirm}
              />
            )}
          </>
        )}
      </>
    </Panel>
  );
};

export default ImageProperties;
