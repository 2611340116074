import { useQuery } from 'react-query';

import { axiosInstance } from '../axiosInstance';

const key = ['GRIB_PARAMETER_MAPPINGS'];

export const useGetParameterMappings = ({
  dataProductId,
  parameter,
}: {
  dataProductId: string | null;
  parameter?: string;
}) => {
  return useQuery(
    [...key, dataProductId, parameter],
    async () => {
      const { data } = await axiosInstance.get(
        `data-products/${dataProductId}/parameter-mappings`,
        {
          params: {
            parameter,
          },
          headers: {
            'X-Product-Id': dataProductId || '',
          },
        },
      );
      return data;
    },
    {
      enabled: !!parameter && !!dataProductId,
      retry: false,
    },
  );
};
