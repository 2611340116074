import { ToggleSwitch } from 'flowbite-react';
import Slider from 'rc-slider';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../atoms/button/Button';
import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { usePropertyGridActive } from '../../../hooks/usePropertyGridActive';
import { AnimationPanelDef } from '../../../model/definitions/AnimationPanelDef';
import { ActiveDef, setPropertyGridActiveHash } from '../../../store/slices/active-slice';
import { deleteAnimationLayer, updateAnimationLayer } from '../../../store/slices/project-slice';
import { selectActiveAnimationLayer } from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import { Panel } from './components/Panel';
import { PropertySection } from './components/PropertySection';
import { BackgroundProperties } from './panels/panelElements/BackgroundProperties';
import { PositionControls } from './panels/PositionControls';
import { TimeControlsPanel } from './panels/TimeControlsPanel';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

export const AnimationProperties = () => {
  const dispatch = useDispatch();
  const animation = useSelector<RootState, AnimationPanelDef | null | undefined>((state) =>
    selectActiveAnimationLayer(state),
  );
  const { activeScene, activeElement, activePoster } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  function onFocus(path: Leaves<AnimationPanelDef>) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }
  const { isOpened, lastFocused } = usePropertyGridActive([
    'lockAspectRatio',
    'name',
    'description',
  ]);
  const deleteElement = () => {
    dispatch(
      deleteAnimationLayer({
        activeScene,
        elementId: activeElement,
        parentId: activePoster,
      }),
    );
  };
  function onAnimationLayerChange(
    propertyPath: Leaves<AnimationPanelDef>,
    e: string | boolean | number,
  ) {
    onFocus(propertyPath);
    dispatch(
      updateAnimationLayer({
        newValue: e,
        activeScene: activeScene,
        elementId: activeElement,
        propertyPath: propertyPath,
        parentId: activePoster,
      }),
    );
  }
  return (
    <Panel>
      <>
        <PropertySection isOpened={isOpened} label={'animation properties'}>
          <div className="prop-wrapper">
            <GridWrapper>
              <GridItem
                noBorderBg
                label={'Lock aspect ratio:'}
                item={
                  <ToggleSwitch
                    label={''}
                    checked={animation?.lockAspectRatio ?? true}
                    onChange={(e) => onAnimationLayerChange('lockAspectRatio', e)}
                  />
                }
              />
              <GridItem
                noBorderBg
                label={'Action'}
                item={
                  <Button label={'Delete'} buttonType={'danger'} onClick={() => deleteElement()} />
                }
              />
              <GridItem
                label={'Name'}
                item={
                  <DebounceInput
                    debounceTimeout={400}
                    onFocus={() => onFocus('name')}
                    autoFocus={lastFocused === 'name'}
                    value={animation?.name}
                    onChange={(e) => onAnimationLayerChange('name', e.target.value)}
                  />
                }
              />
              <GridItem
                label={'Description'}
                item={
                  <DebounceInput
                    debounceTimeout={400}
                    value={animation?.description}
                    onFocus={() => onFocus('description')}
                    autoFocus={lastFocused === 'description'}
                    onChange={(e) => onAnimationLayerChange('description', e.target.value)}
                  />
                }
              />
              <GridItem
                noBorderBg
                label={`Opacty`}
                item={
                  <>
                    <Slider
                      style={{ width: '100%' }}
                      min={0}
                      max={1}
                      step={0.01}
                      value={animation?.opacity}
                      onChange={(e) => {
                        e >= 0 && e <= 1 && onAnimationLayerChange(`opacity`, Number(e));
                      }}
                    />
                    <div style={{ marginLeft: '0.5rem', fontVariantNumeric: 'tabular-nums' }}>
                      {animation?.opacity.toFixed(2)}
                    </div>
                  </>
                }
              />
              <GridItem
                noBorderBg
                label={'Loop animation'}
                item={
                  <ToggleSwitch
                    label={''}
                    checked={animation?.animationPanelDefTemplate.repeat ?? false}
                    onChange={(e) => onAnimationLayerChange('animationPanelDefTemplate.repeat', e)}
                  />
                }
              />
              <GridItem
                noBorderBg
                label={`Lock on canvas`}
                item={
                  <ToggleSwitch
                    checked={animation?.canvasLock ?? false}
                    label={''}
                    onChange={(e) => {
                      onAnimationLayerChange('canvasLock', e);
                    }}
                  />
                }
              />
            </GridWrapper>
          </div>
        </PropertySection>
        {animation?.positionControl && (
          <PositionControls
            position={animation?.positionControl}
            layer={'animationPanels'}
            lockedRatio={animation?.lockAspectRatio ?? true}
          />
        )}
        {animation?.timeControls?.length ? (
          <TimeControlsPanel
            timeControls={animation?.timeControls}
            layer={ElementsEnum.ANIMATION}
          />
        ) : null}
        {animation && (
          <PropertySection label={'Background'} isOpened={isOpened}>
            <BackgroundProperties
              background={animation.background}
              elementType={ElementsEnum.ANIMATION}
              isIndependent
            />
          </PropertySection>
        )}
      </>
    </Panel>
  );
};
