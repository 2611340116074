import { ShadowSetup } from '../types/textShadow';
import { BoxDef } from './BoxDef';
import { C9Object } from './C9Object';
import { LogicalGroupParent } from './LogicalGroupParent';
import { PositionControlDef } from './PositionControlDef';
import { RichTextDef } from './RichTextDef';
import { TextAnimation } from './TextAnimation';
import { TimeControlDef } from './TimeControlDef';

export class TextPanelDef extends C9Object {
  timeControls: TimeControlDef[] = [new TimeControlDef(0, 10000)];
  positionControl = new PositionControlDef(30, 5, 5, 5);
  boxDef = new BoxDef();
  fontColor = 'rgba(255, 255, 255, 255)';
  fontSize = 4;
  fontFamily: string;
  fontType?: string;
  fontVariantId?: string;
  textTransform:
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'full-width'
    | 'full-size-kana';
  fontAlignment: string;
  strokeWidth = 0;
  strokeColor = 'rgba(255, 255, 255, 255)';
  richText: RichTextDef;
  textAnimation = new TextAnimation();
  parentGroups = [new LogicalGroupParent()];
  value: string;
  displayText: boolean;
  enabled: boolean;
  /**** to add to domain model ***/
  textShadow: boolean;
  shadowSetup: ShadowSetup;
}
