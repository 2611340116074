import './style.scss';

import { Select } from 'flowbite-react';
import { cloneDeep, isEqual } from 'lodash';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

// import { toast } from 'react-toastify';
import Button from '../../../atoms/button/Button';
// import { postGribLayer } from '../../../core/api/mapLayers/LayersAPI';
import { shouldHaveInterpolateOption } from '../../../core/weather-data/helpers/interpolationPropertyHelper';
import { fromGeoBoxToBoundsWDLayers } from '../../../helpers/boundsManage';
import { DataFrameDef } from '../../../model/definitions/DataFrameDef';
import { GribSourceDef } from '../../../model/definitions/GribSourceDef';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { PickerDef } from '../../../model/definitions/PickerDef';
import {
  PointSourceDef,
  SymbolLayerDef,
  SymbolPointType,
  SymbolSourceType,
  SymbolStyleDef,
} from '../../../model/definitions/SymbolLayerDef';
import { ParameterUnitDTO } from '../../../model/DTO/ParameterUnitDTO';
import { getZindexOfMapLayer } from '../../../molecules/mapElement/helpers';
import { isWindLayer } from '../../../molecules/mapElement/useSymbolLayers';
import Modal from '../../../molecules/modal/Modal';
import {
  ActiveDef,
  setDisplayedFlyOvers,
  setLayerType,
  setSymbolEditingLayerId,
} from '../../../store/slices/active-slice';
import { updateMapLayer } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import MapSymbolModelLayers from '../properties/mapLayersProperties/MapSymbolModelLayers';
import MapSymbolPointLayers from '../properties/mapLayersProperties/MapSymbolPointLayers';
import { getFirstLayerRange } from './frameRangeHelpers';

interface Props {
  opened: boolean;
  onClose: () => void;
  mapLayer: MapPanelDef;
  gribLayerEdit?: SymbolLayerDef;
  applyLayer?: boolean;
}

const MapSymbolLayersModal: FC<Props> = ({
  mapLayer,
  opened,
  onClose,
  gribLayerEdit,
  applyLayer,
}) => {
  const { activeScene, activeElement } = useSelector<RootState, ActiveDef>((state) => state.active);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [sourceType, setSourceType] = useState<SymbolSourceType>(
    gribLayerEdit ? gribLayerEdit.symbolSource.sourceType : SymbolSourceType.PointData,
  );

  const [pointType, setPointType] = useState<SymbolPointType | undefined>(
    gribLayerEdit
      ? gribLayerEdit.symbolSource.pointType
        ? gribLayerEdit.symbolSource.pointType
        : SymbolPointType.Forecast
      : SymbolPointType.Forecast,
  );
  const [param, setParam] = useState<PickerDef | undefined>(
    gribLayerEdit ? gribLayerEdit.symbolSource.gribSource?.parameterType : undefined,
  );
  const [gribSource, setGribSource] = useState<PickerDef | undefined>(
    gribLayerEdit ? gribLayerEdit.symbolSource.gribSource?.gribSource : undefined,
  );
  const [dataProductId, setDataProductId] = useState<string | null>(
    gribLayerEdit && gribLayerEdit.symbolSource.gribSource
      ? gribLayerEdit.symbolSource.gribSource?.dataProductId
      : null,
  );
  const [gribModel, setGribModel] = useState<PickerDef | undefined>(
    gribLayerEdit ? gribLayerEdit.symbolSource.gribSource?.gribModel : undefined,
  );
  const [enableInterpolation, setEnableInterpolation] = useState(
    gribLayerEdit ? gribLayerEdit.enableInterpolation : true,
  );
  const [frameLoading, setFrameLoading] = useState(false);
  const [name, setName] = useState<string | undefined>(
    gribLayerEdit ? gribLayerEdit.name : undefined,
  );
  const [z, setZ] = useState(gribLayerEdit ? gribLayerEdit.zindex : getZindexOfMapLayer(mapLayer));

  const [unit, setUnit] = useState(gribLayerEdit ? gribLayerEdit.symbolSource.unit : undefined);

  const [iconPackId, setIconPackId] = useState(
    gribLayerEdit ? gribLayerEdit.symbolSource.iconPackId : undefined,
  );
  const [pointSource, setPointSource] = useState<PointSourceDef | undefined>(
    gribLayerEdit ? gribLayerEdit.symbolSource.pointSource : undefined,
  );
  const [pointParameter, setPointParameter] = useState<string | undefined>(
    gribLayerEdit ? gribLayerEdit.symbolSource.pointParameter : undefined,
  );
  const [pointDataFrames, setPointDataFrames] = useState(
    gribLayerEdit ? gribLayerEdit.symbolSource.pointDataFrames : [],
  );
  const [parameterType, setParameterType] = useState(
    gribLayerEdit ? gribLayerEdit.symbolSource.parameterType : undefined,
  );

  const firstLayerRange = getFirstLayerRange(mapLayer);

  const unitsData = queryClient.getQueryData([
    'useGetPointUnits',
    pointParameter,
  ]) as ParameterUnitDTO[];

  const selectedFrames: DataFrameDef[] = [];
  if (gribLayerEdit && gribLayerEdit.dataFrames) {
    selectedFrames.push(...gribLayerEdit.dataFrames);
  }
  const [selectedDataFrames, setSelectedDataFrames] = useState<Array<DataFrameDef>>(selectedFrames);
  useEffect(() => {
    if (selectedDataFrames.length === 1) {
      setSelectedDataFrames([...selectedFrames]);
    }
  }, [param, gribSource, gribModel]);
  const transformedBounds = fromGeoBoxToBoundsWDLayers(mapLayer.baseMapSetup.boundingBox);
  const [framesDensity, setFramesDensity] = useState<number>(
    gribLayerEdit ? gribLayerEdit.dataFramesDensity : 1,
  );
  function onMapLayerChange(propertyPath: Paths<MapPanelDef>, newValue: SymbolLayerDef[]) {
    dispatch(
      updateMapLayer({
        activeScene,
        newValue,
        elementId: activeElement,
        propertyPath,
      }),
    );
  }

  function isRelevantGribDataChanged() {
    if (!gribLayerEdit || applyLayer) return true;
    if (gribLayerEdit.symbolSource.gribSource) {
      return (
        !isEqual(gribLayerEdit.dataFrames, selectedDataFrames) ||
        !isEqual(gribLayerEdit.symbolSource.gribSource.parameterType, param) ||
        !isEqual(gribLayerEdit.symbolSource.gribSource.gribSource, gribSource) ||
        !isEqual(gribLayerEdit.symbolSource.gribSource.gribModel, gribModel) ||
        !isEqual(gribLayerEdit.symbolSource.unit, unit)
      );
    }
  }

  function isIrelevantDataChanged() {
    if (!gribLayerEdit) return true;
    return (
      !isEqual(gribLayerEdit.name, name) ||
      !isEqual(gribLayerEdit.zindex, z) ||
      !isEqual(gribLayerEdit.enableInterpolation, enableInterpolation)
    );
  }

  function isRelevantPointDataChanged() {
    if (!gribLayerEdit || applyLayer) return true;
    return (
      !isEqual(gribLayerEdit.symbolSource.pointDataFrames, pointDataFrames) ||
      !isEqual(gribLayerEdit.symbolSource.pointParameter, pointParameter) ||
      !isEqual(gribLayerEdit.symbolSource.pointSource, pointSource) ||
      !isEqual(gribLayerEdit.symbolSource.unit, unit) ||
      !isEqual(gribLayerEdit.symbolSource.iconPackId, iconPackId)
    );
  }

  function onIrelevantDataChanged() {
    const cloned = cloneDeep(mapLayer.wdSpace[0].symbolLayers);
    const found = cloned.find((l) => l.id === gribLayerEdit?.id);
    const parameter = sourceType === SymbolSourceType.ModelData ? param?.name : pointParameter;
    if (found && parameter) {
      found.name = name!;
      found.zindex = Number(z);
      found.enableInterpolation = shouldHaveInterpolateOption(parameter)
        ? enableInterpolation
        : false;
      // @ts-ignore
      onMapLayerChange('wdSpace[0].symbolLayers', cloned);
    }
  }

  const addLayer = async () => {
    if (sourceType === SymbolSourceType.ModelData && !(gribSource && param && name && gribModel)) {
      return;
    }
    if (sourceType === SymbolSourceType.PointData && !(pointParameter && pointDataFrames)) {
      return;
    }
    if (
      (sourceType === SymbolSourceType.ModelData && !isRelevantGribDataChanged()) ||
      (sourceType !== SymbolSourceType.ModelData && !isRelevantPointDataChanged())
    ) {
      if (isIrelevantDataChanged()) {
        onIrelevantDataChanged();
      }
      return;
    }
    const layerToAdd = new SymbolLayerDef();
    if (name) {
      layerToAdd.name = name;
    } else if (pointParameter) {
      layerToAdd.name = pointParameter;
    }
    layerToAdd.enabled = true;
    layerToAdd.symbolSource.sourceType = sourceType;
    layerToAdd.symbolSource.gribSource = new GribSourceDef();
    layerToAdd.symbolSource.gribSource.dataProductId = dataProductId;
    if (name && gribSource && param && gribModel) {
      layerToAdd.symbolSource.gribSource.name = name;
      layerToAdd.symbolSource.gribSource.gribSource = gribSource;
      layerToAdd.symbolSource.gribSource.parameterType = param;
      layerToAdd.symbolSource.gribSource.gribModel = gribModel;
    }
    layerToAdd.symbolSource.gribSource.location = {
      leftLongitude: transformedBounds[0],
      rightLongitude: transformedBounds[1],
      upperLatitude: transformedBounds[2],
      lowerLatitude: transformedBounds[3],
    };
    // TimeControlDef[] or TimeControlDef ?
    layerToAdd.timeControls = gribLayerEdit
      ? gribLayerEdit.timeControls
      : [mapLayer.timeControls[0]];

    layerToAdd.layerType = gribLayerEdit ? gribLayerEdit.layerType : 'SYMBOL';

    layerToAdd.dataFrames = selectedDataFrames;
    layerToAdd.zindex = z;
    if (param) {
      layerToAdd.enableInterpolation = shouldHaveInterpolateOption(param.name)
        ? enableInterpolation
        : false;
    }
    if (sourceType === SymbolSourceType.PointData && pointType) {
      layerToAdd.symbolSource.pointType = pointType;
    }
    layerToAdd.dataFramesDensity = framesDensity;
    layerToAdd.symbolSource.unit = unit ?? '';
    layerToAdd.symbolSource.iconPackId = iconPackId ?? '';
    layerToAdd.symbolSource.pointParameter = pointParameter;
    layerToAdd.symbolSource.pointSource = pointSource;
    layerToAdd.symbolSource.pointDataFrames = pointDataFrames;
    layerToAdd.symbolSource.parameterType = parameterType;
    layerToAdd.symbolSource.points = gribLayerEdit ? gribLayerEdit.symbolSource.points : [];
    layerToAdd.symbolSource.defaultStyle = gribLayerEdit
      ? gribLayerEdit.symbolSource.defaultStyle
      : new SymbolStyleDef();

    if (!gribLayerEdit && isWindLayer(layerToAdd)) {
      layerToAdd.symbolSource.defaultStyle.fillColor = 'rgba(255, 0, 0, 0)';
    }

    dispatch(setDisplayedFlyOvers({ displayedFlyOver: activeElement! }));
    setFrameLoading(true);

    let existingLayers = gribLayerEdit
      ? mapLayer.wdSpace[0].symbolLayers.filter((l) => l.id !== gribLayerEdit.id)
      : mapLayer.wdSpace[0].symbolLayers;
    if (!existingLayers) existingLayers = [];
    // @ts-ignore
    onMapLayerChange('wdSpace[0].symbolLayers', [...existingLayers, { ...layerToAdd }]);
    dispatch(setSymbolEditingLayerId(layerToAdd.id));
    dispatch(
      setLayerType({
        mapId: mapLayer.id,
        layerType: 'symbol',
        layerId: layerToAdd.id,
      }),
    );
  };

  async function handleAddLayer() {
    await addLayer();
    onClose();
  }

  const onSourceTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as SymbolSourceType;
    setSourceType(value);
  };

  const onPointTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as SymbolPointType;
    setPointType(value);
    setPointParameter('');
    setUnit(undefined);
    setIconPackId(undefined);
    setPointDataFrames(undefined);
  };

  const isButtonDisabled =
    sourceType === SymbolSourceType.ModelData
      ? !(name &&
        gribSource &&
        param &&
        selectedDataFrames.length &&
        gribModel &&
        param.name === 'WeatherType'
          ? iconPackId
          : true)
      : !(name &&
        pointSource &&
        pointParameter &&
        pointDataFrames &&
        Number(pointDataFrames?.length) >= 0 &&
        pointParameter !== 'WeatherType'
          ? /**some parameters do not have units like MaxUV */
            unit || unitsData?.length === 0
          : iconPackId);

  return (
    <Modal
      isOpen={opened}
      onClose={onClose}
      className="add-layers-wrapper"
      header={'Set layers'}
      footer={
        <div className={'modal-footer'}>
          <Button
            disabled={isButtonDisabled}
            label={gribLayerEdit && !applyLayer ? 'Apply changes' : 'Add layer'}
            buttonType="primary"
            onClick={handleAddLayer}
            loading={frameLoading}
          />
        </div>
      }
    >
      <div className="add-layers-body">
        <label>
          <span className="text-[#fa5656]">*</span> Symbol type
        </label>
        <Select
          className={'w-full'}
          onChange={onSourceTypeChange}
          value={sourceType}
          required
          disabled={!!gribLayerEdit}
        >
          <option value={SymbolSourceType.PointData}>Point</option>
          <option value={SymbolSourceType.ModelData}>Model</option>
        </Select>
        {sourceType === SymbolSourceType.PointData && (
          <div className="mt-4">
            <label>
              <span className="text-[#fa5656]">*</span> Point type
            </label>
            <Select className={'w-full'} onChange={onPointTypeChange} value={pointType} required>
              <option value={SymbolPointType.Forecast}>Forecast</option>
              <option value={SymbolPointType.Observed}>Observed</option>
            </Select>
          </div>
        )}
        {sourceType === SymbolSourceType.ModelData ? (
          <MapSymbolModelLayers
            oldLayer={gribLayerEdit && !dataProductId ? true : false}
            dataProductId={dataProductId}
            setDataProductId={setDataProductId}
            framesDensity={framesDensity}
            setFramesDensity={(e) => {
              setFramesDensity(e);
            }}
            param={param}
            source={gribSource}
            name={name}
            setName={setName}
            setParam={setParam}
            z={z}
            setZ={setZ}
            unit={unit}
            setUnit={setUnit}
            setSource={setGribSource}
            selectedDataFrames={selectedDataFrames}
            setSelectedDataFrames={setSelectedDataFrames}
            bounds={transformedBounds}
            mapLayer={mapLayer}
            onMapLayerChange={onMapLayerChange}
            enableInterpolation={enableInterpolation}
            setEnableInterpolation={setEnableInterpolation}
            gribLayerEdit={gribLayerEdit}
            rangeFromFirstLayer={firstLayerRange}
            gribModel={gribModel}
            setGribModel={setGribModel}
            iconPackId={iconPackId}
            setIconPackId={setIconPackId}
            setParameterType={setParameterType}
          />
        ) : (
          <MapSymbolPointLayers
            name={name}
            setName={setName}
            z={z}
            setZ={setZ}
            unit={unit}
            setUnit={setUnit}
            source={pointSource}
            setSource={setPointSource}
            dataProductId={dataProductId}
            setDataProductId={setDataProductId}
            bounds={transformedBounds}
            mapLayer={mapLayer}
            onMapLayerChange={onMapLayerChange}
            gribLayerEdit={gribLayerEdit}
            rangeFromFirstLayer={firstLayerRange}
            pointDataFrames={pointDataFrames}
            setPointDataFrames={setPointDataFrames}
            pointParameter={pointParameter}
            setPointParameter={setPointParameter}
            pointType={pointType}
            iconPackId={iconPackId}
            setIconPackId={setIconPackId}
          />
        )}
      </div>
    </Modal>
  );
};

export default MapSymbolLayersModal;
