import axios from 'axios';

import { _kc } from '../auth/KeycloakService';
import { appendCustomHeaders } from './axiosInstance';

const BASE_URL = `${process.env.REACT_APP_POINT_API_BASE_URL}/api/`;

export const axiosInstancePoint = axios.create({ baseURL: BASE_URL });

axiosInstancePoint.interceptors.request.use(async (config) => {
  if (_kc.authenticated && config?.headers) {
    config.headers['Authorization'] = `Bearer ${_kc.token}`;
    appendCustomHeaders(config);
    return config;
  }
  await _kc.updateToken(5);
  if (!!_kc.token && config?.headers) {
    config.headers['Authorization'] = `Bearer ${_kc.token}`;
    appendCustomHeaders(config);
  }
  return config;
});
axiosInstancePoint.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);
