import './schedule-list.scss';

import {
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleFilled,
  MessageOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Modal,
  Popover,
  Progress,
  Space,
  Table,
  Tabs,
  TabsProps,
  Tag,
  Tooltip,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AiOutlineFieldTime } from 'react-icons/ai';
import { MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import formatFileSize from '../../../helpers/formatFileSize';
import { SchedulerTaskResponseDTO } from '../../../model/other/SchedulerTaskResponseDTO';
import { useGetSchedulerDefinitions } from '../queries-NEW/getSchedulerDefinitions';
import { useDeleteVideoAssemblyTask } from '../queries-NEW/useDeleteVideoAssembyTask';
import { useGetSchedulers } from '../queries-NEW/useGetSchedulers';

const { confirm } = Modal;

function SchedulersTable({ projectId }: { projectId?: string }) {
  const [paginationSchedulers, setPaginationSchedulers] = useState<{ page: number; size: number }>({
    page: 1,
    size: 20,
  });
  const [paginationSchedulerDefs, setPaginationSchedulerDefs] = useState<{
    page: number;
    size: number;
  }>({
    page: 1,
    size: 20,
  });
  const {
    data: schedulers,
    isLoading: isLoadingSchedulers,
    refetch: refetchSchedulers,
  } = useGetSchedulers(paginationSchedulers, projectId);
  const {
    data: schedulerDefs,
    isLoading: isLoadingSchedulerDefs,
    refetch: refetchSchedulerDefs,
  } = useGetSchedulerDefinitions(paginationSchedulerDefs);
  const { mutate: deleteVideoAssemblyTask } = useDeleteVideoAssemblyTask();

  useEffect(() => {
    refetchSchedulers();
  }, [paginationSchedulers]);
  useEffect(() => {
    refetchSchedulerDefs();
  }, [paginationSchedulerDefs]);

  const handleDelete = (job: SchedulerTaskResponseDTO) => {
    confirm({
      title: 'Delete scheduler: "' + job.schedulerName + '"',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to delete this item?',
      centered: true,
      okText: 'Delete',
      okType: 'danger',
      onOk() {
        deleteVideoAssemblyTask(job.id + '');
      },
      onCancel() {},
    });
  };

  // const rowSelection = {
  //   onChange: (selectedRowKeys: React.Key[], selectedRows: Scheduler[]) => {
  //     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  //   },
  //   getCheckboxProps: (record: Scheduler) => ({
  //     disabled: record.name === 'Disabled User', // Column configuration not to be checked
  //     name: record.name,
  //   }),
  // };

  // const popup = (msg: string, status: string) => {
  //   if (status === 'WARNING') {
  //     Modal.warning({
  //       title: 'Preparation warning',
  //       content: msg,
  //     });
  //   } else {
  //     Modal.error({
  //       title: 'Preparation error',
  //       content: msg,
  //     });
  //   }
  // };
  const columnsSchedulerDefs = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any) => (
        <a
          style={{ textTransform: 'uppercase', textDecoration: 'underline', color: '#455060' }}
          href={`/workspace/projects/${record.projectId}`}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Last time of execution',
      dataIndex: 'lastFireTime',
      key: 'lastFireTime',
      render: (text: any, record: any) => (
        <span>
          {record.lastFireTime ? moment(record.lastFireTime).format('DD-MM-YYYY HH:mm:ss') : ''}
        </span>
      ),
    },
    {
      title: 'Next time of execution',
      dataIndex: 'nextFireTime',
      key: 'nextFireTime',
      render: (text: any, record: any) => (
        <span>
          {record.nextFireTime ? moment(record.nextFireTime).format('DD-MM-YYYY HH:mm:ss') : ''}
        </span>
      ),
    },
  ];

  const handleWarningWindow = (message: any, status: any) => {
    const newWindow = window.open('', '_blank', 'location=no,toolbar=no,width=600,height=400');

    // Set a custom favicon
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.type = 'image/png';
    favicon.href = '/favicon.png';
    newWindow?.document.head.appendChild(favicon);

    // Write some HTML content to the new window
    newWindow?.document.write(`
      <html>
        <head>
          <title>${status && status} logs</title>
          <link rel="icon" type="image/png" sizes="16x16" href="https://cdn-icons-png.flaticon.com/512/159/159469.png">
          <style>
            body {
              font-family: monospace;
              font-size: 14px;
              color: #333;
              padding-top: 20px;
            }
          </style>
        </head>
        <body>
        <blockquote>
        <h3>${status}</h3>
        <p>${message}</p>
        </blockquote>
         ${
           status === 'WARNING'
             ? `
             <blockquote>
               <h4>Warnings will occur for layers when:</h3>
               <ul>
                 <li>
                   coverage not exact (not exact match of expected time coverage, but system was
                   able to approximate within the allowed offset)
                 </li>
                 <li>number of frames not the same as in original project</li>
               </ul>
             </blockquote>
           `
             : `
             <blockquote>
               <h4>Errors will occur when:</h3>
               <ul>
                 <li>there is no exact match for time for point data</li>
                 <li>coverage not exact and not within allowed offset for layers</li>
               </ul>
             </blockquote>
           `
         }
        </body>
      </html>
    `);
  };

  const columnsSchedulers: any = [
    {
      title: 'STATUS',
      dataIndex: 'scheduleStatus',
      render: (text: any, record: SchedulerTaskResponseDTO) => (
        <Tooltip title={record.vaTaskStatus}>
          <span className={`schedule-status ${record.vaTaskStatus}`}>
            <AiOutlineFieldTime />
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'INFO',
      dataIndex: ['jobId', 'name', 'description'],
      render: (text: any, record: SchedulerTaskResponseDTO) => (
        <div className="info-wrapper">
          <span>
            <b>JOB ID:</b> {record.schedulerId}
          </span>{' '}
          <h3 className="px-0">
            <Link to={`/workspace/projects/${record.projectId}`}>{record.schedulerName}</Link>
          </h3>{' '}
          <p>{record.schedulerDescription}</p>
        </div>
      ),
    },
    {
      title: 'PREPARATION STATUS',
      dataIndex: 'preparationStatus',
      render: (text: any, record: SchedulerTaskResponseDTO) => (
        <Tag
          color={
            record.preparationStatus && record.preparationStatus === 'WARNING'
              ? 'warning'
              : record.preparationStatus === 'ERROR' || record.preparationStatus === 'APP_ERROR'
              ? 'error'
              : 'success'
          }
        >
          <span
            className={`preparation-status preparation-status-${
              record.preparationStatus !== 'SUCCESS' ? record.preparationStatus : ''
            }`}
          >
            {record.preparationStatus ? record.preparationStatus : '-'}{' '}
            {record.preparationStatus && record.preparationStatus !== 'SUCCESS' && (
              <Button
                size="large"
                className={record.preparationStatus}
                // onClick={() => popup(record.preparationMessage, record.preparationStatus)}
                onClick={() =>
                  handleWarningWindow(record.preparationMessage, record.preparationStatus)
                }
                type="link"
                icon={<MdOpenInNew />}
              />
            )}
          </span>
        </Tag>
      ),
    },
    {
      title: 'EXECUTION TIME',
      dataIndex: 'executionTime',
      render: (text: any, record: SchedulerTaskResponseDTO) => (
        <span>{moment(text).format('DD.MM.yyyy HH:mm:ss')}</span>
      ),
    },
    {
      title: 'RENDERING',
      dataIndex: ['rendering', 'renderStatus'],
      width: 250,
      render: (text: any, record: SchedulerTaskResponseDTO) => (
        <Tooltip title={record.vaTaskErrorMessage}>
          <div className="rendering-status">
            <small>
              {record.vaTaskStatus}
              {record.vaTaskStatus === 'error' && (
                <Popover content={'Error message description'} title="Error message">
                  <MessageOutlined style={{ marginLeft: '6px' }} />
                </Popover>
              )}
            </small>
            {Math.round(record.vaTaskPercentageFinished) === 100 ? (
              <Progress
                type="circle"
                size={50}
                percent={Math.round(record.vaTaskPercentageFinished)}
              />
            ) : (
              <Progress
                type="circle"
                size={50}
                percent={Math.round(record.vaTaskPercentageFinished)}
                status={record.vaTaskStatus === 'error' ? 'exception' : 'active'}
              />
            )}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'SIZE',
      dataIndex: 'sizeInMB',
      render: (text: any, record: SchedulerTaskResponseDTO) => (
        <span>{record.sizeInMB ? formatFileSize(record.sizeInMB * 1000 * 1000) : ''}</span>
      ),
    },
    {
      title: '',
      key: 'actions',
      align: 'center',
      render: (_: any, record: SchedulerTaskResponseDTO) => (
        <Space size="small">
          <Tooltip title="Download">
            <a
              href={
                process.env.REACT_APP_API_BASE_URL +
                '/api/public/store/multimedia/download/video/' +
                record.vaTaskId +
                '?fileName=' +
                encodeURIComponent(record.schedulerName) +
                '.mp4'
              }
              target="_blank"
              download
              rel="noreferrer"
            >
              <Button
                size="large"
                disabled={!record.vaTaskId}
                type="text"
                icon={<DownloadOutlined />}
              />
            </a>
          </Tooltip>

          <Tooltip title="Delete">
            <Button
              size="large"
              type="text"
              danger
              onClick={() => handleDelete(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleSchedulersTableChange = (pagination: any, filters: any, sorter: any) => {
    setPaginationSchedulers({
      page: pagination.current,
      size: pagination.pageSize,
    });
  };

  const handleSchedulerDefsTableChange = (pagination: any, filters: any, sorter: any) => {
    setPaginationSchedulerDefs({
      page: pagination.current,
      size: pagination.pageSize,
    });
  };

  const scheduleInfo = () => {
    return (
      <div className="schedule-list">
        <div className={'flex ws-fixed-header'}>
          <div className="ws-fixed-header-add-project">
            <div className={'ws-temp-title'}>
              <h1>Scheduler jobs</h1>
              <Button
                size="small"
                style={{ marginLeft: '1rem' }}
                type="primary"
                icon={
                  <span style={{ marginRight: '0.5rem' }}>
                    <ReloadOutlined />
                  </span>
                }
                onClick={() => refetchSchedulers()}
              >
                Refresh
              </Button>
            </div>
          </div>
        </div>

        <Table
          rowKey="id"
          loading={isLoadingSchedulers}
          columns={columnsSchedulers}
          dataSource={schedulers?.content}
          pagination={{
            current: paginationSchedulers.page,
            pageSize: paginationSchedulers.size,
            total: schedulers?.totalElements,
            showSizeChanger: true,
          }}
          onChange={handleSchedulersTableChange}
        />
      </div>
    );
  };

  const schedulerDefinitions = () => {
    return (
      <div className="scheduled-definitions">
        <div className={'flex ws-fixed-header'}>
          <div className="ws-fixed-header-add-project">
            <div className={'ws-temp-title'}>
              <h1>Scheduler Definitions</h1>
              <Button
                size="small"
                style={{ marginLeft: '1rem' }}
                type="primary"
                icon={
                  <span style={{ marginRight: '0.5rem' }}>
                    <ReloadOutlined />
                  </span>
                }
                onClick={() => refetchSchedulerDefs()}
              >
                Refresh
              </Button>
            </div>
          </div>
        </div>
        {schedulerDefs && (
          <Table
            rowKey="id"
            loading={isLoadingSchedulerDefs}
            columns={columnsSchedulerDefs}
            dataSource={schedulerDefs.content}
            pagination={{
              current: paginationSchedulerDefs.page,
              pageSize: paginationSchedulerDefs.size,
              total: schedulerDefs.totalElements,
              showSizeChanger: true,
            }}
            onChange={handleSchedulerDefsTableChange}
          />
        )}
      </div>
    );
  };

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: `Schedule Jobs`,
      children: scheduleInfo(),
    },
    {
      key: '2',
      label: `Schedule Definitions`,
      children: schedulerDefinitions(),
    },
  ];

  return (
    <>
      {!projectId && <Tabs defaultActiveKey="1" items={tabItems} size="large" />}
      {projectId && (
        <div className="schedule-list">
          <div className={'flex ws-fixed-header'}>
            <div className="ws-fixed-header-add-project">
              <div className={'ws-temp-title'}>
                <h1>Scheduler jobs</h1>
              </div>
              <Button
                size="large"
                type="primary"
                icon={
                  <span style={{ marginRight: '0.5rem' }}>
                    <ReloadOutlined />
                  </span>
                }
                onClick={() => refetchSchedulers()}
              >
                Refresh
              </Button>
            </div>
          </div>
          {schedulers && (
            <Table
              rowKey="id"
              loading={isLoadingSchedulers}
              columns={columnsSchedulers}
              dataSource={schedulers.content}
              pagination={{
                current: paginationSchedulers.page,
                pageSize: paginationSchedulers.size,
                total: schedulers.totalElements,
                showSizeChanger: true,
              }}
              onChange={handleSchedulersTableChange}
            />
          )}
        </div>
      )}
    </>
  );
}

export default SchedulersTable;
