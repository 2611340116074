enum BlendMode {
  None = 'undefined',
  Multiply = 'MULTIPLY',
  Screen = 'SCREEN',
  Overlay = 'OVERLAY',
  HardLight = 'HARD_LIGHT',
  SoftLight = 'SOFT_LIGHT',
  Difference = 'DIFFERENCE',
  Exclusion = 'EXCLUSION',
}

type ShadowSetup = {
  blendMode: BlendMode;
  /** Horizontal offset in "points" (scaled by `cnvHeight / 100`) */
  distanceX: number;
  /** Vertical offset in "points" (scaled by `cnvHeight / 100`) */
  distanceY: number;
  /** Shadow color (default = "black") */
  color?: string;
  /** "Spread" amount → controls <feMorphology radius> */
  spread?: number;
  /** "Blur" amount → controls <feGaussianBlur stdDeviation> */
  blur?: number;
  /** Shadow opacity */
  opacity?: number;
};

export { BlendMode };
export type { ShadowSetup };
