import lottie from 'lottie-web';

import { ScreenshotData } from '../../helpers/screenshotElement';
import { ImagePanelDefTemplate } from '../../model/definitions/ImagePanelDefTemplate';
import { animationDrawingsCache } from '../../molecules/mapElement/AnimationDrawingsCache';
import { axiosInstance } from './axiosInstance';

const JsonLottieResponse: Record<string, string> = {};

const waitForLottieCanvas = async ({
  lottieData,
  drawId,
  versionId,
}: {
  lottieData: any;
  drawId: string;
  versionId: string;
}) => {
  /**waitForLottieCanvas is async sometimes creates two containers with canvas */
  let container: HTMLElement | null = document.getElementById('animationDrawing-' + drawId);
  const cacheEntry = animationDrawingsCache.getByVersionId(versionId);
  if (cacheEntry) {
    return { canvas: cacheEntry.canvas, anim: cacheEntry.anim };
  }

  if (!container) {
    container = document.createElement('div');
    container.id = 'animationDrawing-' + drawId;
    container.style.width = '20px';
    container.style.height = '20px';
    container.style.position = 'absolute';
    container.style.right = '-9999px';

    document.body.appendChild(container);
    const anim = lottie.loadAnimation({
      container,
      renderer: 'canvas',
      loop: true,
      autoplay: false,
      animationData: lottieData,
      rendererSettings: {
        clearCanvas: true,
      },
    });
    await new Promise<void>((resolve, reject) => {
      anim.addEventListener('DOMLoaded', () => {
        resolve();
      });
    });

    const canvas = container.getElementsByTagName('canvas')[0] as HTMLCanvasElement;
    animationDrawingsCache.setByVersionId({
      versionId,
      canvas,
      anim,
      drawId: drawId,
    });
    window.dispatchEvent(
      new CustomEvent('lottie-canvas-created', { detail: { canvas, anim, versionId } }),
    );
    return { canvas, anim };
  }
  /**Handle race condition if is in progress and is called again */
  const { canvas, anim } = await animationDrawingsCache.waitForCacheEntry(versionId);
  return { canvas, anim };
};

const getMultimediaByVersionId = async (id: string, isJson?: boolean) => {
  if (isJson && JsonLottieResponse[id]) return JsonLottieResponse[id];
  const res = await axiosInstance.get(`multimedia/download/${id}`, {
    responseType: isJson ? 'json' : 'arraybuffer',
  });
  if (isJson) {
    JsonLottieResponse[id] = res.data;
  }
  return res.data;
};

const setMultimediaThumbnail = async (data: {
  versionId: string;
  thumbnail: ScreenshotData;
  multimediaType: 'IMAGE' | 'VIDEO' | 'AUDIO';
}) => {
  const {
    versionId,
    thumbnail: { blob },
    multimediaType,
  } = data;
  const fData = new FormData();
  fData.append('versionId', versionId);
  fData.append('files', blob!, 'file1');
  const res = await axiosInstance.post(`multimedia/${multimediaType}/setThumbnail`, fData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

const uploadImageFile = async (data: {
  name: string;
  description: string;
  media: Blob;
}): Promise<ImagePanelDefTemplate> => {
  const { name, description, media } = data;
  const fData = new FormData();
  fData.append('file', media);
  fData.append('type', 'IMAGE');
  fData.append('description', description ? description : '');
  fData.append('name', name ? name : '');
  const res = await axiosInstance.post<ImagePanelDefTemplate>('multimedia/uploadFile', fData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
  return res.data;
};

const imageToBlob = async (url?: string) => {
  if (!url) return;
  const id = url.substring(url.lastIndexOf('/') + 1);
  const { data } = await axiosInstance.get(`multimedia/download/cdn/${id}`, {
    responseType: 'arraybuffer',
  });
  return URL.createObjectURL(new Blob([data], { type: 'octet/stream' }));
};

export {
  getMultimediaByVersionId,
  imageToBlob,
  setMultimediaThumbnail,
  uploadImageFile,
  waitForLottieCanvas,
};
