import * as Sentry from '@sentry/react';
// import { simplify } from '@turf/turf';
import * as zip from '@zip.js/zip.js';
import { chunk } from 'lodash';

import { fromWDLocationToPointBox } from '../../helpers/boundsManage';
import { GribMapLayer } from '../../model/definitions/GribMapLayer';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { RadarMapLayer } from '../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../model/definitions/SatelliteMapLayer';
import {
  SymbolLayerDef,
  SymbolPointType,
  SymbolSourceType,
} from '../../model/definitions/SymbolLayerDef';
import { VisualisationTypeEnum } from '../../model/enums/VisualisationTypeEnum';
import { setupSentry } from '../../setupSentry';
import { WeatherDataHttpClient } from './WeatherDataHttpClient';
import {
  FrameLoadingResult,
  FrameLoadingResultData,
  FrameLoadingStatus,
  FrameLoadingSymbolData,
  PointWithValue,
} from './WeatherDataLoaderTypes';

interface MessageWithPayload {
  type: string;
  payload: any;
}

export enum MessageTypeEnum {
  token = 'token',
  data = 'data',
  abortinitial = 'abortinitial',
}

const distance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const p = 0.017453292519943295; // Math.PI / 180
  const c = Math.cos;
  const a =
    0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
};

let abortController = new AbortController();

setupSentry();

const mapToPropertyFromResult = (
  pointDataResult: any,
  layer: SymbolLayerDef,
  isInitial = false,
) => {
  /**Map to proper property, forecast and observed */
  if (layer.symbolSource.pointType === SymbolPointType.Observed) {
    return isInitial ? pointDataResult.result.observedData : pointDataResult.observedData;
  } else {
    return pointDataResult.forecasts;
  }
};

self.onmessage = (event: MessageEvent<MessageWithPayload>) => {
  if (event.data.type === MessageTypeEnum.token) {
    WeatherDataHttpClient.setAccessToken(event.data.payload.token);
    WeatherDataHttpClient.setUserEmail(event.data.payload.userId);
    WeatherDataHttpClient.setOrganizationId(event.data.payload.orgId);
    WeatherDataHttpClient.setProjectId(event.data.payload.projectId);
    return;
  }

  if (event.data.type === MessageTypeEnum.abortinitial) {
    abortController.abort();
    abortController = new AbortController();
  }

  if (event.data.type === MessageTypeEnum.data) {
    const projectId = event.data.payload.projectId as string;
    const mapPanel = event.data.payload.mapPanel as MapPanelDef;
    const firstFrameOnly = event.data.payload.firstFrameOnly as boolean;
    const loadFirstTime = event.data.payload.loadFirstTime as boolean;
    const framesToLoadPerLayer = event.data.payload.framesToLoadPerLayer as Record<
      string,
      string[]
    >;
    event.data.payload.layers.forEach(
      (layer: RadarMapLayer | GribMapLayer | SatelliteMapLayer | SymbolLayerDef) => {
        const layerType = getLayerType(layer);
        if (layerType != 'symbol') {
          loadLayer(
            layer as RadarMapLayer | GribMapLayer | SatelliteMapLayer,
            mapPanel,
            firstFrameOnly,
            framesToLoadPerLayer,
            (frameId: string, layerId: string, data?: FrameLoadingResultData, geojson?: any) => {
              /**Clean all [null, null] coordinates coming from BE and make it valid for simplify */

              const isIsoline = Boolean(geojson);
              if (isIsoline) {
                try {
                  for (let i = 0; i < geojson.features.length; i++) {
                    for (let j = 0; j < geojson.features[i].geometry.coordinates.length; j++) {
                      let k = 0;
                      while (k < geojson.features[i].geometry.coordinates[j].length) {
                        const coords = geojson.features[i].geometry.coordinates[j][k];

                        if (coords.includes(null)) {
                          geojson.features[i].geometry.coordinates[j].splice(k, 1);
                        } else {
                          k++;
                        }
                      }
                    }
                    let n = 0;
                    while (n < geojson.features[i].geometry.coordinates.length) {
                      /**At least two coordinates after null clean up */
                      if (geojson.features[i].geometry.coordinates[n].length <= 2) {
                        geojson.features[i].geometry.coordinates.splice(n, 1);
                      } else {
                        n++;
                      }
                    }
                  }

                  // simplify(geojson, { tolerance: 0.2, highQuality: true, mutate: true });
                } catch (e) {
                  /**Json probably not valid let it pass as it is */
                  console.log('Error simplifying ', e);
                }
              }

              const isGVNSP =
                mapPanel.baseMapSetup?.baseMapConfigurationProj4?.includes('+proj=nsper') &&
                isIsoline;
              if (isGVNSP) {
                const { projectionCenterLat, projectionCenterLon } = mapPanel.properties;
                for (let i = 0; i < geojson.features.length; i++) {
                  for (let j = 0; j < geojson.features[i].geometry.coordinates.length; j++) {
                    let k = 0;
                    let newLine = false;
                    while (k < geojson.features[i].geometry.coordinates[j].length) {
                      const [lon, lat] = geojson.features[i].geometry.coordinates[j][k];
                      const dist = distance(
                        lat,
                        lon,
                        Number(projectionCenterLat),
                        Number(projectionCenterLon),
                      );
                      if (dist > 6371) {
                        // 6371 km is earth radius, if the coordinate is more than R away, remove it
                        geojson.features[i].geometry.coordinates[j].splice(k, 1);
                        // we make a new line when we remove coordinates to prevent coordinates being removed in the middle which will cause a straight line
                        newLine = true;
                      } else {
                        // if we're making a new line, take all the rest of the coordinates from this one and add then to the new one
                        if (newLine) {
                          const newLineCoordinates =
                            geojson.features[i].geometry.coordinates[j].splice(k);
                          geojson.features[i].geometry.coordinates.splice(
                            j + 1,
                            0,
                            newLineCoordinates,
                          );
                          newLine = false;
                        }
                        k++;
                      }
                    }
                  }
                }
              }

              const result: FrameLoadingResult = {
                status: geojson ? FrameLoadingStatus.Success : FrameLoadingStatus.Loading,
                frameId,
                data,
                layerId,
                geojson,
              };
              self.postMessage(result);
            },
            (frameId: string, layerId: string) => {
              const result: FrameLoadingResult = {
                status: FrameLoadingStatus.Error,
                frameId: frameId,
                layerId,
              };
              self.postMessage(result);
            },
          );
        } else {
          loadSymbolLayer(
            projectId,
            layer as SymbolLayerDef,
            mapPanel,
            firstFrameOnly,
            loadFirstTime,
            framesToLoadPerLayer,
            (frameId: string, layerId: string, symbolData: FrameLoadingSymbolData) => {
              const result: FrameLoadingResult = {
                status: FrameLoadingStatus.Success,
                frameId,
                layerId,
                symbolData,
              };

              self.postMessage(result);
            },
            (frameId: string, layerId: string) => {
              const result: FrameLoadingResult = {
                status: FrameLoadingStatus.Error,
                frameId: frameId,
                layerId,
              };

              self.postMessage(result);
            },
          );
        }
      },
    );
  }
};

const getLayerType = (layer: RadarMapLayer | GribMapLayer | SatelliteMapLayer | SymbolLayerDef) => {
  if (Object.hasOwn(layer, 'radarSource')) {
    return 'radar';
  }
  if (Object.hasOwn(layer, 'satelliteSource')) {
    return 'satellite';
  }
  if (Object.hasOwn(layer, 'symbolSource')) {
    return 'symbol';
  }
  return 'model';
};

const loadLayer = async (
  layer: RadarMapLayer | GribMapLayer | SatelliteMapLayer,
  mapPanel: MapPanelDef,
  firstFrameOnly: boolean,
  framesToLoadPerLayer: Record<string, string[]>,
  onSuccess: (
    frameId: string,
    layerId: string,
    data?: FrameLoadingResultData,
    geojson?: any,
  ) => void,
  onError: (frameId: string, layerId: string) => void,
) => {
  const layerType = getLayerType(layer);

  const unit = layer.layerSetup.colorPaletteDef?.colorStops.unit;

  const isIsoline = layer.layerSetup.visualisationType === VisualisationTypeEnum.ISOLINE;

  const framesToLoad = firstFrameOnly
    ? [layer.dataFrames[0].frameId]
    : framesToLoadPerLayer[layer.id];

  const chunked = chunk(framesToLoad, 4);
  const parallelGroups = chunk(chunked, isIsoline ? 1 : 4);

  for (const group of parallelGroups) {
    const requests = [];
    for (const chunk of group) {
      if (isIsoline) {
        for (const frameId of chunk) {
          const url = `${process.env.REACT_APP_API_BASE_URL}${layer.layerSetup.initiateFramesBaseUrl}${frameId}`;
          requests.push(
            WeatherDataHttpClient.getInstance()
              .getIsolineData(url)
              .then(async (response) => {
                if (!response) {
                  onError(frameId, layer.id);
                  return;
                }
                onSuccess(frameId, layer.id, undefined, response);
              })
              .catch((error) => {
                Sentry.captureException(error);
                onError(frameId, layer.id);
              }),
          );
        }
      } else {
        requests.push(
          WeatherDataHttpClient.getInstance()
            .getWeatherData(
              layerType,
              chunk,
              mapPanel.baseMapSetup.boundingBox.upperLeft,
              mapPanel.baseMapSetup.boundingBox.bottomRight,
              mapPanel.baseMapSetup.projectionParams!,
              unit || '',
              layer.layerSetup.preprocessing,
              layer.layerSetup.postprocessing,
              layer.layerSetup.embossEffect,
              layer.layerSetup.numberOfIterations,
            )
            .then(async (response) => {
              if (!response.zip) {
                for (const frameId of chunk) {
                  onError(frameId, layer.id);
                }
                return;
              }

              try {
                // unzip the blob
                const data = await unzipBlob(response.zip);
                console.log('Unzipped data ', data);
                for (const frame of data) {
                  onSuccess(frame.frameId, layer.id, frame);
                }
              } catch (e) {
                Sentry.captureException(e);
                for (const frameId of chunk) {
                  onError(frameId, layer.id);
                }
              }
            })
            .catch((error) => {
              Sentry.captureException(error);
              for (const frameId of chunk) {
                onError(frameId, layer.id);
              }
            }),
        );
      }
    }
    await Promise.all(requests);
  }
};

const getSymbolValues = (p: any, layer: SymbolLayerDef) => {
  const values = [];
  let weatherType = '';
  const pointTypeKey =
    layer.symbolSource.pointType == SymbolPointType.Observed ? 'observed' : 'forecast';
  if (p[pointTypeKey]) {
    if (layer.symbolSource.pointParameter === 'WindSpeedAndDirection') {
      const speed = p[pointTypeKey].properties
        .find((p: any) => p.name === 'WindSpeed')
        .values.find(
          (v: any) => v.unit === layer.symbolSource.unit || !layer.symbolSource.unit,
        ).value;
      const direction = p[pointTypeKey].properties
        .find((p: any) => p.name === 'WindDirection')
        .values.find((v: any) => v.unit === 'Degree').value;
      values.push(direction);
      values.push(speed);
    } else if (layer.symbolSource.pointParameter === 'WeatherType') {
      weatherType = p[pointTypeKey].weatherType;
    } else {
      console.assert(
        Boolean(p[pointTypeKey].properties?.length),
        'Warning: Received empty Properties',
      );
      if (layer.symbolSource.unit) {
        values.push(
          p[pointTypeKey].properties[0].values.find((v: any) => v.unit === layer.symbolSource.unit)
            .value,
        );
      } else {
        values.push(p[pointTypeKey].properties[0].values[0].value);
      }
    }
  }
  return { values, weatherType };
};

const getSymbolOldValues = (p: any, layer: SymbolLayerDef) => {
  const oldValues = [];
  let timestamp;
  let userId;
  let overrideId;
  const pointTypeKey =
    layer.symbolSource.pointType == SymbolPointType.Observed ? 'observed' : 'forecast';
  if (p[pointTypeKey] && p[pointTypeKey].properties?.[0]?.overrideInfo) {
    if (layer.symbolSource.pointParameter === 'WindSpeedAndDirection') {
      const speed = p[pointTypeKey].properties
        .find((p: any) => p.name === 'WindSpeed')
        .overrideInfo.oldValues.find(
          (v: any) => v.unit === layer.symbolSource.unit || !layer.symbolSource.unit,
        ).value;
      const direction = p[pointTypeKey].properties
        .find((p: any) => p.name === 'WindDirection')
        .overrideInfo.oldValues.find((v: any) => v.unit === 'Degree').value;
      oldValues.push(direction);
      oldValues.push(speed);
    } else {
      if (layer.symbolSource.unit) {
        oldValues.push(
          p[pointTypeKey].properties[0].overrideInfo.oldValues.find(
            (v: any) => v.unit === layer.symbolSource.unit,
          ).value,
        );
      } else {
        oldValues.push(p[pointTypeKey].properties[0].overrideInfo.oldValues[0].value);
      }
    }
    timestamp = p[pointTypeKey].properties[0].overrideInfo.overrideInfo.timestamp;
    userId = p[pointTypeKey].properties[0].overrideInfo.overrideInfo.userId;
    overrideId = p[pointTypeKey].properties[0].overrideInfo.overrideInfo.id;
  }
  return { oldValues, userId, timestamp, overrideId };
};

const loadSymbolLayer = async (
  projectId: string,
  layer: SymbolLayerDef,
  mapPanel: MapPanelDef,
  firstFrameOnly: boolean,
  loadFirstTime: boolean,
  framesToLoadPerLayer: Record<string, string[]>,
  onSuccess: (frameId: string, layerId: string, symbolData: FrameLoadingSymbolData) => void,
  onError: (frameId: string, layerId: string) => void,
) => {
  if (layer.symbolSource.sourceType === SymbolSourceType.PointData) {
    // these options are required for point data
    if (!layer.symbolSource.pointParameter || !layer.symbolSource.pointDataFrames) return;

    const bounds = layer.symbolSource.gribSource.location;
    const boundingBox = fromWDLocationToPointBox(bounds);

    if (firstFrameOnly) {
      try {
        const pointDataInitialResult =
          await WeatherDataHttpClient.getInstance().getPointDataInitial(
            projectId,
            layer.symbolSource.pointParameter!,
            layer.symbolSource.pointDataFrames![0].startDate,

            loadFirstTime
              ? layer.symbolSource.points
              : layer.symbolSource.points.filter((p) => !p.locationId),
            // layer.symbolSource.points,
            boundingBox,
            layer.symbolSource.pointType || SymbolPointType.Forecast,
            layer.symbolSource.pointSource?.dataProductId as string,
            abortController.signal,
          );
        /**Map to proper property, forecast and observed */

        const initialResult = mapToPropertyFromResult(pointDataInitialResult, layer, true);

        onSuccess(`${layer.symbolSource.pointDataFrames![0].startDate}`, layer.id, {
          points: initialResult.map((p: any) => {
            const isObserved = layer.symbolSource.pointType === SymbolPointType.Observed;
            const { values, weatherType } = getSymbolValues(p, layer);
            const { oldValues, userId, timestamp, overrideId } = getSymbolOldValues(p, layer);
            return {
              lat: p.point.latitude,
              lon: p.point.longitude,
              val: values,
              old_val: oldValues,
              locationId: isObserved ? p.location?.icaoCode : p.location?.id,
              city: {
                name: p.location.name,
                lat: p.location.latitiude,
                lon: p.location.longitude,
              },
              weatherType,
              timestamp,
              userId,
              overrideId,
            };
          }),
          unit: layer.symbolSource.unit,
          frameId: `${layer.symbolSource.pointDataFrames![0].startDate}`,
          frameTimestamp: layer.symbolSource.pointDataFrames![0].startDate,
        });
      } catch (error: any) {
        Sentry.captureException(error);
        if (error?.name === 'AbortError') {
          /**Do not call onError on abort will reset points */
          console.log('Request ABORTED');
          return;
        }
        console.error('Error fetching or processing symbol data INITIAL ', error);
        onError(`${layer.symbolSource.pointDataFrames?.[0]?.startDate}`, layer.id);
      }
    } else {
      const chunked = chunk(layer.symbolSource.pointDataFrames!, 4);

      for (const group of chunked) {
        const requests = [];
        for (const frame of group) {
          requests.push(
            WeatherDataHttpClient.getInstance()
              .getPointData(
                projectId,
                layer.symbolSource.pointParameter!,
                frame.startDate,
                layer.symbolSource.points,
                boundingBox,
                layer.symbolSource.pointType || SymbolPointType.Forecast,
                layer.symbolSource.pointSource?.dataProductId as string,
              )
              .then((response) => {
                try {
                  const result = mapToPropertyFromResult(response, layer);

                  onSuccess(`${frame.startDate}`, layer.id, {
                    points: result.map((p: any) => {
                      const { values, weatherType } = getSymbolValues(p, layer);

                      console.assert(Boolean(p.location), 'RECEIVED EMPTY LOCATION');
                      const { oldValues, userId, timestamp, overrideId } = getSymbolOldValues(
                        p,
                        layer,
                      );
                      return {
                        lat: p.point.latitude,
                        lon: p.point.longitude,
                        val: values,
                        old_val: oldValues,
                        locationId: p.location?.id,
                        city: {
                          name: p.location?.name,
                          lat: p.location?.latitiude,
                          lon: p.location?.longitude,
                        },
                        weatherType,
                        timestamp,
                        userId,
                        overrideId,
                      };
                    }),
                    unit: layer.symbolSource.unit,
                    frameId: `${frame.startDate}`,
                    frameTimestamp: frame.startDate,
                  });
                } catch (error) {
                  Sentry.captureException(error);
                  onError(`${frame.startDate}`, layer.id);
                  console.error('Error fetching or processing symbol data ', error);
                }
              })
              .catch((error) => {
                Sentry.captureException(error);
                onError(`${frame.startDate}`, layer.id);
              }),
          );
        }
        await Promise.all(requests);
      }
    }

    return;
  }

  const framesToLoad = firstFrameOnly ? [layer.dataFrames[0]] : layer.dataFrames;

  const chunked = chunk(framesToLoad, 4);
  const parallelGroups = chunk(chunked, 4);

  for (const group of parallelGroups) {
    const requests = [];
    for (const chunk of group) {
      requests.push(
        WeatherDataHttpClient.getInstance()
          .getSymbols(
            projectId,
            chunk.map((c) => c.frameId),
            // loadFirstTime
            //   ? layer.symbolSource.points
            //   : [layer.symbolSource.points[layer.symbolSource.points.length - 1]],
            loadFirstTime
              ? layer.symbolSource.points
              : layer.symbolSource.points.filter((p) => !p.locationId),
            // layer.symbolSource.points,
            layer.symbolSource.unit,
            layer.symbolSource.gribSource.parameterType.name,
          )
          .then(async (response) => {
            try {
              const frames = response.frames;
              const parameterType = layer.symbolSource.gribSource.parameterType.name;
              for (const frame of chunk) {
                if (frames[frame.frameId].error) {
                  onError(frame.frameId, layer.id);
                } else {
                  const frameData = {
                    frameId: frame.frameId,
                    points: frames[frame.frameId].points,
                    unit: frames[frame.frameId].unit,
                  };
                  if (parameterType === 'WeatherType') {
                    frameData.points = frameData.points.map((point: PointWithValue) => ({
                      ...point,
                      weatherType: point.val[0], // symbolLayerWeatherType use weatherType to display icon
                    }));
                  }
                  onSuccess(frame.frameId, layer.id, frameData);
                }
              }
            } catch (e) {
              Sentry.captureException(e);
              for (const frame of chunk) {
                onError(frame.frameId, layer.id);
              }
            }
          })
          .catch((error) => {
            Sentry.captureException(error);
            for (const frame of chunk) {
              onError(frame.frameId, layer.id);
            }
          }),
      );
    }
    await Promise.all(requests);
  }
};

const unzipBlob = async (blob: Blob): Promise<FrameLoadingResultData[]> => {
  const coordsCache: Record<string, any> = {};
  const results: FrameLoadingResultData[] = [];

  zip.configure({
    useWebWorkers: false,
  });

  const reader = new zip.BlobReader(blob);
  const zipReader = new zip.ZipReader(reader);
  const entries = await zipReader.getEntries();

  const jsonFile = entries.find((file) => file.filename === 'content_decode_information.json');
  const textWriter = new zip.TextWriter();
  if (jsonFile && jsonFile.getData) {
    const jsonText = await jsonFile.getData(textWriter);
    // console.log(jsonText);
    const contentJson = JSON.parse(jsonText);

    const frameIds = Object.keys(contentJson.frames);

    for (let i = 0; i < frameIds.length; i++) {
      const frameInfo = contentJson.frames[frameIds[i]];

      if (frameInfo.error && frameInfo.error.length) {
        throw new Error(frameInfo.error);
      }

      const coordsFileName = frameInfo.coordinates.id;

      if (!coordsCache[coordsFileName]) {
        const coordsImageFile = entries.find((file) => file.filename == coordsFileName);
        const coordsBlobWriter = new zip.BlobWriter('image/png');
        if (coordsImageFile && coordsImageFile.getData) {
          const image = await coordsImageFile.getData(coordsBlobWriter);
          coordsCache[coordsFileName] = URL.createObjectURL(image);
        }
      }

      const coordinates = coordsCache[coordsFileName];

      const fileName = frameInfo.values.id;

      const imageFile = entries.find((file) => file.filename == fileName);
      const blobWriter = new zip.BlobWriter('image/png');
      if (imageFile && imageFile.getData) {
        const imageBlob = await imageFile.getData(blobWriter);
        const image = URL.createObjectURL(imageBlob);

        results.push({
          frameId: frameIds[i],
          frameInfo,
          image,
          coordinates,
        });
      }
    }
  }

  return results;
};

export {};
