import { CSSProperties, useEffect, useRef, useState } from 'react';
import { ColorResult } from 'react-color';
import useEyeDropper from 'use-eye-dropper';

import Chrome from '../../../../atoms/color-picker-refactor/chrome/Chrome';
import styles from '../Properties.module.scss';
import { CloseOnClickOutside } from './CloseOnClickOutside';

interface ColorPickerProps {
  value: string; // e.g. "rgba(100,150,200,1)" or "100,150,200,1"
  disabled?: boolean;
  onChange?: (e: string) => void;
  isPalette?: boolean;
  outline?: boolean;
  browserRGBA?: boolean;
  pickerStyle?: CSSProperties;
}

/** We'll store all channels (r,g,b,a) in [0..255]. */
interface RGBA255 {
  r: number;
  g: number;
  b: number;
  a: number; // 0..255
}

/**
 * Clamp a numeric value so it doesn't exceed [min..max].
 */
function clamp(v: number, min: number, max: number) {
  return Math.max(min, Math.min(v, max));
}

/**
 * Parse a value like "rgba(100, 150, 200, 1)" or "100,150,200,1"
 * and return an object with r,g,b,a each in [0..255].
 *
 * IMPORTANT: We do NOT multiply alpha by 255 if a <= 1.
 * That means if the user typed "rgba(100,150,200,1)", we literally store a=1 in [0..255].
 */
function parseValueToRGBA255(val?: string): RGBA255 {
  if (!val) {
    return { r: 255, g: 255, b: 255, a: 255 };
  }
  const nums = val.match(/\d+(\.\d+)?/g);
  if (!nums) {
    return { r: 255, g: 255, b: 255, a: 255 };
  }

  // Convert to numbers and destructure r, g, b, a. If no alpha, default 255.
  let [r, g, b, a = 255] = nums.map(Number);

  // Clamp each to [0..255]
  r = clamp(r, 0, 255);
  g = clamp(g, 0, 255);
  b = clamp(b, 0, 255);
  a = clamp(a, 0, 255);

  return { r, g, b, a };
}

export const PaletteColorPicker = ({
  value,
  onChange,
  isPalette = false,
  disabled,
  outline,
  browserRGBA,
  pickerStyle,
}: ColorPickerProps) => {
  const pickerRef = useRef<HTMLDivElement>(null);
  const [pickerVisible, setPickerVisible] = useState(false);
  const { open, isSupported } = useEyeDropper();

  /**
   * Convert a #RRGGBB => store r,g,b in [0..255], alpha=255 by default.
   */
  const hexToRGB = (hex: string) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    setColor({ r, g, b, a: 255 });
  };

  // Parse initial value once
  const [color, setColor] = useState<RGBA255>(() => parseValueToRGBA255(value));

  /**
   * EyeDropper always returns a #RRGGBB (no alpha).
   * So if user picks with the eye-dropper, we do alpha=255.
   */
  const pickColor = () => {
    open().then((result) => {
      if (result?.sRGBHex) {
        hexToRGB(result.sRGBHex);
      }
    });
  };

  /**
   * Whenever our local `color` state changes, call onChange with the updated string.
   * Decide the exact string format for your scenario.
   */
  useEffect(() => {
    if (!onChange) return;

    if (isPalette) {
      // e.g. "100, 150, 200, 1"
      onChange(`${color.r}, ${color.g}, ${color.b}, ${color.a}`);
    } else if (browserRGBA) {
      // e.g. "rgba(100, 150, 200, 1)"
      onChange(`rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`);
    } else {
      // Similar to the above.
      onChange(`rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`);
    }
  }, [color, isPalette, browserRGBA]);

  /**
   * If the parent passes a new `value`, parse it again to keep local state in sync.
   */
  useEffect(() => {
    setColor(parseValueToRGBA255(value));
  }, [value]);

  /**
   * Focus the popover if visible
   */
  useEffect(() => {
    if (pickerVisible) {
      pickerRef.current?.focus();
    }
  }, [pickerVisible]);

  /**
   * Helper to clamp and update a single channel (r,g,b,a).
   */
  const editColor = (channel: keyof RGBA255, val: number) => {
    setColor((prev) => ({
      ...prev,
      [channel]: Math.round(clamp(val, 0, 255)),
    }));
  };

  return (
    <div className={styles.colorPickerPalette}>
      {/* The indicator needs alpha in [0..1] for CSS. So do color.a / 255. */}
      <div
        onClick={() => !disabled && setPickerVisible(true)}
        className={styles.indicator}
        style={{
          backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a / 255})`,
          width: 25,
          height: 25,
          minWidth: 25,
          outline: outline ? '1px solid #d9d9d9' : '',
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
      />

      <div className="flex items-center space-x-2">
        <div className="flex items-center">
          R:{' '}
          <input
            type="number"
            className={styles.colInput}
            value={color.r}
            min={0}
            max={255}
            onChange={(e) => editColor('r', Number(e.target.value))}
            disabled={disabled}
          />
        </div>
        <div className="flex items-center">
          G:{' '}
          <input
            type="number"
            className={styles.colInput}
            value={color.g}
            min={0}
            max={255}
            onChange={(e) => editColor('g', Number(e.target.value))}
            disabled={disabled}
          />
        </div>
        <div className="flex items-center">
          B:{' '}
          <input
            type="number"
            className={styles.colInput}
            value={color.b}
            min={0}
            max={255}
            onChange={(e) => editColor('b', Number(e.target.value))}
            disabled={disabled}
          />
        </div>
        <div className="flex items-center">
          A:{' '}
          <input
            type="number"
            className={styles.colInput}
            value={color.a}
            min={0}
            max={255}
            onChange={(e) => editColor('a', Number(e.target.value))}
            disabled={disabled}
          />
        </div>
      </div>

      {pickerVisible && (
        <CloseOnClickOutside
          onClose={() => !disabled && setPickerVisible(false)}
          className={styles.palettePickerWrapper}
          style={pickerStyle}
          ref={pickerRef}
        >
          {/*
            The Chrome color picker expects alpha in [0..1],
            so we convert from our 0..255 alpha.
          */}
          <Chrome
            color={{
              r: color.r,
              g: color.g,
              b: color.b,
              a: color.a / 255,
            }}
            onChange={(res: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
              event.stopPropagation();
              setColor({
                r: res.rgb.r,
                g: res.rgb.g,
                b: res.rgb.b,
                // Chrome alpha is 0..1 => multiply by 255
                a: Math.round((res.rgb.a ?? 1) * 255),
              });
            }}
            pickColor={pickColor}
            colorByName={hexToRGB}
            isSupported={isSupported}
          />
        </CloseOnClickOutside>
      )}
    </div>
  );
};
