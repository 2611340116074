/* eslint-disable unused-imports/no-unused-vars */
import { Button, InputNumber, Select } from 'antd';
import { ToggleSwitch } from 'flowbite-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFontLoader, useFontSetter } from '../../../../core/api/useLoadFont';
import { SymbolStyleDef } from '../../../../model/definitions/SymbolLayerDef';
import { UnitDisplayEnum } from '../../../../model/enums/UnitDisplayEnum';
import { ActiveDef } from '../../../../store/slices/active-slice';
import { updateSymbolLayerStyle } from '../../../../store/slices/project-slice';
import { RootState } from '../../../../store/store';
import SymbolImageModal from '../../modals/SymbolImageModal';
import { FontInterface, FontVariantInterface } from '../panels/FontProperties';
import styles from '../Properties.module.scss';
import GridItem from '../shared/GridItem';
import GridWrapper from '../shared/GridWrapper';
import { PaletteColorPicker } from './PalettecolorPicker';

interface Props {
  symbolStyle: SymbolStyleDef;
  layerId: string;
  mapId: string;
  isWindLayer: boolean;
}

type SymbolKeys = Leaves<SymbolStyleDef>;
export const SymbolStyleProperties = ({ symbolStyle, layerId, mapId, isWindLayer }: Props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const {
    fontSize,
    fillColor,
    fontColor,
    fontFamily,
    fontType,
    strokeWidth,
    strokeColor,
    fontStrokeWidth,
    fontStrokeColor,
    showWindArrow,
    showWindValue,
    windArrowImage,
    windArrowScale,
    showUnit,
    dualColor,
    precision,
    unitDisplay,
    scaleByWindSpeed,
    iconSize,
    threshold,
    thresholdColor,
    lowColor,
  } = symbolStyle;
  const { activeScene } = useSelector<RootState>((state) => state.active) as ActiveDef;

  const { fonts, fontVariants, fetchFontVariants } = useFontLoader(fontFamily);

  useFontSetter(
    fontVariants,
    fontType,
    fontTypeSetter,
    fontVariantIdSetter,
    fetchFontVariants.isLoading,
  );

  function fontTypeSetter(value: string) {
    updateProperty(value, 'fontType');
  }

  function fontVariantIdSetter(value: string) {
    updateProperty(value, 'fontVariantId');
  }

  const updateProperty = (e: number | string | boolean | null, name: SymbolKeys) => {
    dispatch(
      updateSymbolLayerStyle({
        newValue: e,
        activeScene: activeScene,
        propertyPath: name,
        layerId: layerId,
        mapId: mapId,
      }),
    );
  };

  const unitDisplayOptions = Object.keys(UnitDisplayEnum).map((key) => ({
    label: key
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/^\w/, (c) => c.toUpperCase()),
    value: UnitDisplayEnum[key as keyof typeof UnitDisplayEnum],
  }));

  return (
    <div>
      <GridWrapper className="mt-4">
        <GridItem
          label="Font family:"
          item={
            <select
              className={styles.select}
              onChange={(e) => {
                updateProperty(e.target.value, 'fontFamily');
                updateProperty('', 'fontType');
                updateProperty('', 'fontVariantId');
              }}
              value={fontFamily}
            >
              {fonts?.map((font: FontInterface) => (
                <option
                  key={`${font.name}_${layerId}`}
                  value={font.name.split('(')[0]}
                  style={{ fontFamily: font.name.split('(')[0] }}
                >
                  {font.name}
                </option>
              ))}
            </select>
          }
        />
        <GridItem
          label="Font type:"
          item={
            <select
              className={styles.select}
              value={fontType}
              onChange={(e) => {
                const findType = fontVariants.find(
                  (fontVariant: FontVariantInterface) => fontVariant.type === e.target.value,
                );
                updateProperty(e.target.value, 'fontType');
                updateProperty(findType.id, 'fontVariantId');
              }}
            >
              {Array.isArray(fontVariants) &&
                fontVariants?.map((fontVariant: FontVariantInterface) => (
                  <option key={`${fontVariant.id}_${layerId}`} value={fontVariant.type}>
                    {fontVariant.type}
                  </option>
                ))}
            </select>
          }
        />
        <GridItem
          label="Font size:"
          item={
            <input
              className={styles.inputWrap}
              min={0}
              value={fontSize}
              onChange={(e) => {
                updateProperty(e.target.value, 'fontSize');
              }}
            />
          }
        />
        <GridItem
          noBorderBg
          label="Font color:"
          item={
            <PaletteColorPicker
              value={fontColor}
              onChange={(e) => updateProperty(e, 'fontColor')}
            />
          }
        />
        <GridItem
          noBorderBg
          label={dualColor ? 'High color' : 'Fill color:'}
          item={
            <PaletteColorPicker
              value={fillColor}
              onChange={(e) => updateProperty(e, 'fillColor')}
            />
          }
        />
        <GridItem
          label="Border width:"
          item={
            <input
              className={styles.inputWrap}
              min={0}
              value={strokeWidth}
              onChange={(e) => {
                updateProperty(e.target.value, 'strokeWidth');
              }}
            />
          }
        />
        <GridItem
          noBorderBg
          label="Border color:"
          item={
            <PaletteColorPicker
              value={strokeColor}
              onChange={(e) => updateProperty(e, 'strokeColor')}
            />
          }
        />
        <GridItem
          label="Stroke width:"
          item={
            <input
              className={styles.inputWrap}
              min={0}
              value={fontStrokeWidth}
              onChange={(e) => {
                updateProperty(e.target.value, 'fontStrokeWidth');
              }}
            />
          }
        />
        <GridItem
          noBorderBg
          label="Stroke color:"
          item={
            <PaletteColorPicker
              value={fontStrokeColor}
              onChange={(e) => updateProperty(e, 'fontStrokeColor')}
            />
          }
        />
        <GridItem
          noBorderBg
          label="Show unit:"
          item={
            <ToggleSwitch
              checked={showUnit}
              label={'show unit'}
              onChange={(e) => {
                updateProperty(e, 'showUnit');
              }}
            />
          }
        />
        <GridItem
          noBorderBg
          label="Dual color fill:"
          item={
            <ToggleSwitch
              checked={dualColor}
              label={'dual color fill'}
              onChange={(e) => {
                updateProperty(e, 'dualColor');
              }}
            />
          }
        />
        {dualColor && (
          <>
            <GridItem
              noBorderBg
              label={'Threshold'}
              item={
                <InputNumber
                  value={threshold}
                  onChange={(e) => {
                    updateProperty(e, 'threshold');
                  }}
                />
              }
            />
            <GridItem
              noBorderBg
              label="Threshold color:"
              item={
                <PaletteColorPicker
                  value={thresholdColor}
                  onChange={(e) => updateProperty(e, 'thresholdColor')}
                />
              }
            />
            <GridItem
              noBorderBg
              label="Low color:"
              item={
                <PaletteColorPicker
                  value={lowColor}
                  onChange={(e) => updateProperty(e, 'lowColor')}
                />
              }
            />
          </>
        )}
        {!isWindLayer && (
          <>
            <GridItem
              noBorderBg
              label="Precision:"
              item={
                <InputNumber
                  value={precision}
                  min={0}
                  max={100}
                  precision={0}
                  onChange={(e) => {
                    updateProperty(e, 'precision');
                  }}
                />
              }
            />
            <GridItem
              noBorderBg
              label="Unit display:"
              item={
                <Select
                  options={unitDisplayOptions}
                  value={unitDisplay}
                  onChange={(e) => {
                    updateProperty(e, 'unitDisplay');
                  }}
                />
              }
            />
            <GridItem
              noBorderBg
              label="Icon size:"
              item={
                <InputNumber
                  value={iconSize}
                  onChange={(e) => {
                    updateProperty(e, 'iconSize');
                  }}
                />
              }
            />
          </>
        )}
        {isWindLayer ? (
          <>
            <GridItem
              noBorderBg
              label="Show wind arrow:"
              item={
                <ToggleSwitch
                  checked={showWindArrow}
                  label={'show wind arrow'}
                  onChange={(e) => {
                    updateProperty(e, 'showWindArrow');
                  }}
                />
              }
            />
            <GridItem
              noBorderBg
              label="Show wind value:"
              item={
                <ToggleSwitch
                  checked={showWindValue}
                  label={'show wind value'}
                  onChange={(e) => {
                    updateProperty(e, 'showWindValue');
                  }}
                />
              }
            />
            <GridItem
              itemStyle={{ height: 'auto' }}
              label={'Arrow image:'}
              noBorderBg
              item={
                <Button className="!h-auto" onClick={() => setIsOpen(true)}>
                  Choose file
                </Button>
              }
            />
            <GridItem
              label={'Scale:'}
              item={
                <input
                  style={{ padding: '0' }}
                  type={'number'}
                  min={0}
                  onChange={(e) => updateProperty(e.target.value, 'windArrowScale')}
                  value={windArrowScale ?? 1}
                  className={styles.inputWrap}
                />
              }
            />
            <GridItem
              noBorderBg
              label="Scale by wind speed:"
              item={
                <ToggleSwitch
                  checked={scaleByWindSpeed}
                  label={'show scale'}
                  onChange={(e) => {
                    updateProperty(e, 'scaleByWindSpeed');
                  }}
                />
              }
            />
          </>
        ) : null}
      </GridWrapper>
      <SymbolImageModal
        isOpen={isOpen}
        currentImage={windArrowImage}
        onClose={() => setIsOpen(false)}
        onSave={(e) => {
          setIsOpen(false);
          updateProperty(e, 'windArrowImage');
        }}
      />
    </div>
  );
};
