import './style.scss';

import { Checkbox, Input, InputNumber } from 'antd';
import { ToggleSwitch } from 'flowbite-react';
import { useCallback, useEffect, useState } from 'react';
import { AiFillPlusCircle, AiOutlineDelete } from 'react-icons/ai';

import Button from '../../../../../atoms/button/Button';
import {
  ColorPaletteDef,
  PaletteSetup,
  PaletteSetupDegree,
} from '../../../../../model/definitions/ColorPaletteDef';
import { CustomPaletteDTO } from '../../../../../model/DTO/CustomPaletteDTO';
import { ColorPaletteParamTypeEnum } from '../../../../../model/enums/ColorPaletteParamTypeEnum';
import { PaletteLegendScalingEnum } from '../../../../../model/enums/PaletteLegendScalingEnum';
import { VisualisationTypeEnum } from '../../../../../model/enums/VisualisationTypeEnum';
import Modal from '../../../../../molecules/modal/Modal';
import { PaletteColorPicker } from '../../mapLayersProperties/PalettecolorPicker';
import styles from '../../Properties.module.scss';
import { CustomLegend } from './CustomLegend';
import { defaultDegree, interpolateInSteps, middleColor, numberFormatter } from './utils';

const { TextArea } = Input;
interface CustomPaletteV2Props {
  palette: ColorPaletteDef | CustomPaletteDTO;
  createPalette: (e: ColorPaletteDef) => void;
  isModal: boolean;
  onClose: () => void;
  parameter: ColorPaletteParamTypeEnum;
  visualisation: VisualisationTypeEnum;
  unit: string;
  isEdit: boolean;
}
export const CustomPaletteV2 = ({
  palette,
  createPalette,
  isModal,
  onClose,
  parameter,
  visualisation,
  isEdit,
  unit,
}: CustomPaletteV2Props) => {
  const {
    name: paletteName,
    description: paletteDesc,
    paletteParamType,
    visualisationType,
    colorStops,
  } = palette;
  const [paletteSetup, setPaletteSetup] = useState<PaletteSetup | undefined | null>(palette.setup);
  //const [newPalette, setNewPalette] = useState<Record<number, string>>(colorStops.pallet);
  const [paletteToSet, setPaletteToSet] = useState<Record<number, string>>(colorStops.pallet);
  const [name, setName] = useState<string>(paletteName);
  const [description, setDescription] = useState<string>(paletteDesc);
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(0);
  const [proportional, setProportional] = useState<PaletteLegendScalingEnum>(
    palette.paletteLegendScaling ?? PaletteLegendScalingEnum.HOMOGENOUS,
  );
  useEffect(() => {
    if (palette && !palette.setup?.scale) {
      const degrees: Array<PaletteSetupDegree> = Object.keys(colorStops.pallet).map((key) => {
        return {
          degree: Number(key),
          color: colorStops.pallet[key as unknown as number],
          active: true,
          interpolationSteps: 0,
        };
      });
      setPaletteSetup({
        defaultStep: 0,
        scale: degrees.sort((a, b) => a.degree - b.degree),
      });
    }
  }, [colorStops.pallet, palette]);
  useEffect(() => {
    if (isEdit && paletteSetup) {
      const low = Math.min(...paletteSetup.scale.map((item) => item.degree));
      setMin(low);
      const high = Math.max(...paletteSetup.scale.map((item) => item.degree));
      setMax(high);
    }
  }, [isEdit, paletteSetup]);
  const updateScale = (e: number, maxVal?: number | null, minVal?: number | null) => {
    const newScale = [];
    const degrees = interpolateInSteps(minVal ?? min, maxVal ?? max, e);
    if (paletteSetup) {
      for (let i = 0; i < degrees.length; i++) {
        newScale.push({
          ...defaultDegree,
          degree: degrees[i],
        });
      }
      setPaletteSetup({
        ...paletteSetup,
        scale: newScale,
      });
    }
  };
  /*   const cleanPalette = useCallback(() => {
    if (paletteSetup) {
      const redefinedPalette = Object.fromEntries(
        paletteSetup?.scale.map((item) => [item.degree, item.color]),
      );
      // setNewPalette(redefinedPalette);
    }
  }, [paletteSetup]);

  useEffect(() => {
    cleanPalette();
  }, [cleanPalette, paletteSetup]); */

  const changeInterpolationStep = (e: number, degree: number) => {
    const steps = paletteSetup?.scale;
    if (steps) {
      const pointInScale = steps.find((val) => val.degree === degree);
      if (pointInScale && paletteSetup) {
        pointInScale.interpolationSteps = e;
        setPaletteSetup({
          ...paletteSetup,
          scale: steps,
        });
      }
    }
  };
  const changeColor = (e: string, degree: number) => {
    const steps = paletteSetup?.scale;
    if (steps) {
      const pointInScale = steps.find((val) => val.degree === degree);
      if (pointInScale && paletteSetup) {
        pointInScale.color = e;
        setPaletteSetup({
          ...paletteSetup,
          scale: steps,
        });
      }
    }
  };
  const changeDegree = (e: number, degree: number) => {
    const steps = paletteSetup?.scale;
    if (steps) {
      const pointInScale = steps.find((val) => val.degree === degree);
      const currentDegrees = steps.map((item) => item.degree);
      if (currentDegrees.includes(e)) {
        return;
      }
      if (pointInScale && paletteSetup) {
        pointInScale.degree = e;
        setPaletteSetup({
          ...paletteSetup,
          scale: steps,
        });
      }
    }
  };
  const changeDefaultStep = (e: number) => {
    const steps = paletteSetup?.scale.map((point) => {
      return {
        ...point,
        interpolationSteps:
          point.interpolationSteps === paletteSetup?.defaultStep ? e : point.interpolationSteps,
      };
    });
    if (steps && paletteSetup)
      setPaletteSetup({
        ...paletteSetup,
        defaultStep: e,
        scale: steps,
      });
  };
  const setDegreeEnabled = (e: boolean, degree: number) => {
    const steps = paletteSetup?.scale;
    if (steps) {
      const pointInScale = steps.find((val) => Number(val.degree) === Number(degree));
      if (pointInScale && paletteSetup) {
        pointInScale.active = e;
        setPaletteSetup({
          ...paletteSetup,
          scale: steps,
        });
      }
    }
  };
  const removeColor = (degree: number) => {
    if (paletteSetup) {
      const newScale = paletteSetup?.scale.filter((obj) => obj.degree !== degree);
      setPaletteSetup({
        ...paletteSetup,
        scale: newScale,
      });
    }
  };
  const addColor = (degreeToAdd: number) => {
    if (paletteSetup) {
      const sortedData = paletteSetup.scale.slice().sort((a, b) => a.degree - b.degree);
      const index = sortedData?.findIndex((obj) => obj.degree > degreeToAdd);

      if (index !== -1) {
        const nextHighestElement = sortedData[index];
        const newElement = {
          ...nextHighestElement,
          color: middleColor(sortedData[index - 1].color, sortedData[index].color),
          degree: (degreeToAdd + nextHighestElement.degree) / 2,
        };
        middleColor(sortedData[index - 1].color, sortedData[index].color);
        const newScale = [...paletteSetup.scale, newElement];
        setPaletteSetup({
          ...paletteSetup,
          scale: newScale,
        });
      }
    }
  };
  const renderPickers = useCallback(() => {
    const stops = paletteSetup?.scale.sort((a, b) => a.degree - b.degree);
    const grid = stops?.map((stop, index) => (
      <div key={stop.degree} className={'grid grid-cols-10 gap-3 items-center justify-center'}>
        <InputNumber
          defaultValue={stop.degree?.toString()}
          formatter={(e) => numberFormatter(e, 5)}
          className={'col-span-2'}
          onBlur={(e) => {
            if (!isNaN(Number(e.target.value))) {
              changeDegree(Number(e.target.value), stop.degree);
            }
          }}
          onKeyDown={(e: any) => {
            if (e && e.code === 'Enter' && !isNaN(Number(e.target.value))) {
              changeDegree(Number(e.target.value), stop.degree);
              blur();
            }
          }}
          onChange={(e: any) => {
            if (e && e.code === 'Enter' && !isNaN(Number(e.target.value))) {
              changeDegree(Number(e.target.value), stop.degree);
              blur();
            }
          }}
        />
        <div className={'col-span-4'}>
          <PaletteColorPicker
            isPalette
            value={stop.color}
            onChange={(e) => changeColor(e, stop.degree)}
            pickerStyle={{ left: 25 }}
          />
        </div>
        {index !== (stops?.length ?? 0) - 1 ? (
          <InputNumber
            key={`${stop.interpolationSteps}_${stop.degree}`}
            value={stop.interpolationSteps ?? paletteSetup?.defaultStep}
            className={`justify-self-end col-span-2 w-1/2 text-blue-700 input:text-blue-700 ${
              paletteSetup?.defaultStep !== stop.interpolationSteps && 'step-input-diff'
            }`}
            min={0}
            max={900}
            precision={0}
            onChange={(e) => typeof e === 'number' && changeInterpolationStep(e, stop.degree)}
          />
        ) : (
          <div className={'col-span-2'} />
        )}
        <Checkbox
          className={'justify-center'}
          checked={stop.active}
          onChange={(e) => setDegreeEnabled(e.target.checked, stop.degree)}
        />
        <div className={'flex justify-between gap-3'}>
          <AiOutlineDelete
            className={'text-red-700 cursor-pointer'}
            size={18}
            onClick={() => removeColor(stop.degree)}
          />
          {index < stops?.length - 1 && (
            <AiFillPlusCircle
              className={'text-green-700 cursor-pointer'}
              size={18}
              onClick={() => addColor(stop.degree)}
            />
          )}
        </div>
      </div>
    ));
    return <>{grid}</>;
  }, [paletteSetup?.defaultStep, paletteSetup?.scale]);

  return (
    <Modal
      isOpen={isModal}
      onClose={onClose}
      header={'Palette editor'}
      className={styles.paletteModal}
      style={{ content: { width: 700, overflow: 'hidden' } }}
      footer={
        <div className="modal-footer">
          <div className={'footnote'} />
          <div className={'button-holder'}>
            <Button onClick={onClose} buttonType="secondary" label="Cancel" />
            <Button
              label={'Save'}
              onClick={() => {
                createPalette({
                  ...palette,
                  name: name,
                  description: description,
                  setup: paletteSetup,
                  paletteLegendScaling: proportional,
                  colorStops: { ...palette.colorStops, pallet: paletteToSet },
                } as ColorPaletteDef);
                onClose();
              }}
              disabled={paletteSetup?.scale.length === 0 || min === max || !name}
            />
          </div>
        </div>
      }
    >
      <>
        <div>
          <div>Name:</div>
          <Input
            className={'font-bold rounded-md'}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <div>Description:</div>
          <TextArea
            className={'font-bold rounded-md'}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <>
          <div className={`my-5 uppercase font-light border-b border-white/[.07] layer-header`}>
            Palette properties
          </div>
          <div className={'grid grid-cols-6 gap-1 my-5'}>
            <div className={'flex items-center'}>Parameter: </div>
            <div
              className={
                'bg-blue-800 py-1 rounded-md w-full text-center italic text-ellipsis overflow-hidden whitespace-nowrap'
              }
            >
              {paletteParamType || parameter}
            </div>
            <div className={'flex items-center justify-end '}>Visualisation:</div>
            <div className={'bg-blue-800 py-1 rounded-md w-full text-center italic '}>
              {visualisationType || visualisation}
            </div>
            <div className={'flex items-center justify-end'}>Unit:</div>
            <div className={'bg-blue-800 py-1 rounded-md w-full text-center italic '}>
              {colorStops.unit || unit}
            </div>
          </div>
          <div className={'grid grid-cols-6 gap-1 justify-between items-center'}>
            <div className={'whitespace-nowrap'}>Min Value:</div>
            <InputNumber
              value={min}
              className={'w-full'}
              disabled={isEdit}
              onChange={(e) => {
                e !== null && setMin(e);
                e !== null && e > max && setMax(e);
                paletteSetup &&
                  e !== null &&
                  updateScale(paletteSetup.scale.length - 1, undefined, e);
              }}
            />
            <div className={'whitespace-nowrap  justify-end text-right'}>Max Value:</div>
            <InputNumber
              value={max}
              className={'w-full'}
              disabled={isEdit}
              onChange={(e) => {
                e !== null && setMax(e);
                paletteSetup && updateScale(paletteSetup.scale.length - 1, e);
              }}
            />
            <div className={'whitespace-nowrap  justify-end text-right'}>No of colors:</div>
            <InputNumber
              value={paletteSetup?.scale.length}
              className={'w-full'}
              disabled={isEdit || min === max}
              min={1}
              onChange={(e) => e !== null && updateScale(e - 1)}
            />
          </div>
          <div
            className={`my-5 grid grid-cols-12 gap-3 font-light border-b border-white/[.07] layer-header items-center justify-between`}
          >
            <div className={'uppercase col-span-12'}>Legend</div>
          </div>
          <div className={'grid grid-cols-12'}>
            <div className={'col-span-2'}>Preview</div>
            <div className={'col-span-5'}>
              {paletteSetup && (
                <CustomLegend
                  setup={paletteSetup}
                  key={JSON.stringify(paletteSetup)}
                  proportional={proportional}
                  w={1000}
                  onChange={(e) => setPaletteToSet(e)}
                />
              )}
            </div>
            <div className={'col-span-5 flex gap-2 justify-self-end justify-between relative'}>
              <span className={'absolute top-5 text-sm text-red-500'}>preview purposes only!</span>
              <div>homogenous</div>
              <ToggleSwitch
                label={''}
                checked={proportional === PaletteLegendScalingEnum.PROPORTIONAL}
                onChange={(e) =>
                  setProportional(
                    e ? PaletteLegendScalingEnum.PROPORTIONAL : PaletteLegendScalingEnum.HOMOGENOUS,
                  )
                }
              />{' '}
              <div>proportional</div>
            </div>
          </div>
          <div
            className={`my-5 grid grid-cols-10 gap-3 font-light border-b border-white/[.07] layer-header items-center justify-between`}
          >
            <div className={'uppercase col-span-6'}>Colors</div>
            <div className={'flex items-center gap-3 col-span-2 justify-end'}>
              Step
              <InputNumber
                min={0}
                max={900}
                value={paletteSetup?.defaultStep}
                className={'justify-self-end w-1/2'}
                onChange={(e) => typeof e === 'number' && changeDefaultStep(e)}
              />
            </div>
            <div className={'text-center'}>Legend</div>
          </div>
        </>
        {renderPickers()}
      </>
    </Modal>
  );
};
