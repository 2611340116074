import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Text } from 'slate';
import { v4 } from 'uuid';

import { useFontLoader } from '../../core/api/useLoadFont';
import { gradientOpacity, singleColorOpacity } from '../../helpers/convertOpacity';
import { MAX_FULLSCREEN_HEIGHT } from '../../model/constants/constants';
import { BorderDef } from '../../model/definitions/BorderDef';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { RichTextChildDef } from '../../model/definitions/RichTextChildDef';
import { TextPanelDef } from '../../model/definitions/TextPanelDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';
import ElementContainer from './ElementContainer';
import { TextShadow } from './TextElementAddOns/DropShadow';
import TextWithFontFamily from './TextWithFontFamily';
import { transformPercentToAbsolute } from './utils';

interface TextElementElementProps {
  panelProps: TextPanelDef;
  canvas: { cnvWidth?: number; cnvHeight?: number };
  disabled: boolean;
  isMapTextElement?: boolean;
  mapId?: string;
  parentTime?: TimeControlDef[];
  inPoster?: boolean;
  posterId?: string;
  parentSize?: PositionControlDef;
  isMapOverlay?: boolean;
  geoPosterId?: string;
}
type Node = { children: RichTextChildDef[]; type: string };
export const TextElement = ({
  panelProps,
  canvas,
  disabled,
  mapId,
  parentTime,
  inPoster,
  posterId,
  parentSize,
  isMapOverlay = false,
}: TextElementElementProps) => {
  const { activeAspectRatio, translation, activeScene, sceneTranslation } = useSelector<
    RootState,
    ActiveDef
  >((state) => state.active);
  const {
    value,
    boxDef,
    fontSize,
    richText,
    fontFamily,
    fontColor,
    fontAlignment,
    textAnimation,
    textShadow,
    timeControls,
    positionControl,
    strokeWidth,
    strokeColor,
    shadowSetup,
  } = panelProps;
  const {
    background,
    paddingBottom,
    paddingTop,
    paddingLeft,
    paddingRight,
    borderRight,
    borderTop,
    borderLeft,
    borderBottom,
  } = boxDef;
  const { cnvHeight } = canvas;
  let fontUnit: number;
  if (inPoster && cnvHeight) {
    const cH = transformPercentToAbsolute(
      cnvHeight,
      activeAspectRatio,
      'height',
      MAX_FULLSCREEN_HEIGHT,
    );
    fontUnit = cH / 100;
  } else {
    fontUnit = (cnvHeight ?? MAX_FULLSCREEN_HEIGHT) / 100;
  }
  const { color } = background;
  const backgroundClip = fontColor?.includes('linear') ? 'text' : 'initial';
  useFontLoader(fontFamily);
  const contextValue = useContext(PlayerContext);
  const { time } = contextValue;
  const borderString = (val: BorderDef) =>
    val && `${fontUnit * val.width}px ${val.style} ${singleColorOpacity(val.color)}`;
  const dict = sceneTranslation[activeScene]?.customWords || translation?.customWords;

  const richTextDisplay = (node: Node | RichTextChildDef) => {
    if (Text.isText(node)) {
      let element: JSX.Element = stringWithPlaceholders(node.text, dict) as unknown as JSX.Element;
      if (node.underline) {
        element = <u>{element}</u>;
      }
      if (node.superscript) {
        element = <sup>{element}</sup>;
      }
      if (node.subscript) {
        element = <sub>{element}</sub>;
      }
      if (node.fontFamily) {
        element = <TextWithFontFamily key={v4()} node={node} element={element} />;
      }
      return element;
    }
    return (
      <span
        style={{
          position: 'relative',
          zIndex: 2,
          whiteSpace: textAnimation.active ? 'nowrap' : 'break-spaces',
          backgroundImage: backgroundClip === 'text' ? gradientOpacity(fontColor) : 'transparent',
          WebkitBackgroundClip: backgroundClip,
          WebkitTextStroke: strokeWidth
            ? `${strokeWidth}px ${singleColorOpacity(strokeColor)}`
            : '0px',
          color: fontColor,
        }}
      >
        {node.children.map((n: RichTextChildDef) => richTextDisplay(n))}
      </span>
    );
  };
  function stringWithPlaceholders(
    inputString: string,
    valueObject: Record<string, string> = {},
  ): string | undefined {
    const regex = /\$(\w+)/g;
    return inputString?.replace(regex, (match, placeholder) => {
      return (valueObject && valueObject[placeholder]) || match;
    });
  }
  return (
    <ElementContainer
      visibility={panelProps.enabled}
      canvas={canvas}
      panelProps={panelProps}
      disabled={disabled}
      type={'textPanels'}
      lock={false}
      style={{
        color: fontColor?.includes('linear') ? 'transparent' : singleColorOpacity(fontColor),
        textAlign: fontAlignment as FontAlignment,
        fontSize: fontUnit * fontSize,
      }}
      parentTime={parentTime}
      inPoster={inPoster}
      posterId={posterId}
      parentSize={parentSize}
      isMapOverlay={isMapOverlay}
      parentMapId={mapId}
    >
      <div
        className="text-panel-container"
        key={fontColor + 'container'}
        style={{
          height: '100%',
          overflow: 'hidden',
          paddingTop: fontUnit * paddingTop,
          paddingLeft: fontUnit * paddingLeft,
          paddingRight: fontUnit * paddingRight,
          paddingBottom: fontUnit * paddingBottom,
          borderLeft: borderString(borderLeft),
          borderRight: borderString(borderRight),
          borderTop: borderString(borderTop),
          borderBottom: borderString(borderBottom),
        }}
      >
        <div
          style={{ overflow: 'hidden', width: '100%', height: '100%', background: 'transparent' }}
        >
          <div
            style={{
              background: 'transparent',
              height: '100%',
              minWidth: textAnimation.active ? '100%' : 'auto',
              width: textAnimation.active ? 'fit-content' : 'auto',
              whiteSpace: textAnimation.active ? 'nowrap' : 'break-spaces',
              lineHeight: 1.15,
            }}
          >
            <TextShadow
              shadow={textShadow}
              shadowSetup={shadowSetup}
              cnvHeight={cnvHeight}
              aspectRatio={activeAspectRatio}
              textAnimation={textAnimation}
              background={color}
              positionControl={positionControl}
              timeControls={timeControls}
              time={time}
              backgroundClip={backgroundClip}
              fontColor={fontColor}
              strokeColor={strokeColor}
              strokeWidth={strokeWidth}
            >
              {richText ? richTextDisplay({ ...richText }) : stringWithPlaceholders(value, dict)}
            </TextShadow>
          </div>
        </div>
      </div>
    </ElementContainer>
  );
};
