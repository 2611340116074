import axios from 'axios';

import { MapStyleDTO } from '../../model/DTO/MapStyleDTO';
import { CitiesQueryDTO } from '../../organisms/addElementModal/CitiesQueryDTO';
import { _kc } from '../auth/KeycloakService';
import { axiosInstance } from './axiosInstance';

const headersToRemove = [
  'sec-ch-ua',
  'sec-ch-ua-mobile',
  'sec-ch-ua-platform',
  'sec-fetch-dest',
  'sec-fetch-mode',
  'sec-fetch-site',
];

const getAllMapStyles = async () => {
  const res = await axiosInstance.get<MapStyleDTO[]>(`map/map-style`);
  res.data[3] = {
    ...res.data[3],
    basicPreviewUrl:
      'https://public-bucket-cloud9.s3.amazonaws.com/1667647237209_satellite-preview-min.jpg',
    name: 'SATELLITE',
  };
  res.data[2] = {
    ...res.data[2],
    name: 'SATELLITE MT',
  };
  return res.data;
};

export const axiosGeolocation = axios.create({ baseURL: process.env.REACT_APP_GEOLOCATION });

axiosGeolocation.interceptors.request.use(async (config) => {
  if (config?.headers) {
    headersToRemove.forEach((header) => {
      if (config.headers?.[header]) {
        delete config.headers[header];
      }
    });
  }
  if (_kc.authenticated && config?.headers) {
    config.headers['Authorization'] = `Bearer ${_kc.token}`;
    return config;
  }
  await _kc.updateToken(5);
  if (!!_kc.token && config?.headers) config.headers['Authorization'] = `Bearer ${_kc.token}`;
  return config;
});

const queryCities = async (query: string): Promise<CitiesQueryDTO[]> => {
  const res = await axiosGeolocation.get(`/q/${query}.js`);
  return res.data.results;
};

const queryCity = async (lon: string, lat: string): Promise<CitiesQueryDTO[]> => {
  const res = await axiosGeolocation.get(`/r/${lon}/${lat}.js`);
  return res.data.results;
};

const queryCitiesByViewport = async (
  query: string,
  viewBox: [number, number, number, number],
): Promise<CitiesQueryDTO[]> => {
  /**baseMapSetup.baseMapConfigurationBounds */
  const [left, top, right, bottom] = viewBox;
  const res = await axiosGeolocation.get(`/?q=${query}&viewbox=${bottom},${left},${top},${right}`);
  return res.data.results;
};

export { getAllMapStyles, queryCities, queryCitiesByViewport, queryCity };
