import { AnimationItem } from 'lottie-web';

class AnimationDrawingsCache {
  /** cache by version id */
  cache: { [key: string]: { canvas: HTMLCanvasElement; anim: AnimationItem; drawId: string } } = {};

  setByVersionId({
    versionId,
    canvas,
    anim,
    drawId,
  }: {
    versionId: string;
    canvas: HTMLCanvasElement;
    anim: AnimationItem;
    drawId: string;
  }) {
    this.cache[versionId] = { canvas, anim, drawId };
  }

  getByVersionId(versionId: string) {
    return this.cache[versionId];
  }

  clearCacheByDrawIds(drawIds: string[]) {
    const keysToDelete: string[] = [];
    drawIds.forEach((drawId) => {
      Object.entries(this.cache).forEach(([versionId, value]) => {
        if (value.drawId === drawId) {
          keysToDelete.push(versionId);
          /** remove offscreen canvases */
          const container = document.getElementById('animationDrawing-' + drawId);
          container?.remove();
        }
      });
    });
    keysToDelete.forEach((key) => {
      delete this.cache[key];
    });
  }

  waitForCacheEntry(versionId: string): Promise<{
    canvas: HTMLCanvasElement;
    anim: AnimationItem;
    drawId: string;
  }> {
    return new Promise((res, reject) => {
      window.addEventListener('lottie-canvas-created', (e) => {
        // @ts-expect-error
        if (e.detail.versionId === versionId) {
          res(this.getByVersionId(versionId));
        }
      });
    });
  }
}

const animationDrawingsCache = new AnimationDrawingsCache();

export { animationDrawingsCache };
