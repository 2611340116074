import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { WeatherDataHttpClient } from '../../../core/weather-data/WeatherDataHttpClient';

const key = ['ENTERPISE_ACCOUNT'];
export const useGetEnterprise = (authenticated?: boolean) =>
  useQuery(
    [key, authenticated],
    async () => {
      const { data } = await axiosInstance.get(`enterprise-accounts/info/by-user`);
      return data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: (enterprise) => {
        sessionStorage.setItem('ORGANIZATION_ID', enterprise.id);
        WeatherDataHttpClient.setOrganizationId(enterprise.id);
      },
    },
  );
