import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['DATA_PRODUCT_MONITORING_FILES_PER_HOUR'];

export const useGetFilesPerHour = (dateRange: [string | null, string | null], id?: string) =>
  useQuery(
    [...key, id, ...dateRange],
    async () => {
      const { data } = await axiosInstance.get(
        `data-products/${id}/ingestion-data/files-per-hours`,
        {
          params: {
            dateFrom: dateRange[0],
            dateTo: dateRange[1],
          },
          headers: {
            'X-Product-Id': id || '',
          },
        },
      );
      return data;
    },
    {
      enabled: !!id,
    },
  );
