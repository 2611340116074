import { InputNumber, Select } from 'antd';
import { ToggleSwitch } from 'flowbite-react';
import { FC } from 'react';

import { usePropertyGridActive } from '../../../../../hooks/usePropertyGridActive';
import { TextPanelDef } from '../../../../../model/definitions/TextPanelDef';
import { BlendMode, ShadowSetup } from '../../../../../model/types/textShadow';
import { PropertySection } from '../../components/PropertySection';
import { PaletteColorPicker } from '../../mapLayersProperties/PalettecolorPicker';
import GridItem from '../../shared/GridItem';
import GridWrapper from '../../shared/GridWrapper';

function formatDashedString(input: string): string {
  // Split on the dash
  const segments = input.split('_');

  // Capitalize each segment
  const capitalized = segments.map((segment) => {
    if (!segment) return segment; // handle edge cases
    return segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase();
  });

  // Join with a space
  return capitalized.join(' ');
}

interface Props {
  dropShadow?: boolean;
  onChange: (propertyPath: Leaves<TextPanelDef>, e: boolean | string | number) => void;
  shadowSetup?: ShadowSetup;
}
export const DropShadow: FC<Props> = ({ dropShadow = false, onChange, shadowSetup }) => {
  const { isOpened } = usePropertyGridActive(['dropShadow']);

  return (
    <PropertySection isOpened={isOpened} label={`Drop Shadow`}>
      <GridWrapper className={'py-4'}>
        <GridItem
          noBorderBg
          label={'Drop Shadow'}
          item={
            <ToggleSwitch
              label={''}
              checked={dropShadow}
              onChange={(e) => onChange('textShadow', e)}
            />
          }
        />
        <GridItem
          noBorderBg
          label={'Blend Mode'}
          item={
            <Select
              value={shadowSetup?.blendMode}
              onChange={(val) => onChange('shadowSetup.blendMode', val)}
              style={{ width: 200 }}
              options={Object.values(BlendMode).map((mode) => ({
                label: formatDashedString(mode),
                value: mode,
              }))}
            />
          }
        />
        <GridItem
          noBorderBg
          label={'Distance X'}
          item={
            <InputNumber
              value={shadowSetup?.distanceX}
              onChange={(e) => onChange('shadowSetup.distanceX', Number(e))}
            />
          }
        />
        <GridItem
          noBorderBg
          label={'Distance Y'}
          item={
            <InputNumber
              value={shadowSetup?.distanceY}
              onChange={(e) => onChange('shadowSetup.distanceY', Number(e))}
            />
          }
        />
        <GridItem
          noBorderBg
          label={'Spread Distance'}
          item={
            <InputNumber
              value={shadowSetup?.spread}
              onChange={(e) => onChange('shadowSetup.spread', Number(e))}
            />
          }
        />
        <GridItem
          noBorderBg
          label={'Blur radius'}
          item={
            <InputNumber
              value={shadowSetup?.blur}
              onChange={(e) => onChange('shadowSetup.blur', Number(e))}
            />
          }
        />
        <GridItem
          noBorderBg
          label={'Color'}
          item={
            <PaletteColorPicker
              value={shadowSetup?.color ?? 'rgba(0, 0, 0 ,0)'}
              onChange={(e) => onChange('shadowSetup.color', e)}
            />
          }
        />
        <GridItem
          noBorderBg
          label={'Opacity'}
          item={
            <InputNumber
              min={0}
              max={255}
              value={shadowSetup?.opacity}
              onChange={(e) => onChange('shadowSetup.opacity', Number(e))}
            />
          }
        />
      </GridWrapper>
    </PropertySection>
  );
};
