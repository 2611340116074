import { FC, HTMLProps, useContext, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import Moveable, { OnEndEvent } from 'react-moveable';
import { useDispatch, useSelector } from 'react-redux';

import { animate } from '../../animations/animate';
import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import { getAnimationType } from '../../helpers/timelineUtil';
import { useOverlap } from '../../hooks/useOverlap';
import { MAX_FULLSCREEN_HEIGHT } from '../../model/constants/constants';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { ForecastWDElementDef } from '../../model/definitions/ForecastWDElementDef';
import { ImagePanelDef } from '../../model/definitions/ImagePanelDef';
import { ObservedWDElementDef } from '../../model/definitions/ObservedWDElementDef';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { SceneDef } from '../../model/definitions/SceneDef';
import { TextPanelDef } from '../../model/definitions/TextPanelDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { TimestampElementDef } from '../../model/definitions/TimestampElementDef';
import { VideoPanelDef } from '../../model/definitions/VideoPanelDef';
import { WeatherDataMapLayerSetup } from '../../model/definitions/WeatherDataMapLayerSetup';
import { WeatherPosterDef } from '../../model/definitions/WeatherPosterDef';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import {
  ActiveDef,
  addToMultiselect,
  populatePoster,
  setActiveMap,
  setElement,
  setPoster,
} from '../../store/slices/active-slice';
import { updateMapOverlay, updatePosition } from '../../store/slices/project-slice';
import { RootState } from '../../store/store';
import { AllElementsDefs, ElementType } from '../../types/elements';
import style from './style.module.scss';
import { transformAbsoluteToPercent, transformPercentToAbsolute } from './utils';

export interface ElementContainerInterface {
  canvas?: { cnvWidth?: number; cnvHeight?: number };
  mainIndicator?: { timeControls: TimeControlDef; value: string };
  panelProps:
    | VideoPanelDef
    | ObservedWDElementDef
    | ImagePanelDef
    | TextPanelDef
    | TimestampElementDef
    | ForecastWDElementDef
    | (WeatherDataMapLayerSetup & { positionControl: PositionControlDef } & {
        timeControls: TimeControlDef[];
      })
    | WeatherPosterDef;
  disabled: boolean;
  lock?: boolean;
  type: SceneKeys<SceneDef>;
  parentTime?: TimeControlDef[];
  inPoster?: boolean;
  posterId?: string;
  parentSize?: PositionControlDef;
  isMapOverlay?: boolean;
  parentMapId?: string;
  geoPosterId?: string;
  visibility: boolean;
  opacity?: number;
  children: React.ReactNode;
  style?: React.CSSProperties;
  isDraggable?: boolean;
  onClick?: () => void;
  /**Used for Layer legend always visible on map, min level is map level */
  minLevel?: number;
  isLegend?: boolean;
}
const ElementContainer: FC<ElementContainerInterface & HTMLProps<HTMLDivElement>> = ({
  canvas,
  panelProps,
  disabled,
  type,
  lock = true,
  children,
  parentTime,
  inPoster,
  posterId,
  parentSize,
  isMapOverlay = false,
  parentMapId,
  geoPosterId,
  visibility,
  opacity = 1,
  isDraggable,
  onClick,
  minLevel = 0,
  isLegend = false,
  ...rest
}: ElementContainerInterface) => {
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project?.present?.project);
  const dispatch = useDispatch();
  const contextValue = useContext(PlayerContext);
  const { bringToFront } = useOverlap(panelProps.positionControl, panelProps.id);
  const { time } = contextValue;
  // @ts-ignore
  // @ts-ignore
  const isMapOverlayType = type === 'mapOverlay';
  const {
    mode,
    activeElement,
    activeProp,
    activeScene,
    activeAspectRatio,
    biasMode,
    posterContent,
    posterMode,
    multiselect,
  } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const scene = project.sceneDefs.find((scene) => scene.id === activeScene);
  //@ts-ignore
  const elem = scene![activeProp as ElementType]?.find((elmnt) => elmnt.id === activeElement);
  const { timeControls, id, positionControl, canvasLock } = panelProps;

  const elementTime = timeControls;
  const elementContainerRef = useRef(null);

  const moveableRef = useRef<Moveable>(null);

  const run = () => {
    if (!parentTime) {
      return elementTime?.find((segment) => segment.startMS <= time && segment.endMS >= time);
    }
    if (isMapOverlay && !inPoster) {
      return parentTime?.find((segment) => segment.startMS <= time && segment.endMS >= time);
    }
    if (parentTime && inPoster) {
      return elementTime.find(
        (segment) =>
          segment.startMS + parentTime[0]?.startMS <= time &&
          segment.endMS + parentTime[0]?.startMS >= time &&
          parentTime[0].endMS > time, // &&
        //segment.startMS + parentTime[0]?.startMS + (segment.endMS - segment.startMS) >= time,
        //segment.endMS >= time,
      );
    }
  };
  const [shouldBePartOfPoster, setShouldBePartOfPoster] = useState<boolean>(false);
  useEffect(() => {
    const val = !!posterContent.find((element) => element.id === panelProps.id);
    setShouldBePartOfPoster(val);
  }, [posterContent]);
  const isVisible = () => (!run() || !visibility ? 'hidden' : 'visible');
  //const startTime = parentTime && parentTime[0]?.startMS ? parentTime[0]?.startMS : 0;
  const editingStopped = (e: OnEndEvent) => {
    let x = e.lastEvent?.translate?.[0] ?? translateAbsolute.x;
    let y = e.lastEvent?.translate?.[1] ?? translateAbsolute.y;
    const inputString = e.lastEvent?.transform;
    if (!inputString) return;
    const regex = /translate\(([-+]?\d*\.?\d+)px,\s*([-+]?\d*\.?\d+)px\)/;
    const match = inputString.match(regex);
    if (match) {
      const xValue = match[1];
      const yValue = match[2];
      x = xValue;
      y = yValue;
    } else {
      console.log('No match found.');
    }
    const rotation = e.moveable.getRotation();
    const ref = e.target as HTMLElement;
    const position = {
      ...positionControl,
      rotation: rotation,
      x: transformAbsoluteToPercent(
        x, // + e.lastEvent.drag ? e.lastEvent.drag?.clientX : 0,
        activeAspectRatio,
        'width',
        MAX_FULLSCREEN_HEIGHT,
        {
          w: inPoster ? parentSize?.w : undefined,
          h: inPoster ? parentSize?.h : undefined,
        },
      ),
      y: transformAbsoluteToPercent(
        y, // + e.lastEvent.drag ? e.lastEvent.drag?.clientY : 0,
        activeAspectRatio,
        'height',
        MAX_FULLSCREEN_HEIGHT,
        {
          w: inPoster ? parentSize?.w : undefined,
          h: inPoster ? parentSize?.h : undefined,
        },
      ),
      w: transformAbsoluteToPercent(
        ref.offsetWidth,
        activeAspectRatio,
        'width',
        MAX_FULLSCREEN_HEIGHT,
        {
          w: inPoster ? parentSize?.w : undefined,
          h: inPoster ? parentSize?.h : undefined,
        },
      ),
      h: transformAbsoluteToPercent(
        ref.offsetHeight,
        activeAspectRatio,
        'height',
        MAX_FULLSCREEN_HEIGHT,
        {
          w: inPoster ? parentSize?.w : undefined,
          h: inPoster ? parentSize?.h : undefined,
        },
      ),
    };
    if (isMapOverlay) {
      dispatch(
        updateMapOverlay({
          activeScene,
          elementId: id,
          elementType: type,
          geoPosterId: geoPosterId!,
          mapId: parentMapId!,
          position: position,
        }),
      );
    } else {
      dispatch(
        updatePosition({
          activeScene: activeScene as string,
          elementId: panelProps.id,
          position,
          elementType: type,
          isPoster: inPoster,
          posterId: posterId,
        }),
      );
    }
  };

  const isMarked =
    posterMode && shouldBePartOfPoster && !biasMode ? { outline: '2px solid yellow' } : {};

  const translateAbsolute = {
    x: transformPercentToAbsolute(
      positionControl?.x,
      activeAspectRatio,
      'width',
      MAX_FULLSCREEN_HEIGHT,
      {
        w: inPoster ? parentSize?.w : undefined,
        h: inPoster ? parentSize?.h : undefined,
      },
    ),
    y: transformPercentToAbsolute(
      positionControl?.y,
      activeAspectRatio,
      'height',
      MAX_FULLSCREEN_HEIGHT,
      {
        w: inPoster ? parentSize?.w : undefined,
        h: inPoster ? parentSize?.h : undefined,
      },
    ),
  };
  const keyboardMove = (e: KeyboardEvent) => {
    if (moveableRef.current && e.shiftKey && activeElement === id) {
      let deltaX = 0,
        deltaY = 0;
      if (e.key === 'ArrowUp') {
        deltaY = -1;
      }
      if (e.key === 'ArrowDown') {
        deltaY = 1;
      }
      if (e.key === 'ArrowLeft') {
        deltaX = -1;
      }
      if (e.key === 'ArrowRight') {
        deltaX = 1;
      }

      moveableRef.current.request('draggable', { deltaX, deltaY }, true);
    }
  };

  useHotkeys('shift+up,shift+down,shift+left,shift+right', keyboardMove, [
    positionControl,
    id,
    activeElement,
    moveableRef,
  ]);
  const allElements = multiselect.map((section) => section.elements).flat();

  return (
    <>
      {(activeElement === id || allElements.some((item) => item.element.id === id)) &&
      mode == ModeEnum.SEQUENCE &&
      !disabled &&
      !canvasLock ? (
        <Moveable
          onRenderEnd={(e) => {
            if (isMapOverlay && (type === 'observedWDElements' || type === 'forecastWDElements'))
              e.target.style.transform = 'translate(0, 0)';
          }}
          useResizeObserver={true}
          useMutationObserver={true}
          ref={moveableRef}
          target={elementContainerRef}
          draggable={!geoPosterId || (isDraggable && !isMapOverlay && !canvasLock)}
          resizable={!posterMode && !canvasLock}
          rotatable={!posterMode && !canvasLock}
          innerBounds={{ left: 0, top: 0, width: 100, height: 100 }}
          onDrag={({ target, transform }) => {
            target!.style.transform = transform;
          }}
          onDragEnd={editingStopped}
          keepRatio={lock || isMapOverlay}
          throttleResize={0}
          onResize={({ target, width, height, delta, transform }) => {
            delta[0] && (target!.style.width = `${width}px`);
            delta[1] && (target!.style.height = `${height}px`);
            target!.style.transform = transform;
          }}
          onResizeEnd={editingStopped}
          throttleRotate={0}
          onRotate={({ target, transform }) => {
            target!.style.transform = transform;
          }}
          onRotateEnd={editingStopped}
        />
      ) : null}
      <div
        data-parent={posterId}
        data-parenttype={posterId && 'weatherPosters'}
        data-type={type}
        data-id={id}
        ref={elementContainerRef}
        onMouseDown={(e) => {
          if (!canvasLock) {
            !posterId && e.stopPropagation();
            if (e.type !== 'contextmenu') {
              //setLines(true);
              if (e.metaKey || e.ctrlKey) {
                if (elem) {
                  dispatch(
                    addToMultiselect({
                      element: {
                        type: activeProp as ElementType,
                        element: panelProps as AllElementsDefs,
                        parentId: posterId,
                        mapId: parentMapId,
                      },
                    }),
                  );
                }
                dispatch(
                  addToMultiselect({
                    element: {
                      type: type as ElementType,
                      element: panelProps as AllElementsDefs,
                      parentId: posterId,
                      mapId: parentMapId,
                    },
                  }),
                );
              } else {
                dispatch(
                  setElement({
                    activeElement: id,
                    activeProp: type,
                    parentId: posterId,
                  }),
                );
                parentMapId &&
                  dispatch(
                    setActiveMap({
                      mapId: parentMapId,
                    }),
                  );
              }
              if (e.type !== 'contextmenu' && !posterId) {
                return;
              }
              !posterMode && dispatch(setPoster({ posterId: posterId ?? '' }));
              type === 'weatherPosters' && dispatch(setPoster({ posterId: id }));
            }
          }
        }}
        onClick={(e) => {
          if (!canvasLock) {
            e.stopPropagation();
            e.preventDefault();
            posterMode &&
              !parentSize &&
              type !== 'weatherPosters' &&
              dispatch(
                populatePoster({
                  // @ts-ignore
                  element: {
                    ...panelProps,
                    type: type,
                  },
                }),
              );
            if (e.type !== 'contextmenu' && isMapOverlay && isMapOverlayType) {
              dispatch(
                setElement({
                  activeElement: id,
                  activeProp: type,
                  parentId: posterId,
                }),
              );
            }
            onClick && onClick();
          }
        }}
        style={{
          pointerEvents: !canvasLock ? 'auto' : 'none',
          position: 'absolute',
          width: transformPercentToAbsolute(
            positionControl?.w,
            activeAspectRatio,
            'width',
            MAX_FULLSCREEN_HEIGHT,
            {
              w: inPoster ? parentSize?.w : undefined,
              h: inPoster ? parentSize?.h : undefined,
            },
          ),
          height: transformPercentToAbsolute(
            positionControl?.h,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
            {
              w: inPoster ? parentSize?.w : undefined,
              h: inPoster ? parentSize?.h : undefined,
            },
          ),
          transform: `translate(${translateAbsolute.x}px, ${translateAbsolute.y}px) rotate(${(
            positionControl?.rotation || 0
          ).toFixed(4)}deg)`,
          opacity: opacity,
          visibility: isVisible(),
          zIndex:
            activeElement === id && bringToFront
              ? 20
              : isMapOverlayType || isLegend
              ? 100
              : Math.max(positionControl?.zindex, minLevel),
          ...rest.style,
          ...isMarked,
        }}
        className={`${style.canvasElement} ${
          mode === ModeEnum.SEQUENCE && !disabled ? style.elementHover : null
        }`}
      >
        {visibility &&
          animate(
            children,
            getAnimationType(run()?.inAnimationDef),
            {
              duration: run()?.inAnimationDuration,
              enterTime: (run()?.startMS ?? 0) + (parentTime ? parentTime[0]?.startMS : 0),
              currentTime: contextValue.time,
            },
            getAnimationType(run()?.outAnimationDef),
            {
              duration: run()?.outAnimationDuration,
              exitTime: run()?.endMS,
              currentTime: contextValue.time,
            },
          )}
      </div>
    </>
  );
};

export default ElementContainer;
